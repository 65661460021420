import { Button } from "@pivus/ui/Button/Button";
import { CheckBox } from "@pivus/ui/CheckBox/CheckBox";
import { Care, Left, Play } from "@pivus/ui/Icons";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { useThemeSpace } from "@pivus/ui/style/useThemeToken";
import { useCallback, useState } from "react";
import type { ComponentProps, FunctionComponent } from "react";

import * as Screen from "../Screen";

type ButtonName = "close" | "play" | "care";
export const Emergency: FunctionComponent<
	{ onButtonPress: (button: ButtonName, dontPlayChecked: boolean) => void } & ComponentProps<typeof Screen.Root>
> = ({ onButtonPress, ...props }) => {
	const [checked, setChecked] = useState(true);

	return (
		<Screen.Root {...props}>
			<Screen.Section sx={{ marginTop: 0 }}>
				<Screen.Title Icon={Care} iconSx={{ marginTop: "$0" }} label="Taking a moment for selfcare" />
				<Screen.Text sx={{ marginTop: "$2" }}>
					You can just stop here and play something else. Or you can navigate to the care section of this app to calm
					your nervous system.
				</Screen.Text>
			</Screen.Section>

			<Screen.Section>
				<CheckBox checked={checked} onChange={setChecked}>
					<Screen.Text variant="body" sx={{ lineHeight: useThemeSpace("$6"), userSelect: "none" }}>
						Don’t play similar content anymore
					</Screen.Text>
				</CheckBox>
			</Screen.Section>

			<Screen.Section sx={{ marginBottom: 0 }}>
				<View sx={{ flexDirection: "row", alignItems: "center" }}>
					<Button
						containerStyle={{ width: "50%", paddingRight: useThemeSpace("$2") }}
						label="Stop & close"
						Icon={Left}
						color="relief"
						onPress={useCallback(() => onButtonPress("close", checked), [onButtonPress, checked])}
					/>
					<Button
						containerStyle={{ width: "50%", paddingLeft: useThemeSpace("$2"), flexGrow: 1 }}
						label="Continue playing"
						Icon={Play}
						color="calm"
						disabled={checked}
						onPress={useCallback(() => onButtonPress("play", checked), [onButtonPress, checked])}
					/>
				</View>
				<Button
					sx={{ marginTop: "$4" }}
					label="Stop & calm nervous system"
					Icon={Care}
					color="trust"
					onPress={useCallback(() => onButtonPress("care", checked), [onButtonPress, checked])}
				/>
			</Screen.Section>
		</Screen.Root>
	);
};
