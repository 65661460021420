import { useSx } from "@pivus/ui/style/primitives/dripsy";
import { useColorModeValue } from "@pivus/ui/style/useColorModeValue";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { FirebaseTesterScreen } from "../screens/simple/FirebaseTesterScreen";
import { SimpleAudioScreen } from "../screens/simple/SimpleAudioScreen";
import { SimpleHomeScreen } from "../screens/simple/SimpleHomeScreen";

import { ProfileStackNavigator } from "./ProfileStackNavigator";
import type { SimpleStackParams } from "./stacks";
import { useNavigationStyles } from "./styles/useNavigationStyles";
import { withColoredStatusBarComponent } from "./utils/withColoredStatusBarComponent";

const SimpleStack = createNativeStackNavigator<SimpleStackParams>();

export const SimpleStackNavigator = () => {
	const sx = useSx();
	const { headerStyle, headerTitleStyle } = useNavigationStyles();

	return (
		<SimpleStack.Navigator screenOptions={{ headerStyle, headerTitleStyle }}>
			<SimpleStack.Screen
				name="home"
				component={withColoredStatusBarComponent(SimpleHomeScreen, {
					barStyle: useColorModeValue("light-content", "dark-content"),
					backgroundColor: useThemeColor("$awe4"),
				})}
				options={{
					title: "Home",
					headerStyle: sx({ ...headerStyle, backgroundColor: "$awe4" }),
				}}
			/>
			<SimpleStack.Screen
				name="audio"
				component={withColoredStatusBarComponent(SimpleAudioScreen, {
					barStyle: useColorModeValue("light-content", "dark-content"),
					backgroundColor: useThemeColor("$joy4"),
				})}
				options={{
					title: "Audio",
					headerStyle: sx({ ...headerStyle, backgroundColor: "$joy4" }),
				}}
			/>
			<SimpleStack.Screen
				name="firebaseTester"
				component={withColoredStatusBarComponent(FirebaseTesterScreen, {
					barStyle: useColorModeValue("light-content", "dark-content"),
					backgroundColor: useThemeColor("$joy4"),
				})}
				options={{
					title: "FirebaseTesterScreen",
					headerStyle: sx({ ...headerStyle, backgroundColor: "$joy4" }),
				}}
			/>
			<SimpleStack.Screen name="profileStack" component={ProfileStackNavigator} options={{ headerShown: false }} />
		</SimpleStack.Navigator>
	);
};
