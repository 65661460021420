import { Play } from "@pivus/ui/Icons";
import { FlatList, Image, useSx, View } from "@pivus/ui/style/primitives/dripsy";
import { MotiPressable } from "@pivus/ui/style/primitives/moti";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { Theme } from "@pivus/ui/style/theme.dripsy";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import { formatDuration } from "@pivus/ui/utils/formatDuration";
import { usePressableAnimation, usePressableTransition } from "@pivus/ui/utils/transitionUtils";
import type { ComponentProps, FunctionComponent } from "react";
import { Linking, Platform, useWindowDimensions } from "react-native";
import type { ListRenderItemInfo } from "react-native";

import { dummyCareListData } from "./dummyCareListData";

export const CareList: FunctionComponent<
	{
		data?: CareListItemItem[];
		onItemPress?: (item: CareListItemItem) => void;
		paddingX?: keyof Theme["space"];
		paddingY?: keyof Theme["space"];
	} & Omit<ComponentProps<typeof FlatList>, "data" | "renderItem">
> = ({ data = dummyCareListData, onItemPress, paddingX = "$4", paddingY = "$4", ...props }) => {
	return (
		<FlatList
			data={data}
			{...props}
			ItemSeparatorComponent={ItemSeparator}
			// @ts-ignore -- because of https://github.com/nandorojo/dripsy/issues/58
			renderItem={({ item }: ListRenderItemInfo<CareListItemItem>) => (
				<CareListItem sx={{ paddingX, paddingY }} item={item} onPress={() => onItemPress?.(item)} />
			)}
			contentContainerStyle={{ paddingTop: useThemeSize("$headerHeight") }}
		/>
	);
};

export const ItemSeparator: FunctionComponent = () => {
	return <View sx={{ borderBottomWidth: 1, borderBottomColor: "$gray10" }} />;
};

export type CareListItemItem = {
	id: string;
	href: string;
	image: string;
	title: string;
	duration: number;
};
export const CareListItem: FunctionComponent<
	{ item: CareListItemItem } & Omit<ComponentProps<typeof MotiPressable>, "children">
> = ({ item, onPress, sx, ...props }) => {
	const animateSx = useSx();

	const iconSize = useThemeSize("$5");

	const { width } = useWindowDimensions();
	const paddingX = useThemeSize((sx as any).paddingX! || "$0") || 0;
	const contentWidth = Math.min(useThemeSize("$bp1"), width) - paddingX * 2;
	const imageSize = contentWidth * 0.4 - paddingX;

	return (
		<MotiPressable
			href={item.href}
			sx={sx}
			onPress={() => {
				if (Platform.OS !== "web") {
					// TODO: solve href as prop and opening onPress, this should be handled by MotiPressable
					Linking.openURL(item.href);
				}
				onPress?.();
			}}
			transition={usePressableTransition(
				{
					normal: { type: "timing", duration: 400 },
					hovered: { type: "timing", duration: 200 },
					pressed: { type: "timing", duration: 0 },
				},
				[]
			)}
			animate={usePressableAnimation(
				{
					normal: animateSx({ backgroundColor: "$background" }),
					hovered: animateSx({ backgroundColor: "$gray10" }),
					pressed: animateSx({ backgroundColor: "$background" }),
				},
				[animateSx]
			)}
			{...props}
		>
			<View sx={{ flexDirection: "row", maxWidth: "$bp1", width: "100%", alignSelf: "center" }}>
				<View
					sx={{
						borderRadius: "$2",
						width: imageSize,
						height: imageSize * (9 / 16),
						position: "relative",
						shadowColor: "#000",
						shadowOffset: {
							width: 5,
							height: 5,
						},
						shadowOpacity: 0.15,
						shadowRadius: 5,
					}}
				>
					<Image
						source={{ uri: item.image }}
						sx={{
							borderRadius: "$2",
							width: "100%",
							height: "100%",
						}}
					/>
					<Image
						// eslint-disable-next-line global-require
						source={require("./youtube.png")}
						sx={{
							width: 35,
							marginLeft: 35 / -2,
							height: 24,
							marginTop: 24 / -2,
							position: "absolute",
							top: "50%",
							left: "50%",
							opacity: 0.85,
						}}
					/>
				</View>

				<View sx={{ width: contentWidth - imageSize, paddingLeft: "$6" }}>
					<View sx={{ flexGrow: 1, justifyContent: "center" }}>
						<Text sx={{ marginBottom: "$2", color: "$gray6" }}>{item.title}</Text>
					</View>
					<View sx={{ flexDirection: "row", justifyContent: "flex-end" }}>
						<Text variant="bodySmall" sx={{ lineHeight: iconSize, marginRight: "$1" }}>
							{formatDuration(item.duration)}
						</Text>
						<Play width={iconSize} height={iconSize} />
					</View>
				</View>
			</View>
		</MotiPressable>
	);
};
