import {
	useCrossPlayer,
	useCrossPlayerDuration,
	useCrossPlayerPlaybackStatus,
	useCrossPlayerPosition,
	useCrossPlayerTrack,
} from "@pivus/cross-player/hooks";
import { useEffect, useRef, useState } from "react";

import { logPlaybackEvent } from "../../firebase/analytics-utils";

/**
 * Caches the crossPlayer state so that we can use it when everything is unset, but we still want to log an event
 */
const useCachedCrossPlayer = () => {
	const playbackStatus = useCrossPlayerPlaybackStatus();
	const track = useCrossPlayerTrack();
	const position = useCrossPlayerPosition();
	const duration = useCrossPlayerDuration();

	const cachedCrossPlayer = useRef({
		playbackStatus,
		track: track.crossPlayerTrack,
		position,
		duration,
	});

	useEffect(() => {
		if (track.crossPlayerTrack) {
			cachedCrossPlayer.current = {
				playbackStatus,
				track: track.crossPlayerTrack,
				position,
				duration,
			};
		}
	}, [playbackStatus, track, position, duration]);

	return cachedCrossPlayer;
};

export const useTrackableCrossPlayer = () => {
	const crossPlayer = useCrossPlayer();
	const { crossPlayerTrack } = useCrossPlayerTrack();
	const playbackStatus = useCrossPlayerPlaybackStatus();
	const duration = useCrossPlayerDuration();
	const cachedCrossPlayer = useCachedCrossPlayer();
	const [hasReachedNaturalStop, setHasReachedNaturalStop] = useState(false);

	useEffect(() => {
		if (playbackStatus === "stopped") {
			setHasReachedNaturalStop(true);
		}

		// Only log the events when there is a track and it is currently playing.
		if (!crossPlayer.track || !duration) return;

		logPlaybackEvent({
			playback_status: playbackStatus,
			playback_track_title: crossPlayer.track?.title ?? "",
			playback_track_id: crossPlayer.track?.id ?? "",
			// TODO: Remove || 0, which resolves TJ-154 for native devices where we no longer have progress at this point
			playback_position: cachedCrossPlayer.current.position! || 0,
			playback_progress: cachedCrossPlayer.current.position! / cachedCrossPlayer.current.duration! || 0,
		});
	}, [playbackStatus, duration]);

	useEffect(() => {
		if (!crossPlayerTrack && !hasReachedNaturalStop && cachedCrossPlayer.current.track) {
			logPlaybackEvent({
				playback_status: "stopped",
				playback_track_title: cachedCrossPlayer.current.track?.title ?? "",
				playback_track_id: cachedCrossPlayer.current.track?.id ?? "",
				// TODO: Remove || 0, which resolves TJ-154 for native devices where we no longer have progress at this point
				playback_position: cachedCrossPlayer.current.position! || 0,
				playback_progress: cachedCrossPlayer.current.position! / cachedCrossPlayer.current.duration! || 0,
			});
		}

		setHasReachedNaturalStop(false);
	}, [crossPlayerTrack]);
};
