/* eslint no-restricted-imports: 0 */
import * as moti from "moti";
import * as motiInteractions from "moti/interactions";

import { styled } from "./dripsy";

// Moti is restricted to just import its components from here, so we can encourage using these styled moti components
// This works together with eslint-config-pivus, which restricts importing from dripsy, react-native and moti
export const MotiView = styled(moti.MotiView)({});
export const MotiText = styled(moti.MotiText)({});
export const MotiImage = styled(moti.MotiImage)({});
export const MotiScrollView = styled(moti.MotiScrollView)({});
export const MotiPressable = styled(motiInteractions.MotiPressable)({});

// Moti exports
export const { MotiProgressBar, motify, useMotify, useAnimationState, useDynamicAnimation } = moti;
export const {
	useMotiPressable,
	useMotiPressables,
	useMotiPressableAnimatedProps,
	useMotiPressableTransition,
	useInterpolateMotiPressable,
} = motiInteractions;

export type { MotiTransitionProp, MotiAnimationProp, MotiTransition } from "moti";
