import React, { useReducer } from "react";

import { Pressable } from "../../style/primitives/dripsy";
import { MotiView } from "../../style/primitives/moti";

const Shape = () => {
	return (
		<MotiView
			from={{
				opacity: 0,
				scale: 0.5,
			}}
			animate={{
				opacity: 1,
				scale: 1,
			}}
			transition={{
				type: "timing",
			}}
			sx={{
				justifyContent: "center",
				height: "$64",
				width: "$64",
				borderRadius: 25,
				marginRight: 10,
				backgroundColor: "$joy8",
			}}
		/>
	);
};

export const MotiPlay = () => {
	const [visible, toggle] = useReducer((s) => !s, true);

	return (
		<Pressable
			onPress={toggle}
			sx={{
				flex: 1,
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "row",
				backgroundColor: "$awe4",
				minHeight: 300,
			}}
		>
			{visible && <Shape />}
		</Pressable>
	);
};
