import { getFirestore, collection, addDoc } from "firebase/firestore";
import { object } from "superstruct";
import type { ObjectSchema } from "superstruct/dist/utils";

import type { TimestampInstance } from "./types";
import { Timestamp, timestamp } from "./types";

export const docSchema = <S extends ObjectSchema>(schema: S) => {
	return object({ ...schema, createdAt: timestamp, updatedAt: timestamp });
};

export type DocumentFields<Fields extends object = {}> = {
	createdAt: TimestampInstance;
	updatedAt: TimestampInstance;
} & Fields;

export type PrivateDocumentFields = "createdAt" | "updatedAt";

const db = getFirestore();
export const addDocumentToCollection = <Fields extends DocumentFields>(
	collectionPath: string,
	data: Omit<Fields, PrivateDocumentFields>
) => {
	return addDoc(collection(db, collectionPath), { ...data, createdAt: Timestamp.now(), updatedAt: Timestamp.now() });
};
