import { IntroText } from "@pivus/ui/IntroText/IntroText";
import { Pressable, SafeAreaView, View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useLinkTo } from "@react-navigation/native";
import { signInWithEmailAndPassword } from "firebase/auth";
import { observer } from "mobx-react";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { StatusBar } from "react-native";
import Toast from "react-native-toast-message";

import { LoginForm } from "../../components/forms/LoginForm/LoginForm";
import { ShapedBackground } from "../../components/ShapedBackground/ShapedBackground";
import { logEvent } from "../../firebase/analytics";
import { logUiEvent } from "../../firebase/analytics-utils";
import { firebaseAuth } from "../../firebase/firebase";
import { getErrorMessage } from "../../firebase/getErrorMessage";
import { routes } from "../../utils/routes";

// eslint-disable-next-line no-empty-pattern
export const LoginScreen: FunctionComponent<{}> = observer(({}) => {
	const [isLoading, setIsLoading] = useState(false);
	const linkTo = useLinkTo();

	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<StatusBar barStyle="dark-content" />
			<ShapedBackground />
			<View sx={{ flex: 1, justifyContent: "center", margin: "$4", position: "relative" }}>
				<View sx={{ alignItems: "center" }}>
					<View sx={{ width: "75%", maxWidth: "$bp1" }}>
						<IntroText
							heading="Login"
							body="Please enter your email and password to login to your account."
							sx={{ marginBottom: "$6" }}
						/>

						<LoginForm
							loading={isLoading}
							onSubmit={async ({ email, password }) => {
								setIsLoading(true);
								try {
									const {
										user: { displayName },
									} = await signInWithEmailAndPassword(firebaseAuth, email, password);
									Toast.show({
										type: "accountSuccess",
										text1: `Welcome back ${displayName || ""}`,
										text2: "We’re glad to see you again here on TearUp",
									});
									logEvent("login", { method: "email" });
								} catch (err) {
									const errorString = getErrorMessage(err);

									Toast.show({
										type: "error",
										text1: "Whoops, something went wrong",
										text2: errorString,
									});

									logUiEvent({
										ui_component: "login",
										ui_trigger: "submit",
										ui_action: "error",
										ui_value: errorString,
									});
								}
								setIsLoading(false);
							}}
						/>
					</View>
					<Pressable
						sx={{ marginTop: "$10", borderBottomWidth: 1, borderBottomColor: "$black" }}
						onPress={() => {
							logUiEvent({
								ui_component: "login",
								ui_trigger: "switch_to_signup",
								ui_action: "press",
							});

							linkTo(routes.signup);
						}}
					>
						<Text sx={{ color: "$text" }}>or create an account</Text>
					</Pressable>
				</View>
			</View>
		</SafeAreaView>
	);
});
