import type { CareListItemItem } from "./CareList";

// Based on https://www.youtube.com/playlist?list=PLAeJI9ewbMrhvvxOLsmN_5C3mI2Hqo7DG
export const dummyCareListData: CareListItemItem[] = [
	{
		id: "1",
		title: "Beginner's kundalini yoga practice",
		image: "https://i.ytimg.com/vi/-DO_GgchYPA/hqdefault.jpg",
		href: "https://youtu.be/-DO_GgchYPA",
		duration: 34 * 60 + 23,
	},
	{
		id: "2",
		title: "Cultivate balance with movement medicine",
		image: "https://i.ytimg.com/vi/Enz98dDXQfY/hqdefault.jpg",
		href: "https://youtu.be/Enz98dDXQfY",
		duration: 17 * 60 + 56,
	},
	{
		id: "3",
		title: "Brain-calming sleep music @432Hz",
		image: "https://i.ytimg.com/vi/_7vE2GRFENI/hqdefault.jpg",
		href: "https://youtu.be/_7vE2GRFENI",
		duration: (9 * 60 + 9) * 60 + 8,
	},
	{
		id: "4",
		title: "Reset your nervous system through meditation",
		image: "https://i.ytimg.com/vi/JDpULX1MnPI/hqdefault.jpg",
		href: "https://youtu.be/JDpULX1MnPI",
		duration: 15 * 60 + 10,
	},
	{
		id: "5",
		title: "Guided breathwork for nervous system reset",
		image: "https://i.ytimg.com/vi/qlTC2HBmPeM/hqdefault.jpg",
		href: "https://youtu.be/qlTC2HBmPeM",
		duration: 22 * 60 + 2,
	},
	{
		id: "6",
		title: "Yoga nidra: Complete relaxation for nervous system",
		image: "https://i.ytimg.com/vi/9r8jXUvsi3w/hqdefault.jpg",
		href: "https://youtu.be/9r8jXUvsi3w",
		duration: 20 * 60 + 36,
	},
	{
		id: "7",
		title: "Mindfulness exercise: Vagus nerve reset",
		image: "https://i.ytimg.com/vi/TONw4nCjb84/hqdefault.jpg",
		href: "https://youtu.be/TONw4nCjb84",
		duration: 11 * 60 + 58,
	},
	{
		id: "8",
		title: "Vagus nerve reset for stress and anxiety relief",
		image: "https://i.ytimg.com/vi/eFV0FfMc_uo/hqdefault.jpg",
		href: "https://youtu.be/eFV0FfMc_uo",
		duration: 5 * 60 + 40,
	},
	{
		id: "9",
		title: "Release suppressed emotions through a guided meditation",
		image: "https://i.ytimg.com/vi/149tYQEhqvY/hqdefault.jpg",
		href: "https://youtu.be/149tYQEhqvY",
		duration: 14 * 60 + 3,
	},
	{
		id: "10",
		title: "Parasympathetic breathing technique for relaxation",
		image: "https://i.ytimg.com/vi/RyKCUo5gSMY/hqdefault.jpg",
		href: "https://youtu.be/RyKCUo5gSMY",
		duration: 15 * 60 + 49,
	},
	{
		id: "11",
		title: "Maintain a parasympathetic state with calming meditation",
		image: "https://i.ytimg.com/vi/GUXOLhF99_E/hqdefault.jpg",
		href: "https://youtu.be/GUXOLhF99_E",
		duration: 20 * 60 + 58,
	},
	{
		id: "12",
		title: "Calming anxiety: Activate your body’s anti-anxiety response",
		image: "https://i.ytimg.com/vi/SkJdKsiCyyM/hqdefault.jpg",
		href: "https://youtu.be/SkJdKsiCyyM",
		duration: 17 * 60 + 38,
	},
	{
		id: "13",
		title: "Yoga for vagus nerve activation - Gentle asana",
		image: "https://i.ytimg.com/vi/XvcNRQd7jKg/hqdefault.jpg",
		href: "https://youtu.be/XvcNRQd7jKg",
		duration: 29 * 60 + 16,
	},
	{
		id: "14",
		title: "Vagus nerve massage for stress and anxiety relief",
		image: "https://i.ytimg.com/vi/LnV3Q2xIb1U/hqdefault.jpg",
		href: "https://youtu.be/LnV3Q2xIb1U",
		duration: 15 * 60 + 28,
	},
	{
		id: "15",
		title: "Release trauma from your body: Guided meditation",
		image: "https://i.ytimg.com/vi/Ah71MQM6cMc/hqdefault.jpg",
		href: "https://youtu.be/Ah71MQM6cMc",
		duration: 12 * 60 + 7,
	},
	{
		id: "16",
		title: "Calm down your nervous system with Dr. Andrew Huberman",
		image: "https://i.ytimg.com/vi/xZVw-9ThmSM/hqdefault.jpg",
		href: "https://youtu.be/xZVw-9ThmSM",
		duration: 5 * 60 + 25,
	},
];
