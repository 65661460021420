import { useCrossPlayer } from "@pivus/cross-player/hooks";
import { observer } from "mobx-react";
import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

import { CrossPlayerState } from "./CrossPlayerState";
import { UserStore } from "./UserStore";

type StoresContextType = { userStore: UserStore };
const StoresContext = createContext<StoresContextType>({} as StoresContextType);
export const StoresProvider: FunctionComponent<{ children: ReactNode }> = observer(({ children }) => {
	const crossPlayer = useCrossPlayer();

	const stores = useMemo(() => {
		const crossPlayerState = new CrossPlayerState(crossPlayer);

		const userStore = new UserStore(crossPlayerState);
		return { userStore };
	}, []);

	return <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>;
});

export const useStores = () => {
	return useContext(StoresContext);
};
