import type { SVGReactComponent } from "@pivus/svg-loader";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { ThemedColor } from "@pivus/ui/style/theme.dripsy";
import { useThemeColor, useThemeSize } from "@pivus/ui/style/useThemeToken";
import type { FunctionComponent, ComponentProps } from "react";

export const HistoryTag: FunctionComponent<
	{
		label: string;
		count: number;
		Icon: SVGReactComponent;
		iconSx?: ComponentProps<typeof View>["sx"];
		iconColor?: ThemedColor;
		labelSx?: ComponentProps<typeof Text>["sx"];
		countSx?: ComponentProps<typeof Text>["sx"];
	} & Omit<ComponentProps<typeof View>, "children">
> = ({ label, count, Icon, iconSx, iconColor = "$text", labelSx, sx }) => {
	const badgeSize = useThemeSize("$5");
	return (
		<View sx={{ position: "relative", marginX: "$1", marginY: "$2", ...sx }}>
			<View
				sx={{
					flexDirection: "row",
					borderWidth: 1,
					borderColor: "$gray10",
					borderRadius: "$1",
					backgroundColor: "$background",
					alignItems: "center",
					padding: "$1",
					marginRight: badgeSize * 0.5,
					boxShadow: "$navigation",
				}}
			>
				<View sx={{ size: "$4", ...iconSx }}>
					<Icon color={useThemeColor(iconColor)} />
				</View>
				<Text
					sx={{
						color: "$text",
						marginX: "$1",
						...labelSx,
					}}
					variant="bodySmall"
				>
					{label}
				</Text>
				<View
					sx={{
						size: badgeSize,
						borderRadius: badgeSize * 0.5,
						backgroundColor: "$relief6",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						right: badgeSize * -0.5,
						top: badgeSize * -0.5,
						boxShadow: "$navigation",
						zIndex: 1,
					}}
				>
					<Text sx={{ fontSize: 10, color: "$invertedText" }}>{count}</Text>
				</View>
			</View>
		</View>
	);
};
