import type { SetTrackOptions } from "@pivus/cross-player/BaseCrossPlayer";
import type { User } from "firebase/auth";
import { Mode } from "firestorter";
import { action, makeAutoObservable, reaction } from "mobx";

import { setUserId } from "../firebase/analytics";
import { firebaseAuth } from "../firebase/firebase";

import type { CrossPlayerState } from "./CrossPlayerState";
import { TrackCollection, trackDocAsAudioTrack } from "./firebase/TrackCollection";
import { UserDataStore } from "./firebase/user_data/UserDataStore";

export class UserStore {
	private _initializingAuth: boolean = true;

	private _user?: User = undefined;

	readonly userData: UserDataStore = new UserDataStore();

	private readonly _trackCollection: TrackCollection = new TrackCollection("tracks", { mode: Mode.Off });

	private readonly _crossPlayerState: CrossPlayerState;

	constructor(crossPlayerState: CrossPlayerState) {
		this._crossPlayerState = crossPlayerState;
		makeAutoObservable(this);

		this.addListeners();
		this.addReactions();
	}

	private addListeners() {
		firebaseAuth.onAuthStateChanged(
			action((user: User | null) => {
				this._initializingAuth = false;
				this._user = user || undefined;
			})
		);
	}

	private addReactions() {
		// UID change, resetting data
		reaction(
			() => this.user?.uid,
			async (uid) => {
				this.userData.uid = uid;

				if (uid) {
					setUserId(uid);
				}

				// Whenever the uid changes, the current track should be reset, and we will automatically stop playing
				this.setTrackById(undefined, { autoPlay: false });

				await this._trackCollection.ready();
				if (!uid) {
					// This throws an error if the uid is undefined, but that's fine and the only way to reset the collection to an empty state
					return this._trackCollection.fetch().catch(() => {
						// this is expected, no need to do anything
					});
				}
				return this._trackCollection.fetch();
			}
		);

		// Create a new session
		reaction(
			() =>
				this.currentTrack?.id !== undefined &&
				this.userData.activePlaybackSessions.isReady &&
				this.activePlaybackSession === undefined &&
				this._crossPlayerState.playbackStatus === "playing",
			(createSession) => {
				if (createSession) {
					return this.userData.activePlaybackSessions.createNewSessionForTrack(this.currentTrack?.id!);
				}

				return undefined;
			}
		);

		// Update the current session
		reaction(
			() => ({
				playbackStatus: this._crossPlayerState.playbackStatus,
				position: this._crossPlayerState.position,
				activePlaybackSession: this.activePlaybackSession,
			}),
			({ playbackStatus, position, activePlaybackSession }) => {
				if (position !== undefined && position !== 0 && playbackStatus && activePlaybackSession) {
					if (playbackStatus === "playing") {
						// While playing, we just update the frontend...
						activePlaybackSession.data.position = position;
					} else {
						// In other cases, sync the current data back to the backend
						if (playbackStatus === "stopped") {
							activePlaybackSession.data.state = "completed";
						}

						activePlaybackSession.update({
							position,
							state: activePlaybackSession.data.state,
						});
					}
				}
			}
		);

		// Playback session change means that the new session is active and the previous one is stopped
		reaction(
			() => this.activePlaybackSession,
			(session, prevSession) => {
				if (session && session.data.state !== "active") {
					session.update({ state: "active" });
				}

				if (prevSession && prevSession.data.state === "active") {
					prevSession.update({ state: "stopped", position: prevSession.data.position }).catch(() => {
						// This is fine, it just means that the session was already deleted/moved into the history. Probably because the track was blocked
					});
				}
			}
		);
	}

	public get activePlaybackSession() {
		if (this.userData.activePlaybackSessions.isReady && this.currentTrack?.id) {
			return this.userData.activePlaybackSessions.getSessionByTrackId(this.currentTrack?.id);
		}

		return undefined;
	}

	get initializingAuth() {
		return this._initializingAuth;
	}

	public get user() {
		return this._user;
	}

	public async setTrackById(trackId: string | undefined, options: SetTrackOptions = { autoPlay: true }) {
		const newTrackDoc = trackId === undefined ? undefined : this._trackCollection.getDocById(trackId);
		const newAudioTrack = newTrackDoc === undefined ? undefined : trackDocAsAudioTrack(newTrackDoc);
		await this._crossPlayerState.crossPlayer.setTrack(newAudioTrack, options);

		const session = this.userData.activePlaybackSessions.getSessionByTrackId(trackId);

		if (session) {
			await this._crossPlayerState.crossPlayer.seekTo(session.data.position);
		}
	}

	get currentTrack() {
		const { trackId } = this._crossPlayerState;
		return trackId === undefined ? undefined : this._trackCollection.getDocById(trackId);
	}

	public get tracks() {
		const blockedIds = this.userData.trackPlaybackAggregations.blockedTrackIds;
		return this._trackCollection.docs.filter((doc) => !blockedIds.includes(doc.id!));
	}
}
