import { BasicHTML } from "@pivus/ui/BasicHTML/BasicHTML";
import { Button } from "@pivus/ui/Button/Button";
import * as DotPaginatedList from "@pivus/ui/DotPaginatedList/DotPaginatedList";
import { PaginatedContext } from "@pivus/ui/DotPaginatedList/DotPaginatedList";
import { Right } from "@pivus/ui/Icons";
import { Pressable, SafeAreaView, ScalableImage, ScrollView, useSx, View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { MotiView } from "@pivus/ui/style/primitives/moti";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { Theme } from "@pivus/ui/style/theme.dripsy";
import { useThemeColor, useThemeSize } from "@pivus/ui/style/useThemeToken";
import { useLinkTo } from "@react-navigation/native";
import type { FunctionComponent } from "react";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import type { ImageSourcePropType, ListRenderItemInfo } from "react-native";
import { StatusBar, useWindowDimensions } from "react-native";
import { Easing } from "react-native-reanimated";

import { logUiEvent } from "../../firebase/analytics-utils";
import { useTrackableFlatList } from "../../hooks/tracking/useTrackableFlatList";
import { routes } from "../../utils/routes";

import Background from "./assets/background.svg";
import Logo from "./assets/logo.svg";
import { items } from "./items";

export type Item = {
	id: number;
	title: string;
	descriptions: string[];
	images?: (ImageSourcePropType | undefined)[];
	color?: keyof Theme["colors"];
};

const logOnboardingPressEvent = (button: string) => {
	logUiEvent({
		ui_component: "onboarding",
		ui_trigger: button,
		ui_action: "press",
	});
};

const TitleBlob: FunctionComponent<{ color: keyof Theme["colors"]; title: string }> = ({ color, title }) => {
	const spacing = useThemeSize("$4");
	const width = Math.min(useWindowDimensions().width, useThemeSize("$bp1") * 0.8);
	const blobWidth = width - spacing * 2;
	const blobHeight = (346 / 439) * blobWidth;

	return (
		<View
			sx={{
				marginY: "$8",
				position: "relative",
				alignSelf: "center",
				width,
			}}
		>
			<Background
				color={useThemeColor(color)}
				style={{
					width: blobWidth,
					height: blobHeight,
					marginLeft: spacing,
					marginRight: spacing,
				}}
			/>

			<View
				sx={{
					alignItems: "center",
					justifyContent: "center",

					position: "absolute",
					top: 0,
					left: 0,
					paddingTop: "$6",
					width,
					height: blobHeight,
				}}
			>
				<Heading
					variant="h3"
					as="h1"
					sx={{
						color: "$white",
						textAlign: "center",
						paddingX: "$4",
						zIndex: 10,
					}}
				>
					{title}
				</Heading>
			</View>
		</View>
	);
};

const OnboardingItem: FunctionComponent<{ item: Item }> = ({ item }) => {
	const spacing = useThemeSize("$4");
	const { width } = useWindowDimensions();

	return (
		<DotPaginatedList.ItemRoot sx={{ height: undefined, flex: 1 }}>
			<ScrollView>
				<TitleBlob color={item.color ?? "$gray10"} title={item.title} />
				{item.descriptions.map((description, index) => {
					const image = item.images && item.images[index];
					const key = `${item.id}-${index}`;
					return (
						<View key={key}>
							{image && (
								<ScalableImage
									source={image}
									width={width - spacing * 2}
									sx={{ maxWidth: 244, maxHeight: 153, marginX: spacing, alignSelf: "center" }}
								/>
							)}
							<BasicHTML
								source={{ html: description }}
								variant="bodyLarge"
								contentWidth={width}
								baseStyleSx={{ color: "$text", paddingX: spacing, textAlign: "center" }}
								tagsStyles={useMemo(
									() => ({
										ul: { textAlign: "left", width: "100%", maxWidth: 400, margin: "auto" },
										li: { marginLeft: 10 },
									}),
									[]
								)}
							/>
						</View>
					);
				})}
			</ScrollView>
		</DotPaginatedList.ItemRoot>
	);
};

// eslint-disable-next-line no-empty-pattern
export const OnboardingScreen: FunctionComponent<{}> = ({}) => {
	const { onViewableItemsChanged } = useTrackableFlatList<Item>("onboarding", (item) => ({
		id: item.id.toString(),
		name: item.title,
	}));

	return (
		<SafeAreaView sx={{ backgroundColor: "$background", flex: 1 }}>
			<StatusBar barStyle="dark-content" />
			<View sx={{ alignItems: "center", paddingY: "$2" }}>
				<Logo width={90} height={40} />
			</View>

			<DotPaginatedList.Root data={items} sx={{ flex: 1 }}>
				<DotPaginatedList.List
					// @ts-ignore -- because of https://github.com/nandorojo/dripsy/issues/58
					renderItem={useCallback(
						({ item }: ListRenderItemInfo<Item>) => (
							<OnboardingItem item={item} />
						),
						[]
					)}
					onViewableItemsChanged={onViewableItemsChanged}
				/>
				<DotPaginatedList.ExpandingDots containerSx={{ bottom: 0, height: "$14", alignItems: "center" }} />
				<BottomNavigation />
			</DotPaginatedList.Root>
		</SafeAreaView>
	);
};

export const BottomNavigation: FunctionComponent<{}> = ({}) => {
	const { scrollToNext, scrollToIndex, getCurrentIndex, scrollX } = useContext(PaginatedContext);
	const iconSize = useThemeSize("$4");
	const [isLast, setIsLast] = useState(false);
	const sx = useSx();
	const linkTo = useLinkTo();

	useEffect(() => {
		const listener = scrollX.addListener(() => {
			setIsLast(getCurrentIndex() === items.length);
		});

		return () => scrollX.removeListener(listener);
	}, [scrollX, getCurrentIndex, items.length, setIsLast]);

	return (
		<View
			sx={{
				flexDirection: "row",
				justifyContent: "space-between",
				height: "$14",
				position: "relative",
			}}
		>
			<MotiView
				pointerEvents={isLast ? "auto" : "none"}
				from={{
					opacity: 0,
					marginTop: useThemeSize("$14"),
				}}
				animate={{
					opacity: isLast ? 1 : 0,
					marginTop: isLast ? 0 : useThemeSize("$14"),
				}}
				transition={{
					type: "timing",
					duration: isLast ? 150 : 250,
					easing: isLast ? Easing.out(Easing.ease) : Easing.in(Easing.ease),
				}}
				sx={{
					height: "$14",
					flexDirection: "row",
					alignItems: "center",
					position: "absolute",
					zIndex: 1,
					width: "100%",
					paddingX: "$2",
					backgroundColor: "$background",
				}}
			>
				<Button
					label="login"
					color="secondary"
					containerStyle={sx({ flexBasis: 0.5, marginX: "$2", height: "$10" })}
					onPress={() => {
						logOnboardingPressEvent("login");
						linkTo(routes.login);
					}}
				/>
				<Button
					label="create account"
					color="primary"
					containerStyle={sx({ flexBasis: 0.5, marginX: "$2", height: "$10" })}
					onPress={() => {
						logOnboardingPressEvent("create_account");
						linkTo(routes.signup);
					}}
				/>
			</MotiView>

			<Pressable
				sx={{ paddingX: "$4", flexDirection: "row", alignItems: "center" }}
				onPress={() => {
					logOnboardingPressEvent("skip");
					scrollToIndex(items.length);
				}}
			>
				<Text sx={{ color: "$text" }}>Skip</Text>
			</Pressable>

			<Pressable
				sx={{ paddingX: "$4", flexDirection: "row", alignItems: "center" }}
				onPress={() => {
					logOnboardingPressEvent("next");
					scrollToNext();
				}}
			>
				<Text sx={{ color: "$text", paddingRight: "$2" }}>Next</Text>
				<Right width={iconSize} height={iconSize} color={useThemeColor("$text")} />
			</Pressable>
		</View>
	);
};
