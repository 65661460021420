import { useFonts } from "expo-font";
import type { FunctionComponent, ReactElement } from "react";

import { darkColors, lightColors } from "./colors/theme.colors";
import { fonts } from "./fonts/theme.fonts";
import { DripsyProvider, makeTheme } from "./primitives/dripsy";

const spacesAndSizes = {
	$0: 0,
	$1: 4,
	$2: 8,
	$3: 12,
	$4: 16,
	$5: 20,
	$6: 24,
	$8: 32,
	$10: 40,
	$12: 48,
	$14: 56,
	$16: 64,
	$20: 80,
	$24: 96,
	$32: 128,
	$64: 256,
	$headerHeight: 64,
	$bp1: 480,
	$bp2: 740,
	$bp3: 980,
	$bp4: 1300,
};

const fontSizes = {
	$10: 10,
	$12: 12,
	$14: 14,
	$16: 16,
	$18: 18,
	$20: 20,
	$24: 24,
	$28: 28,
	$32: 32,
	$36: 36,
	$44: 44,
	$56: 56,
};

// Light theme
const themeLight = makeTheme({
	colors: lightColors,
	breakpoints: [480, 740, 980, 1300] as unknown[] as string[], // Cast to string[] to avoid type error, but using numbers is fine here
	radii: { $0: 0, $1: 4, $2: 8, $3: 12, $4: 16 },
	space: { ...spacesAndSizes },
	sizes: { ...spacesAndSizes },
	shadows: {
		$navigation: {
			shadowColor: "$gray1",
			shadowOffset: {
				width: 0,
				height: 0,
			},
			shadowRadius: 25,
			shadowOpacity: 0.25,
			elevation: 8, // TODO: somehow elevation is not working properly on Android, it does in storybook, fix it
		},
	},
	customFonts: {
		main: fonts.main.variants,
		heading: fonts.heading.variants,
		ui: fonts.ui.variants,
	},
	fonts: {
		root: fonts.main.family,
		heading: fonts.heading.family,
		ui: fonts.ui.family,
	},
	fontSizes,
	fontWeights: { thin: "100", light: "300", regular: "400", semibold: "600", bold: "700", black: "900" },
	text: {
		text: {
			fontFamily: fonts.main.family,
			color: "$text",
			variants: {
				variant: {
					note: { fontSize: "$12", fontWeight: "400", textTransform: "uppercase", lineHeight: fontSizes.$12 * 1.5 },
					bodySmall: { fontSize: "$14", fontWeight: "400", lineHeight: fontSizes.$14 * 1.5 },
					body: { fontSize: "$16", fontWeight: "400", lineHeight: fontSizes.$16 * 1.5 },
					bodyLarge: { fontSize: "$18", fontWeight: "400", lineHeight: fontSizes.$18 * 1.5 },
				},
			},
		},
		ui: {
			fontFamily: fonts.ui.family,
			color: "$text",
			variants: {
				variant: {
					thickNote: { fontSize: "$12", textTransform: "uppercase", lineHeight: fontSizes.$12, fontWeight: "900" },
				},
			},
		},
		heading: {
			fontFamily: fonts.heading.family,
			marginVertical: 0,
			color: "$text",
			variants: {
				variant: {
					h1: { fontWeight: "500", fontSize: "$56", lineHeight: fontSizes.$56 * 1.5 },
					h2: { fontWeight: "500", fontSize: "$44", lineHeight: fontSizes.$44 * 1.5 },
					h3: { fontWeight: "500", fontSize: "$36", lineHeight: fontSizes.$36 * 1.5 },
					h4: { fontWeight: "500", fontSize: "$32", lineHeight: fontSizes.$32 * 1.5 },
					h5: { fontWeight: "500", fontSize: "$28", lineHeight: fontSizes.$28 * 1.5 },
					h6: { fontWeight: "500", fontSize: "$24", lineHeight: fontSizes.$24 * 1.5 },
				},
			},
		},
	},
});

const themeDark = {
	...themeLight,
	colors: darkColors,
};

const StyledDripsyProvider: FunctionComponent<{
	children: ReactElement;
	// lightTheme?: Theme;
	// darkTheme?: Theme;
	// theme?: "light" | "dark" | "auto";
}> = ({ children /* ,lightTheme = themeLight, darkTheme = themeDark, theme: themeOverride = "auto" */ }) => {
	// const colorMode = useColorScheme();
	const [loaded] = useFonts({
		...fonts.main.sources,
		...fonts.heading.sources,
		...fonts.ui.sources,
	});

	if (!loaded) {
		return null;
	}

	// const themeName = themeOverride === "auto" ? colorMode : themeOverride;
	// const theme = themeName === "dark" ? darkTheme : lightTheme;

	return <DripsyProvider theme={themeLight}>{children}</DripsyProvider>;
};

type Theme = typeof themeLight;
declare module "dripsy" {
	interface DripsyCustomTheme extends Theme {}
}

export type ThemedColor = keyof typeof lightColors;
export type ThemedSize = keyof Theme["sizes"];

export { StyledDripsyProvider, themeLight, themeDark };
export type { Theme };
