import { useIsFocused } from "@react-navigation/native";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import type { StatusBarProps } from "react-native";
import { StatusBar } from "react-native";

/**
 * Wrap a screen component with a colored status bar to mount into a navigation stack as the screen component.
 */
export function withColoredStatusBarComponent(Component: FunctionComponent, statusBarProps: StatusBarProps = {}) {
	return useMemo(
		() =>
			function Screen(props: any) {
				const isFocused = useIsFocused();
				return (
					<>
						{isFocused ? <StatusBar {...statusBarProps} /> : null}
						<Component {...props} />
					</>
				);
			},
		[]
	);
}
