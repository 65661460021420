import { useColorScheme } from "react-native";

/**
 * A hook to get the light or dark value based on the color mode.
 */
export const useColorModeValue = <Type>(lightValue: Type, darkValue: Type) => {
	const colorMode = useColorScheme();

	if (colorMode === "dark") {
		return darkValue;
	}
	return lightValue;
};
