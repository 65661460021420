import { BasicHTML } from "@pivus/ui/BasicHTML/BasicHTML";
import { IntroText } from "@pivus/ui/IntroText/IntroText";
import { Pressable, SafeAreaView, useSx, View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import { useLinkTo } from "@react-navigation/native";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { observer } from "mobx-react";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { Linking, StatusBar, useWindowDimensions } from "react-native";
import Toast from "react-native-toast-message";

import { SignupForm } from "../../components/forms/SignupForm/SignupForm";
import { ShapedBackground } from "../../components/ShapedBackground/ShapedBackground";
import { logEvent } from "../../firebase/analytics";
import { logUiEvent } from "../../firebase/analytics-utils";
import { firebaseAuth } from "../../firebase/firebase";
import { getErrorMessage } from "../../firebase/getErrorMessage";
import { routes } from "../../utils/routes";

// eslint-disable-next-line no-empty-pattern
export const SignupScreen: FunctionComponent<{}> = observer(({}) => {
	const [isLoading, setIsLoading] = useState(false);
	const { width } = useWindowDimensions();
	const margin = useThemeSize("$4");
	const sx = useSx();
	const linkTo = useLinkTo();

	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<StatusBar barStyle="dark-content" />
			<ShapedBackground />
			<View sx={{ flex: 1, justifyContent: "center", margin: "$4", position: "relative" }}>
				<View sx={{ alignItems: "center" }}>
					<View sx={{ width: "75%", maxWidth: "$bp1" }}>
						<IntroText
							heading="Create account"
							body="Fill in the information below to create an account and get started."
							sx={{ marginBottom: "$6" }}
						/>

						<SignupForm
							loading={isLoading}
							onSubmit={async ({ name, email, password }) => {
								setIsLoading(true);
								try {
									await createUserWithEmailAndPassword(firebaseAuth, email, password);
									await updateProfile(firebaseAuth.currentUser!, { displayName: name });

									Toast.show({
										type: "accountSuccess",
										text1: `Welcome to TearUp ${name}`,
										text2: "You’re now a member of TearUp",
									});

									logEvent("sign_up", { method: "email" });
								} catch (err) {
									const errorString = getErrorMessage(err);

									Toast.show({
										type: "error",
										text1: "Whoops, something went wrong",
										text2: errorString,
									});

									logUiEvent({
										ui_component: "sign_up",
										ui_trigger: "submit",
										ui_action: "error",
										ui_value: errorString,
									});
								}
								setIsLoading(false);
							}}
						/>
					</View>

					<Pressable
						sx={{ marginTop: "$10", borderBottomWidth: 1, borderBottomColor: "$black" }}
						onPress={() => {
							logUiEvent({
								ui_component: "sign_up",
								ui_trigger: "switch_to_login",
								ui_action: "press",
							});

							linkTo(routes.login);
						}}
					>
						<Text sx={{ color: "$text" }}>or login</Text>
					</Pressable>
				</View>

				<View
					sx={{
						position: "absolute",
						bottom: "$0",
						width: "100%",
					}}
				>
					<BasicHTML
						variant="bodySmall"
						baseStyleSx={{ textAlign: "center" }}
						tagsStyles={{ a: sx({ color: "$relief4", textDecorationLine: "none" }) }}
						contentWidth={width - margin * 2}
						source={{
							html: `By creating an account you agree to our <a href="https://www.tearup.app/terms-and-conditions" data-name="Terms and Conditions">Terms and & Conditions</a> and <a href="https://www.tearup.app/privacy-policy" data-name="Privacy Policy">Privacy Policy</a>`,
						}}
						renderersProps={{
							a: {
								onPress: (event, url, { [`data-name`]: name }) => {
									logUiEvent({
										ui_component: "sign_up",
										ui_trigger: "link",
										ui_action: "press",
										ui_value: name,
									});

									Linking.openURL(url);
								},
							},
						}}
					/>
				</View>
			</View>
		</SafeAreaView>
	);
});
