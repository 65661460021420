import { TextInput } from "@pivus/ui/style/primitives/dripsy";
import { useTextVariantStyle } from "@pivus/ui/style/useTextVariantStyle";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import type { ComponentProps, FunctionComponent } from "react";
import { Platform } from "react-native";

export const FieldInput: FunctionComponent<ComponentProps<typeof TextInput>> = ({ sx, editable, ...props }) => {
	return (
		<TextInput
			placeholderTextColor={props.placeholderTextColor || useThemeColor("$text")}
			sx={{
				opacity: editable ? 1 : 0.5,
				borderColor: "$black",
				borderBottomWidth: 1,
				paddingX: "$2",
				paddingY: "$2",
				marginLeft: "$2",
				...useTextVariantStyle("text", "variant", "body").style,
				outlineWidth: Platform.OS === "web" ? 0 : undefined,
				...sx,
			}}
			editable={editable}
			{...props}
		/>
	);
};
