import { Tear, Thunder } from "@pivus/ui/Icons";
import * as ControlButton from "@pivus/ui/player/ControlButton/ControlButton";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { MotiView } from "@pivus/ui/style/primitives/moti";
import { useThemeColor, useThemeSize } from "@pivus/ui/style/useThemeToken";
import { AnimatePresence } from "moti";
import { useState } from "react";
import type { ComponentProps, FunctionComponent } from "react";
import { Easing } from "react-native-reanimated";
// This is basically a ControlButton with a colored icon
export const { Root } = ControlButton;

type Emotion = "sadness" | "anger";

/**
 * Combines the `EmotionTagButton.Root` (effectively `ControlButton.Root`) with an SVG.
 */
export const EmotionTagButton: FunctionComponent<{ emotion: Emotion } & ComponentProps<typeof Root>> = ({
	emotion,
	size = "$14",
	onPressIn,
	onPressOut,
	...props
}) => {
	const [isPressed, setIsPressed] = useState(false);
	const [pressIndex, setPressIndex] = useState(0);
	const tearColor = useThemeColor("$relief3");
	const thunderColor = useThemeColor("$pride5");
	const pxSize = useThemeSize(size);

	return (
		<Root
			size={size}
			onPressIn={() => {
				setPressIndex(pressIndex + 1);
				setIsPressed(true);
				onPressIn?.();
			}}
			onPressOut={() => {
				setIsPressed(false);
				onPressOut?.();
			}}
			{...props}
		>
			<View
				sx={{
					flex: 1,
					padding: "$2",
				}}
				{...props}
			>
				{emotion === "sadness" && (
					<>
						<Tear color={tearColor} />
						<AnimatePresence>
							{isPressed && (
								<MotiView
									sx={{ position: "absolute", top: 0, left: -2, padding: "$2" }}
									pointerEvents="none"
									transition={{
										type: "timing",
										easing: Easing.in(Easing.exp),
										duration: 1000,
										marginLeft: { type: "timing", duration: 150, easing: Easing.out(Easing.cubic) },
										width: { type: "timing", duration: 150, easing: Easing.out(Easing.cubic) },
										height: { type: "timing", duration: 150, easing: Easing.out(Easing.cubic) },
										scaleX: { type: "timing", delay: 300, easing: Easing.in(Easing.cubic) },
										opacity: { type: "timing", delay: 800, duration: 200 },
									}}
									animate={{ top: 4, marginLeft: 0, opacity: 1, width: pxSize, height: pxSize, scaleX: 1, scaleY: 1 }}
									exit={{
										top: 600,
										marginLeft: pxSize * -0.125,
										opacity: 0,
										width: pxSize * 1.25,
										height: pxSize * 1.25,
										scaleX: 0.5,
										scaleY: 7,
									}}
									key={pressIndex}
								>
									<Tear color={tearColor} />
								</MotiView>
							)}
						</AnimatePresence>
					</>
				)}
				{emotion === "anger" && (
					<>
						<Thunder color={thunderColor} />
						<AnimatePresence>
							{isPressed && (
								<MotiView
									sx={{ position: "absolute", left: -2, padding: "$2" }}
									pointerEvents="none"
									transition={{
										type: "timing",
										easing: Easing.in(Easing.bounce),
										duration: 800,
										opacity: { type: "timing", delay: 50 },
									}}
									animate={{ opacity: 1, width: pxSize, height: pxSize, scale: 1 }}
									exit={{
										opacity: 0,
										scale: 5,
									}}
									key={pressIndex}
								>
									<Thunder color={thunderColor} />
								</MotiView>
							)}
						</AnimatePresence>
					</>
				)}
			</View>
		</Root>
	);
};
