import type { PlaybackStatus } from "@pivus/cross-player/BaseCrossPlayer";

import { logEvent } from "./analytics";

export const logUiEvent = (params: {
	ui_component: string; // bottom_overlay_player, login_form
	ui_trigger: string; // buttonA, buttonB
	ui_action: string; // close, tag, click, seek
	ui_value?: string; // user_exists, error, cry, anger
	ui_ref?: string; // uid, id, etc
}) => {
	// console.log("analytics.shared > logUiEvent", "ui_event", params);
	return logEvent("ui_event" as any, params);
};

export const logPlaybackEvent = (params: {
	// Trigger and component are not relevant here, we just want to analyze what's happening with the player
	playback_status: PlaybackStatus;
	playback_track_title: string;
	playback_track_id: string;
	playback_position: number; // in seconds
	playback_progress: number; // normalized 0-1
}) => {
	// console.log("analytics.shared > playback_event", "player_event", params);
	return logEvent("playback_event" as any, {
		...params,
		playback_position: Math.round(params.playback_position * 10) / 10,
		playback_progress: Math.round(params.playback_progress * 1000) / 1000,
	});
};
