import type { ComponentProps, FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Slider } from "../../Slider/Slider";
import { View } from "../../style/primitives/dripsy";
import { Text } from "../../style/primitives/Text/Text";
import { formatDuration } from "../../utils/formatDuration";

export const TimeScrubber: FunctionComponent<
	{
		duration?: number;
		currentTime?: number;
		sx?: ComponentProps<typeof View>["sx"];
		disabled?: boolean;
		onSlidingUpdate?: (newTime: number) => void;
		onSlidingComplete?: (newTime: number) => void;
	} & Omit<ComponentProps<typeof Slider>, "onChange">
> = ({ duration, currentTime = 0, sx, disabled, onSlidingUpdate, onSlidingComplete, ...props }) => {
	const [isSliding, setIsSliding] = useState(false);
	const [displayTime, setDisplayTime] = useState(currentTime);

	// When the prop changes, we update the displayTime, otherwise it is the displayTime as set in the onChange
	useEffect(() => {
		if (!isSliding) {
			setDisplayTime(currentTime);
		}
	}, [currentTime, setDisplayTime]);

	const currentPosition = useMemo(() => (duration ? displayTime / duration : 0), [displayTime, duration]);

	return (
		<View
			sx={{
				...sx,
			}}
		>
			<View sx={{ flexDirection: "row", justifyContent: "space-between", marginBottom: "$2" }}>
				{/* TODO: animate once we have implemented animation/moti capabilities */}
				<Text variant="bodySmall" sx={{ color: disabled ? "$disabledText" : "$text" }}>
					{formatDuration(displayTime)}
				</Text>
				{duration ? (
					<Text variant="bodySmall" sx={{ color: disabled ? "$disabledText" : "$text" }}>
						{formatDuration((duration - displayTime) * -1)}
					</Text>
				) : null}
			</View>
			<Slider
				position={currentPosition}
				// TODO: properly type the onChange callback using the Slider's onChange type
				onChange={useCallback(
					(update: { position: number; isSliding: boolean }) => {
						if (!duration) return;

						const newTime = update.position * duration;
						setDisplayTime(newTime);
						onSlidingUpdate?.(newTime);
						if (isSliding !== update.isSliding) {
							// TODO: remove this ugly delayed update. This is for devices, granting a bit of time to ultimately seek to this position and send its value back in the update as a prop. For now delaying 100ms also works and keeps the position stable
							// See: https://www.notion.so/Create-custom-slider-component-replacing-react-native-awesome-slider-35cd6642e9ca4efdacfebdfc85f0a057?pvs=4
							setTimeout(() => setIsSliding(update.isSliding), update.isSliding ? 0 : 100);
							if (!update.isSliding) {
								onSlidingComplete?.(newTime);
							}
						}
					},
					[onSlidingUpdate, onSlidingComplete, setDisplayTime, isSliding, setIsSliding, duration]
				)}
				{...props}
			/>
		</View>
	);
};
