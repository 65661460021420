import type { SVGReactComponent } from "@pivus/svg-loader";
import { Care, Explore, History, Menu } from "@pivus/ui/Icons";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import type { EventMapBase, TypedNavigator } from "@react-navigation/core/lib/typescript/src/types";
import { DrawerActions } from "@react-navigation/native";
import type { NavigationState } from "@react-navigation/routers";
import type { ComponentProps, FunctionComponent } from "react";
import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

import { CareScreen } from "../screens/CareScreen/CareScreen";
import { ExploreScreen } from "../screens/ExploreScreen/ExploreScreen";
import { HistoryScreen } from "../screens/HistoryScreen/HistoryScreen";

import type { TabItemOptions } from "./components/BottomTabBar/BottomTabBar";
import { BottomTabBar } from "./components/BottomTabBar/BottomTabBar";
import { HeaderBar } from "./components/HeaderBar/HeaderBar";
import type { TabStackParams } from "./stacks";
import { useNavigationStyles } from "./styles/useNavigationStyles";
import { withColoredStatusBarComponent } from "./utils/withColoredStatusBarComponent";

// Casts the created stack navigator to accept the TabItemOptions for typing the screen options
// This is a bit of a hack, but it works for now
type ScreenOptions = TabItemOptions<TabStackParams, EventMapBase>;
const TabStack = createBottomTabNavigator<TabStackParams>() as TypedNavigator<
	TabStackParams,
	NavigationState,
	ScreenOptions,
	EventMapBase,
	any
>;

const NullComponent = () => null;
const PlayButtonSpace = () => <View sx={{ size: "$16" }} />;

export const TabStackNavigator = () => {
	const { headerTitleStyle, headerStyle, ...sharedNavigationStyles } = useNavigationStyles();

	return (
		// TODO: We shouldn't need to cast the screenOptions here, but it's not working without it
		<TabStack.Navigator
			tabBar={BottomTabBar}
			screenOptions={{ headerTitleStyle, headerStyle, ...sharedNavigationStyles } as ScreenOptions}
		>
			<TabStack.Screen
				name="explore"
				component={withColoredStatusBarComponent(ExploreScreen, {
					barStyle: "light-content",
					backgroundColor: useThemeColor("$relief4"),
				})}
				options={{
					title: "Explore",
					headerTitle: useHeaderTitle("Explore", Explore, headerTitleStyle.color, useThemeColor("$relief4")),
					tabBarIcon: Explore,
					iconColor: "$relief4",
				}}
			/>
			<TabStack.Screen
				name="care"
				component={withColoredStatusBarComponent(CareScreen, {
					barStyle: "light-content",
					backgroundColor: useThemeColor("$trust6"),
				})}
				options={{
					title: "Care",
					headerTitle: useHeaderTitle("Care", Care, headerTitleStyle.color, useThemeColor("$trust6")),
					tabBarIcon: Care,
					iconColor: "$trust6",
				}}
			/>
			<TabStack.Screen
				name="play"
				component={NullComponent}
				options={{
					tabBarButton: PlayButtonSpace,
				}}
			/>
			<TabStack.Screen
				name="history"
				component={withColoredStatusBarComponent(HistoryScreen, {
					barStyle: "light-content",
					backgroundColor: useThemeColor("$joy5"),
				})}
				options={{
					title: "History",
					headerTitle: useHeaderTitle("History", History, headerTitleStyle.color, useThemeColor("$joy5")),
					tabBarIcon: History,
					iconColor: "$joy5",
				}}
			/>
			<TabStack.Screen
				name="drawer"
				component={NullComponent}
				options={{
					title: "drawer",
					tabBarLabel: "",
					tabBarIcon: Menu,
					iconColor: "$gray2",
					onPress: ({ navigation }) => {
						navigation.dispatch(DrawerActions.openDrawer());
					},
				}}
			/>
		</TabStack.Navigator>
	);
};
const HeaderTitle: FunctionComponent<
	{ title: string; Icon: SVGReactComponent; textColor: string; backgroundColor: string } & ComponentProps<typeof View>
> = ({ title, Icon, textColor, backgroundColor, sx, ...props }) => {
	const { width } = useWindowDimensions();

	return (
		<View
			sx={{
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "row",
				width,
				marginLeft: "-$4",
				flex: 1,
				height: "$headerHeight",
				...sx,
			}}
			{...props}
		>
			<View sx={{ size: "$6", marginRight: "$3" }}>
				<Icon color={textColor} />
			</View>
			<Heading variant="h5" sx={{ color: textColor }}>
				{title}
			</Heading>
			<HeaderBar color={backgroundColor} />
		</View>
	);
};

const useHeaderTitle = (
	title: string,
	Icon: SVGReactComponent,
	textColor: string,
	backgroundColor: string,
	sx?: ComponentProps<typeof HeaderTitle>["sx"]
) => {
	return useMemo(
		() =>
			function () {
				return (
					<HeaderTitle title={title} Icon={Icon} textColor={textColor} backgroundColor={backgroundColor} sx={sx} />
				);
			},
		[title, Icon, textColor, backgroundColor, sx]
	);
};
