import { useDripsyTheme } from "./primitives/dripsy";
import type { Theme } from "./theme.dripsy";

/**
 * A hook to get a theme token value based on the current theme (either light or dark).
 */
export const useThemeToken = <Space extends keyof Theme, Token extends keyof Theme[Space]>(
	space: Space,
	token: Token
) => {
	const { theme } = useDripsyTheme();
	return theme[space][token];
};

export const useThemeColor = (colorToken: keyof Theme["colors"]) => {
	return useThemeToken("colors", colorToken) as string;
};

export const useThemeSize = (sizeToken: keyof Theme["sizes"]) => {
	return useThemeToken("sizes", sizeToken) as number;
};
export const useThemeFontSize = (fontSizeToken: keyof Theme["fontSizes"]) => {
	return useThemeToken("fontSizes", fontSizeToken) as number;
};

export const useThemeSpace = (spaceToken: keyof Theme["space"]) => {
	return useThemeToken("space", spaceToken) as number;
};
