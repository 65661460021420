import type { DocumentFields, PrivateDocumentFields } from "@pivus/firebase-entities/Document";
import { Timestamp } from "@pivus/firebase-entities/types";
import type { SetOptions } from "firebase/firestore";
import type { IDocumentOptions, DocumentSource } from "firestorter";
import { Document } from "firestorter";
import { reaction } from "mobx";
import type { Struct } from "superstruct";
import { assert } from "superstruct";

export type TypedDocumentOptions = {
	createIfNotExists?: true;
	defaultDocumentCreator?: (docId?: string) => object; // TODO: make generic so this should only return generic DocumentFields (and nothing else)
} & IDocumentOptions;

export class TypedDocument<
	Fields extends DocumentFields,
	PrivateFields extends keyof any = PrivateDocumentFields
> extends Document<Fields> {
	constructor(
		source?: DocumentSource,
		{ createIfNotExists, defaultDocumentCreator, ...options }: TypedDocumentOptions = {}
	) {
		super(source, options);

		reaction(
			() => !this.isLoading && !this.hasData && createIfNotExists,
			(shouldCreate) => {
				if (shouldCreate) {
					// TODO: improve the type, if shouldCreate is true, then defaultDocumentCreator should be required and we
					// should not need to cast {} as any
					this.set(defaultDocumentCreator ? defaultDocumentCreator(this.id) : ({} as any));
				}
			}
		);

		this.init();
	}

	// stub for creation initialization, useful for not needing to use the constructor in subclasses
	protected init() {}

	update(fields: Partial<Omit<Fields, PrivateFields>>): Promise<void> {
		return super.update({ ...fields, updatedAt: Timestamp.now() });
	}

	// Loosely update, useful for array and map modifications
	looseUpdate(fields: any): Promise<void> {
		return super.update({ ...fields, updatedAt: Timestamp.now() }, { skipSchemaValidation: true });
	}

	set(data: Omit<Fields, PrivateFields>, options?: SetOptions): Promise<void> {
		return super.set(
			{
				...data,
				createdAt: Timestamp.now(),
				updatedAt: Timestamp.now(),
			},
			options
		);
	}

	fetch(): Promise<TypedDocument<Fields>> {
		return super.fetch() as Promise<TypedDocument<Fields>>;
	}
}

export const createSchemaValidator = <Fields extends DocumentFields>(schema: Struct<Fields>) => {
	return (data: Fields) => {
		assert(data, schema);
		return data;
	};
};
