/* eslint no-restricted-imports: 0 */
import * as dripsy from "dripsy";
import rScalableImage from "react-native-scalable-image";

export type { SxProp } from "dripsy";

export * from "./ScrollView";
export * from "./TextInput";

// Dripsy is restricted to just import its components from here, so we can encourage the using our own components (such as using Heading over H1)
// This works together with eslint-config-pivus, which restricts importing from dripsy, react-native and moti
export const {
	View,
	Pressable,
	Image,
	A,
	FlatList,
	ActivityIndicator,
	Flex,
	Container,
	SafeAreaView,
	DripsyProvider,
	makeTheme,
	styled,
	useDripsyTheme,
	useResponsiveValue,
	useBreakpointIndex,
	getBreakpointIndex,
	useBreakpoints,
	remToPixels,
	useSx,
} = dripsy;

export const ScalableImage = styled(rScalableImage)();
