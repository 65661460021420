import type { ComponentProps, FunctionComponent } from "react";
import { useContentWidth } from "react-native-render-html";

import { BasicHTML } from "../BasicHTML/BasicHTML";
import { View } from "../style/primitives/dripsy";
import { Heading } from "../style/primitives/Heading/Heading";

export const IntroText: FunctionComponent<{ heading: string; body: string } & ComponentProps<typeof View>> = ({
	heading,
	body,
	...props
}) => {
	return (
		<View {...props}>
			<Heading variant="h3" as="h1" sx={{ textAlign: "center", marginBottom: "$2", color: "$text" }}>
				{heading}
			</Heading>
			<BasicHTML
				source={{ html: body }}
				contentWidth={useContentWidth()}
				variant="bodyLarge"
				baseStyleSx={{ textAlign: "center" }}
			/>
		</View>
	);
};
