import { Care } from "@pivus/ui/Icons";
import * as ControlButton from "@pivus/ui/player/ControlButton/ControlButton";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useTextVariantStyle } from "@pivus/ui/style/useTextVariantStyle";
import type { ComponentProps, FunctionComponent } from "react";

/**
 * Essentially a ControlButton with the Thunder icon and the "Stop!" label.
 */
export const EmergencyButton: FunctionComponent<ComponentProps<typeof ControlButton.Root>> = ({
	size = "$20",
	disabled = false,
	...props
}) => {
	return (
		<ControlButton.Root size={size} disabled={disabled} {...props}>
			<ControlButton.Icon SVG={Care} color="$trust6" sx={{ marginTop: "$1" }} />
			<Text
				sx={{
					...useTextVariantStyle("ui", "variant", "thickNote").style,
					textAlign: "center",
					marginBottom: "$3",
					color: "$gray4",
				}}
			>
				Stop!
			</Text>
		</ControlButton.Root>
	);
};
