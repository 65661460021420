import { useSx } from "@pivus/ui/style/primitives/dripsy";

export const useNavigationStyles = () => {
	const sx = useSx();

	return {
		headerTitleStyle: sx({
			color: "$gray12",
		}),
		headerStyle: sx({
			backgroundColor: "transparent",
			borderBottomWidth: 0,
		}),
		headerTransparent: true,
	};
};
