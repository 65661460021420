// TODO Validate env

import Constants from "expo-constants";

export default {
	FIREBASE_WEB_APP_CHECK_SITE_KEY: Constants.expoConfig?.extra?.env?.FIREBASE_WEB_APP_CHECK_SITE_KEY,
	FIREBASE_WEB_APP_CHECK_DEBUG_TOKEN: Constants.expoConfig?.extra?.env?.FIREBASE_WEB_APP_CHECK_DEBUG_TOKEN,
	FIREBASE_API_KEY: Constants.expoConfig?.extra?.env?.FIREBASE_API_KEY,
	FIREBASE_DATABASE_URL: Constants.expoConfig?.extra?.env?.FIREBASE_DATABASE_URL,
	FIREBASE_AUTH_DOMAIN: Constants.expoConfig?.extra?.env?.FIREBASE_AUTH_DOMAIN,
	FIREBASE_PROJECT_ID: Constants.expoConfig?.extra?.env?.FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET: Constants.expoConfig?.extra?.env?.FIREBASE_STORAGE_BUCKET,
	FIREBASE_MESSAGING_SENDER_ID: Constants.expoConfig?.extra?.env?.FIREBASE_MESSAGING_SENDER_ID,
	FIREBASE_APP_ID: Constants.expoConfig?.extra?.env?.FIREBASE_APP_ID,
	FIREBASE_MEASUREMENT_ID: Constants.expoConfig?.extra?.env?.FIREBASE_MEASUREMENT_ID,
};
