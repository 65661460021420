import { useEffect, useRef, useState } from "react";

// Taken from https://github.com/streamich/react-use/blob/master/src/useThrottle.ts
// but that library crashes our app, so only picking the hook we need

const useThrottle = <T>(value: T, ms: number = 200) => {
	const [state, setState] = useState<T>(value);
	const timeout = useRef<ReturnType<typeof setTimeout>>();
	const nextValue = useRef(null) as any;
	const hasNextValue = useRef(0) as any;

	useEffect(() => {
		if (!timeout.current) {
			setState(value);
			const timeoutCallback = () => {
				if (hasNextValue.current) {
					hasNextValue.current = false;
					setState(nextValue.current);
					timeout.current = setTimeout(timeoutCallback, ms);
				} else {
					timeout.current = undefined;
				}
			};
			timeout.current = setTimeout(timeoutCallback, ms);
		} else {
			nextValue.current = value;
			hasNextValue.current = true;
		}
	}, [value]);

	useEffect(() => {
		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
				timeout.current = undefined;
			}
		};
	}, []);

	return state;
};

export default useThrottle;
