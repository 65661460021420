import { View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import { useContext } from "react";
import type { FunctionComponent, ComponentProps } from "react";

import { logUiEvent } from "../../../firebase/analytics-utils";
import * as AudioPlayer from "../AudioPlayer";

import { EmergencyButton } from "./EmergencyButton/EmergencyButton";
import { EmotionTagButton } from "./EmotionTagButton/EmotionTagButton";

const TagBar: FunctionComponent<
	{ onTag?: (type: "cry" | "anger" | "emergency") => void } & ComponentProps<typeof AudioPlayer.Section>
> = ({ onTag }) => {
	const { disabled } = useContext(AudioPlayer.Context);
	const emergencySizeInPx = useThemeSize("$20");

	return (
		<AudioPlayer.Section sx={{ justifyContent: "center", marginTop: emergencySizeInPx * -0.5 }}>
			{/* TODO: drop shadow isn't working properly on iOS and Android */}
			<EmotionTagButton
				emotion="anger"
				size="$14"
				disabled={disabled}
				onPress={() => {
					onTag?.("anger");
				}}
			/>

			<EmergencyButton
				size="$20"
				disabled={disabled}
				sx={{ marginX: "$8" }}
				onPress={() => {
					onTag?.("emergency");
				}}
			/>

			<EmotionTagButton
				emotion="sadness"
				size="$14"
				disabled={disabled}
				onPress={() => {
					onTag?.("cry");
				}}
			/>
		</AudioPlayer.Section>
	);
};

export const BottomPlayer: FunctionComponent<
	{ title: string; id?: string; onTag?: ComponentProps<typeof TagBar>["onTag"] } & ComponentProps<
		typeof AudioPlayer.Root
	>
> = ({ title, id, onTag, ...props }) => {
	return (
		<AudioPlayer.Root {...props}>
			<TagBar
				onTag={(tag) => {
					logUiEvent({
						ui_component: "audio_player",
						ui_trigger: `tag_bar`,
						ui_action: `tag`,
						ui_value: tag,
						ui_ref: id,
					});

					onTag?.(tag);
				}}
			/>

			<Heading variant="h6" sx={{ marginY: "$6" }}>
				{title}
			</Heading>

			<AudioPlayer.Scrubber
				onSlidingComplete={() => {
					logUiEvent({
						ui_component: "audio_player",
						ui_trigger: "scrubber",
						ui_action: "seek",
						ui_ref: id,
					});
				}}
			/>

			<AudioPlayer.Section sx={{ marginTop: "$10" }}>
				<AudioPlayer.RewindButton
					size="$10"
					onPress={() => {
						logUiEvent({
							ui_component: "audio_player",
							ui_trigger: "rewind",
							ui_action: "seek",
							ui_ref: id,
						});
					}}
				/>
				{/* Placeholder for the PlayPauseButton that is separated from this component and will display in the TabBar */}
				<View sx={{ size: "$16" }} />
				<AudioPlayer.ForwardButton
					size="$10"
					onPress={() => {
						logUiEvent({
							ui_component: "bottom_player",
							ui_trigger: "forward",
							ui_action: "seek",
							ui_ref: id,
						});
					}}
				/>
			</AudioPlayer.Section>
		</AudioPlayer.Root>
	);
};
