import { Button } from "@pivus/ui/Button/Button";
import { SafeAreaView, ScrollView, TextInput, View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { observer } from "mobx-react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import { ping } from "../../firebase/functions";
import type { TodoCollection } from "../../stores/firebase/user_data/TodoCollection";
import { useStores } from "../../stores/StoresProvider";

export const FirebaseTesterScreen = observer(() => {
	const {
		userStore: { userData },
	} = useStores();

	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<ScrollView sx={{ padding: "$4" }}>
				<Text>FirebaseTesterScreen</Text>
				<TodoInput todos={userData.todoCollection} />

				<Button
					label="Ping"
					onPress={() =>
						ping({ message: "message to ping" }).then((result) => console.log("ping result", JSON.stringify(result)))
					}
				/>
				<Button
					label="Mark all as completed"
					onPress={() => userData.todoCollection.docs.map((doc) => doc.update({ completed: true }))}
				/>
				<Button
					label="Toggle all"
					onPress={() => userData.todoCollection.docs.map((doc) => doc.update({ completed: !doc.data.completed }))}
				/>
				<TodoList todos={userData.todoCollection} />
			</ScrollView>
		</SafeAreaView>
	);
});

const TodoInput: FunctionComponent<{ todos: TodoCollection }> = ({ todos }) => {
	const [value, setValue] = useState<string>("");
	return (
		<View sx={{ flexDirection: "row" }}>
			<TextInput onChangeText={setValue} sx={{ flex: 1, borderColor: "$awe2", borderWidth: 1 }} />
			<Button
				label="Add"
				onPress={() =>
					todos.add({ title: value, completed: false }).then((todo) => console.log(todo.id, todo.data.title))
				}
			/>
		</View>
	);
};

const TodoList: FunctionComponent<{ todos: TodoCollection }> = observer(({ todos }) => {
	return (
		<View>
			{todos.docs.map((todo) => {
				return (
					<View sx={{ flexDirection: "row", marginY: "$1" }} key={todo.id}>
						<View sx={{ flexDirection: "row", flex: 1 }}>
							<Text>{todo.id} - </Text>
							<Text>{todo.data.title} - </Text>
							<Text>{todo.data.updatedAt.toDate().toString().split("GMT")[0]}</Text>
							<Text>{todo.data.completed ? "complete" : "open/todo"}</Text>
						</View>
						<Button
							containerStyle={{ flexGrow: 0 }}
							label={todo.data.completed ? "Mark as TODO" : "Mark as completed"}
							onPress={() => {
								todo.update({ completed: !todo.data.completed });
							}}
						/>
					</View>
				);
			})}
		</View>
	);
});
