import type { DocumentFields, PrivateDocumentFields } from "@pivus/firebase-entities/Document";
import { Timestamp } from "@pivus/firebase-entities/types";
import { Collection } from "firestorter";

import type { TypedDocument } from "./TypedDocument";

export class TypedCollection<
	Fields extends DocumentFields,
	Doc extends TypedDocument<Fields> = TypedDocument<Fields>,
	PrivateFields extends keyof any = PrivateDocumentFields
> extends Collection<Doc> {
	constructor(...args: ConstructorParameters<typeof Collection<Doc>>) {
		super(...args);

		this.init();
	}

	// stub for creation initialization, useful for not needing to use the constructor in subclasses
	protected init() {}

	add(data: Omit<Fields, PrivateFields>): Promise<Doc> {
		return super.add({
			...data,
			createdAt: Timestamp.now(),
			updatedAt: Timestamp.now(),
		});
	}

	getDocById(id: string) {
		return this.docs.find((doc) => doc.id === id);
	}
}
