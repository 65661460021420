/* eslint global-require: 0 */
import { createFonts } from "./createFonts";

/**
 * Once a font is added here, also add it to:
 * - theme.dripsy.tsx:
 * 		- StyledDripsyProvider
 * 		- fonts
 * 		- customFonts
 * - BasicHTML.tsx
 */

export const fonts = createFonts([
	{
		family: "main",
		fallback: "Helvetica, Arial, sans-serif",
		faces: [
			// { weight: "100", src: require("./fonts/Roboto/Roboto-Thin.ttf") },
			// { weight: "300", src: require("./Roboto/Roboto-Light.ttf") },
			// { weight: "300", src: require("./TTCommons/TT-Commons-Light.otf") },
			{ weight: "400", src: require("./TTCommons/TT-Commons-Regular.otf"), isNormal: true },
			{ weight: "700", src: require("./TTCommons/TT-Commons-Medium.otf") },
			// { weight: "900", src: require("./fonts/Roboto/Roboto-Bold.ttf") },
		],
	},
	{
		family: "heading",
		fallback: "Helvetica, Arial, sans-serif",
		faces: [
			// { weight: "100", src: require("./Inter/Inter-Thin.ttf") },
			{ weight: "500", src: require("./Recoleta/Recoleta-Regular.ttf"), isNormal: true },
			// { weight: "500", src: require("./fonts/Inter/Inter-Regular.ttf"), isNormal: true },
			// { weight: "700", src: require("./fonts/Inter/Inter-Medium.ttf") },
			// { weight: "900", src: require("./fonts/Inter/Inter-Bold.ttf") },
		],
	},
	{
		family: "ui",
		fallback: "Helvetica, Arial, sans-serif",
		faces: [
			{ weight: "400", src: require("./Roboto/Roboto-Regular.ttf"), isNormal: true },
			{ weight: "700", src: require("./Roboto/Roboto-Bold.ttf") },
			{ weight: "900", src: require("./Roboto/Roboto-Black.ttf") },
		],
	},
]);
