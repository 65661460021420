import { Play, CheckCircular, Pause } from "@pivus/ui/Icons";
import { FlatList, useSx, View } from "@pivus/ui/style/primitives/dripsy";
import { MotiPressable } from "@pivus/ui/style/primitives/moti";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { Theme } from "@pivus/ui/style/theme.dripsy";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import { formatDuration } from "@pivus/ui/utils/formatDuration";
import { usePressableAnimation, usePressableTransition } from "@pivus/ui/utils/transitionUtils";
import type { ComponentProps, FunctionComponent } from "react";
import type { ListRenderItemInfo } from "react-native";

import { Album, albumShapes } from "../Album/Album";

export const TrackList: FunctionComponent<
	{
		data: TrackListItemItem[];
		onItemPress: (item: TrackListItemItem) => void;
		paddingX?: keyof Theme["space"];
		paddingY?: keyof Theme["space"];
	} & Omit<ComponentProps<typeof FlatList>, "data" | "renderItem">
> = ({ data, onItemPress, paddingX = "$4", paddingY = "$4", ...props }) => {
	return (
		<FlatList
			data={data}
			{...props}
			ItemSeparatorComponent={ItemSeparator}
			// @ts-ignore -- because of https://github.com/nandorojo/dripsy/issues/58
			renderItem={({ item, index }: ListRenderItemInfo<TrackListItemItem>) => (
				<TrackListItem sx={{ paddingX, paddingY }} item={item} index={index} onPress={() => onItemPress(item)} />
			)}
			contentContainerStyle={{ paddingTop: useThemeSize("$headerHeight") }}
		/>
	);
};

export const ItemSeparator: FunctionComponent = () => {
	return <View sx={{ borderBottomWidth: 1, borderBottomColor: "$gray11" }} />;
};

export type TrackListItemItem = {
	id: string;
	artist: string;
	title: string;
	description: string;
	duration: number;
	position?: number;
	hasPlayed: boolean;
	isPlaying: boolean;
};

const shapes = Object.values(albumShapes);
const colors = ["$awe6", "$joy6", "$calm6", "$relief6", "$trust6"] as const;
export const TrackListItem: FunctionComponent<
	{ item: TrackListItemItem; index: number } & Omit<ComponentProps<typeof MotiPressable>, "children">
> = ({ item, index, ...props }) => {
	const sx = useSx();

	const timeLeft = item.duration - (item.position || 0);
	const iconSize = useThemeSize("$5");
	const Icon = item.hasPlayed ? CheckCircular : item.isPlaying ? Pause : Play;

	return (
		<MotiPressable
			{...props}
			transition={usePressableTransition(
				{
					normal: { type: "timing", duration: 400 },
					hovered: { type: "timing", duration: 200 },
					pressed: { type: "timing", duration: 0 },
				},
				[]
			)}
			animate={usePressableAnimation(
				{
					normal: sx({ backgroundColor: "$background" }),
					hovered: sx({ backgroundColor: "$white" }),
					pressed: sx({ backgroundColor: "$white" }),
				},
				[item.isPlaying, sx]
			)}
		>
			{/* TODO: This extra View in between shouldn't be needed, but for some reason the parent MotiPressable doesn't apply any sx props */}
			<View sx={{ flexDirection: "row", maxWidth: "$bp1", width: "100%", alignSelf: "center" }}>
				<View sx={{ width: "40%", paddingRight: "$3" }}>
					<Album
						Shape={shapes[index % shapes.length]}
						color={colors[index % colors.length]}
						title={item.title}
						subtitle={`The story of ${item.artist}`}
						open={item.isPlaying}
					/>
				</View>
				<View sx={{ width: "60%", paddingLeft: "$3", zIndex: -1 }}>
					<View sx={{ flexGrow: 1, justifyContent: "center" }}>
						<Text sx={{ marginBottom: "$2", color: "$gray6" }}>{item.description}</Text>
					</View>
					<View sx={{ flexDirection: "row", justifyContent: "flex-end" }}>
						{item.hasPlayed ? (
							<Text variant="bodySmall" sx={{ lineHeight: iconSize, marginRight: "$1" }}>
								Played {formatDuration(item.duration)}
							</Text>
						) : (
							<Text variant="bodySmall" sx={{ lineHeight: iconSize, marginRight: "$1" }}>
								{formatDuration(timeLeft)}
								{(item.position && timeLeft > 0) || item.isPlaying ? " left" : ""}
							</Text>
						)}
						<Icon width={iconSize} height={iconSize} />
					</View>
				</View>
			</View>
		</MotiPressable>
	);
};
