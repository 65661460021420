import { Image, View } from "@pivus/ui/style/primitives/dripsy";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import type { FunctionComponent } from "react";
import { useWindowDimensions } from "react-native";
import { getStatusBarHeight } from "react-native-safearea-height";

import HeaderShape from "./assets/headerShape.svg";
import HeaderShadow from "./assets/shadow.png";

export const HeaderBar: FunctionComponent<{ color: string }> = ({ color }) => {
	const statusBarHeight = getStatusBarHeight(true);
	const { width } = useWindowDimensions();
	const svgSize = { width: 394, height: 24 };
	const scale = width / svgSize.width;
	const shapeHeight = Math.ceil(svgSize.height * scale);
	const baseShadowHeight = 36;
	const shadowHeight = Math.ceil(baseShadowHeight * scale);
	const headerHeight = useThemeSize("$headerHeight") + statusBarHeight;
	const boxHeight = headerHeight - shapeHeight;
	const overflowHeight = shapeHeight - headerHeight;

	return (
		<View
			sx={{
				position: "absolute",
				zIndex: -1,
				top: Math.max(statusBarHeight, overflowHeight) * -1,
				width,
				height: headerHeight,
			}}
		>
			<View sx={{ backgroundColor: color, width: "100%", height: boxHeight }} />
			<View sx={{ position: "relative", marginTop: -1 }}>
				<HeaderShape color={color} style={{ zIndex: 1, height: shapeHeight }} />
				<Image source={HeaderShadow} sx={{ width: "100%", height: shadowHeight, position: "absolute", top: -1 }} />
			</View>
		</View>
	);
};
