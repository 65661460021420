import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from "expo-av";
import type { AVPlaybackStatus } from "expo-av/src/AV.types";

import type { CrossTrack, SetTrackOptions } from "./BaseCrossPlayer";
import { BaseCrossPlayer } from "./BaseCrossPlayer";

/**
 * Only works with expo web apps.
 *
 * TODO: Add support for web apps outside of the expo context
 */
export class CrossPlayer extends BaseCrossPlayer {
	private _sound?: Audio.Sound;

	private _shouldPlay = false;

	constructor() {
		super();
		this.initialize();
	}

	protected initialize = async () => {
		await Audio.setAudioModeAsync({
			staysActiveInBackground: true,
			interruptionModeIOS: InterruptionModeIOS.DoNotMix,
			playsInSilentModeIOS: true,
			shouldDuckAndroid: true,
			interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
			playThroughEarpieceAndroid: false,
		});

		super.initialize();
	};

	public setTrack = async (newTrack: CrossTrack, options: SetTrackOptions = {}) => {
		await this._sound?.unloadAsync();
		this._sound = undefined;
		this._shouldPlay = !!newTrack && options.autoPlay === true;
		this.position = undefined;
		this.duration = undefined;
		this.playbackStatus = undefined;
		this.track = newTrack;
		if (newTrack) {
			const audio = await Audio.Sound.createAsync({ uri: newTrack.url }, {}, this.updatePlaybackStatusByAvStatus);
			this._sound = audio.sound;
			this.updatePlaybackStatusByAvStatus(audio.status);

			if (this._shouldPlay) {
				await this.play();
			}
		}
	};

	public play = async () => {
		this._shouldPlay = true;
		await this._sound?.playAsync().then(() => (this._shouldPlay = false));
	};

	public pause = async () => {
		this._shouldPlay = false;
		await this._sound?.pauseAsync();
	};

	public seekTo = async (position: number) => {
		this._shouldPlay = this.playbackStatus === "playing";
		this.position = position;
		await this._sound?.setPositionAsync(position * 1000).then(() => (this._shouldPlay = false));
	};

	public stop = async () => {
		this._shouldPlay = false;
		await this._sound?.stopAsync();
	};

	private updatePlaybackStatusByAvStatus = (status: AVPlaybackStatus) => {
		if (!status?.isLoaded || !this.track) {
			this.playbackStatus = this._shouldPlay ? "playing" : "loading";
			return;
		}

		this.duration = status.durationMillis ? status.durationMillis / 1000 : undefined;
		this.position = status.positionMillis / 1000;

		if (this._shouldPlay || status.isPlaying) {
			this.playbackStatus = "playing";
			return;
		}

		const isAtStart = status.positionMillis === 0;
		const isAtEnd = status.positionMillis === status.durationMillis;
		const isStopped = isAtStart || isAtEnd || status.didJustFinish;

		if (isStopped) {
			this.playbackStatus = "stopped";
			return;
		}

		this.playbackStatus = "paused";
	};
}
