/* eslint no-restricted-imports: 0 */
import * as Dripsy from "dripsy";
import type { ComponentProps, FunctionComponent } from "react";

/**
 * Dripsy's TextInput with undefined props as their defaults, bypassing some type errors
 */
export const TextInput: FunctionComponent<Partial<ComponentProps<typeof Dripsy.TextInput>>> = ({
	children,
	onPressIn = undefined,
	onPressOut = undefined,
	accessibilityLabelledBy = undefined,
	accessibilityLanguage = undefined,
	onPointerEnter = undefined,
	onPointerEnterCapture = undefined,
	onPointerLeave = undefined,
	onPointerLeaveCapture = undefined,
	onPointerMove = undefined,
	onPointerMoveCapture = undefined,
	onPointerCancel = undefined,
	onPointerCancelCapture = undefined,
	onPointerDown = undefined,
	onPointerDownCapture = undefined,
	onPointerUp = undefined,
	onPointerUpCapture = undefined,
	autoComplete = undefined,
	cursorColor = undefined,
	...props
}) => {
	return (
		<Dripsy.TextInput
			onPressIn={onPressIn}
			onPressOut={onPressOut}
			accessibilityLabelledBy={accessibilityLabelledBy}
			accessibilityLanguage={accessibilityLanguage}
			onPointerEnter={onPointerEnter}
			onPointerEnterCapture={onPointerEnterCapture}
			onPointerLeave={onPointerLeave}
			onPointerLeaveCapture={onPointerLeaveCapture}
			onPointerMove={onPointerMove}
			onPointerMoveCapture={onPointerMoveCapture}
			onPointerCancel={onPointerCancel}
			onPointerCancelCapture={onPointerCancelCapture}
			onPointerDown={onPointerDown}
			onPointerDownCapture={onPointerDownCapture}
			onPointerUp={onPointerUp}
			onPointerUpCapture={onPointerUpCapture}
			autoComplete={autoComplete}
			cursorColor={cursorColor}
			{...props}
		>
			{children}
		</Dripsy.TextInput>
	);
};
