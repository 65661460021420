import { Button } from "@pivus/ui/Button/Button";
import { CheckBox } from "@pivus/ui/CheckBox/CheckBox";
import { Care, CheckCircular, Explore } from "@pivus/ui/Icons";
import { useThemeSpace } from "@pivus/ui/style/useThemeToken";
import { useCallback, useState } from "react";
import type { ComponentProps, FunctionComponent } from "react";

import * as Screen from "../Screen";

type ButtonName = "close" | "care";
export const Completed: FunctionComponent<
	{ onButtonPress: (button: ButtonName, dontPlayChecked: boolean) => void } & ComponentProps<typeof Screen.Root>
> = ({ onButtonPress, ...props }) => {
	const [checked, setChecked] = useState(false);

	return (
		<Screen.Root {...props}>
			<Screen.Section sx={{ marginTop: 0 }}>
				<Screen.Title Icon={CheckCircular} label="Emotional Journey Complete" />
				<Screen.Text sx={{ marginTop: "$2" }}>
					Before jumping into the next story, make sure to take good care of yourself, listen to what you need right
					now. In case you don&apos;t know, check out the care section of this app.
				</Screen.Text>
			</Screen.Section>

			<Screen.Section>
				<CheckBox checked={checked} onChange={setChecked}>
					<Screen.Text variant="body" sx={{ lineHeight: useThemeSpace("$6"), userSelect: "none" }}>
						Don’t play similar content anymore
					</Screen.Text>
				</CheckBox>
			</Screen.Section>

			<Screen.Section sx={{ flexDirection: "row", alignItems: "center", width: "100%", marginBottom: 0 }}>
				<Button
					containerStyle={{ width: "50%", paddingRight: useThemeSpace("$2") }}
					Icon={Explore}
					label="Explore"
					color="relief"
					onPress={useCallback(() => onButtonPress("close", checked), [onButtonPress, checked])}
				/>
				<Button
					containerStyle={{ width: "50%", paddingLeft: useThemeSpace("$2") }}
					label="Calm nervous system"
					Icon={Care}
					color="trust"
					onPress={useCallback(() => onButtonPress("care", checked), [onButtonPress, checked])}
				/>
			</Screen.Section>
		</Screen.Root>
	);
};
