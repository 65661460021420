import type { SVGReactComponent } from "@pivus/svg-loader";
import type { ThemedColor } from "@pivus/ui/style/theme.dripsy";
import { TabBar } from "@pivus/ui/TabBar/TabBar";
import type {
	BottomTabBarProps,
	BottomTabNavigationEventMap,
	BottomTabNavigationOptions,
} from "@react-navigation/bottom-tabs";
import type { EventMapBase } from "@react-navigation/core/lib/typescript/src/types";
import type { NavigationHelpers, ParamListBase } from "@react-navigation/native";
import type { FunctionComponent } from "react";
import React from "react";
import type { TouchableWithoutFeedbackProps } from "react-native";

import { ManagedBottomOverlayPlayer } from "../../../components/BottomOverlayPlayer/ManagedBottomOverlayPlayer";
import { logUiEvent } from "../../../firebase/analytics-utils";

import { useDefaultTabProps } from "./useDefaultTabProps";

export type TabItemOptions<
	Params extends ParamListBase = ParamListBase,
	EventMap extends EventMapBase = BottomTabNavigationEventMap
> = {
	tabBarIcon?: SVGReactComponent;
	tabBarLabel?: string;
	tabBarButton?: FunctionComponent<TouchableWithoutFeedbackProps>;
	iconColor?: ThemedColor;
	onPress?: (options: { navigation: NavigationHelpers<Params, EventMap>; defaultOnPressHandler: () => void }) => void;
} & Pick<BottomTabNavigationOptions, "title" | "headerStyle" | "headerTitleStyle" | "headerTitle">;

export const heightToken = "$20";

export const BottomTabBar: FunctionComponent<BottomTabBarProps> = ({ navigation, state, descriptors, insets }) => {
	return (
		<>
			<TabBar.Root
				sx={{
					paddingBottom: insets.bottom,
					height: heightToken,
				}}
			>
				{state.routes.map((route, index) => {
					const {
						focused,
						label,
						options,
						onPress: defaultOnPressHandler,
						...props
					} = useDefaultTabProps<TabItemOptions>({
						index,
						state,
						route,
						descriptors,
						navigation,
					});

					const onPress = () => {
						if (options.title) {
							logUiEvent({
								ui_component: "bottom_tab_bar",
								ui_trigger: "tab",
								ui_action: "press",
								ui_value: options.title,
							});
						}

						if (options.onPress) {
							options.onPress({ navigation, defaultOnPressHandler });
						} else {
							defaultOnPressHandler();
						}
					};

					if (options.tabBarButton) {
						const Button = options.tabBarButton;
						return <Button key={route.key} onPress={onPress} {...props} />;
					}

					if (!options.tabBarIcon) throw new Error(`No tabBarIcon provided for route: ${route.name}`);

					return (
						<TabBar.Item
							key={route.key}
							Icon={options.tabBarIcon}
							label={label}
							iconColor={options.iconColor}
							active={focused}
							onPress={onPress}
							{...props}
						/>
					);
				})}
			</TabBar.Root>

			<ManagedBottomOverlayPlayer />
		</>
	);
};
