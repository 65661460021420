import type { ComponentProps, FunctionComponent, MutableRefObject, ReactNode } from "react";
import { createContext, useContext, useMemo, useRef } from "react";
import { Animated, Platform, useWindowDimensions } from "react-native";
import { SlidingBorder, ExpandingDot, ScalingDot, SlidingDot } from "react-native-animated-pagination-dots";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import { useSharedValue } from "react-native-reanimated";

import type { SxProp } from "../style/primitives/dripsy";
import { useSx, View, FlatList } from "../style/primitives/dripsy";
import type { Theme } from "../style/theme.dripsy";
import { useThemeColor, useThemeSize } from "../style/useThemeToken";
import isMobileWebClient from "../utils/isMobileWebClient";

type BaseItem = { id: number | string };
export const PaginatedContext = createContext<{
	scrollX: Animated.Value;
	data: BaseItem[];
	// @ts-ignore
	listRef: MutableRefObject<FlatList> | null;
	getCurrentIndex: () => number;
	scrollToPrevious: () => void;
	scrollToNext: () => void;
	scrollToIndex: (index: number) => void;
}>({
	scrollX: new Animated.Value(0),
	data: [],
	listRef: null,
	getCurrentIndex: () => 0,
	scrollToPrevious: () => {},
	scrollToNext: () => {},
	scrollToIndex: () => {},
});

export const Root: FunctionComponent<ComponentProps<typeof View> & { data: BaseItem[] }> = ({ sx, data, ...props }) => {
	const scrollX = useRef(new Animated.Value(0)).current;
	// @ts-ignore
	const listRef = useRef<FlatList>(null);
	const { width } = useWindowDimensions();

	const scrollToIndex = useMemo(() => {
		return (index: number) => {
			listRef.current?.scrollToOffset({ offset: width * index - width, animated: true });
		};
	}, [width]);

	const getCurrentIndex = useMemo(
		() => () => {
			// @ts-ignore
			// eslint-disable-next-line no-underscore-dangle
			return Math.floor(scrollX.__getValue() / Math.floor(width)) + 1 || 1;
		},
		[width]
	);

	const scrollToPrevious = useMemo(
		() => () => {
			return scrollToIndex(getCurrentIndex() - 1);
		},
		[getCurrentIndex, scrollToIndex]
	);

	const scrollToNext = useMemo(
		() => () => {
			return scrollToIndex(getCurrentIndex() + 1);
		},
		[getCurrentIndex, scrollToIndex]
	);

	const ctxValue = useMemo(
		() => ({
			scrollX,
			data,
			listRef,
			getCurrentIndex,
			scrollToPrevious,
			scrollToNext,
			scrollToIndex,
		}),
		[scrollX, data, scrollToNext, scrollToIndex]
	);

	return (
		<PaginatedContext.Provider value={ctxValue}>
			<View sx={{ ...sx }} {...props} />
		</PaginatedContext.Provider>
	);
};

export const ItemRoot: FunctionComponent<ComponentProps<typeof View>> = ({ sx, ...props }) => {
	const { width, height } = useWindowDimensions();
	return (
		<View
			sx={{
				width,
				height,
				...sx,
			}}
			{...props}
		/>
	);
};

// on (non mobile) web we use WebListGestureDetector to handle paging and panning
const applyGestureDetector = Platform.OS === "web" && !isMobileWebClient();

export const List: FunctionComponent<
	Omit<ComponentProps<typeof FlatList>, "onScroll" | "pagingEnabled" | "horizontal" | "data">
> = ({ sx, showsHorizontalScrollIndicator = false, decelerationRate = "fast", scrollEventThrottle = 16, ...props }) => {
	const { scrollX, data, listRef } = useContext(PaginatedContext);

	return (
		<WebListGestureDetector>
			<FlatList
				sx={{
					...sx,
				}}
				data={data}
				onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
					useNativeDriver: false,
				})}
				showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
				decelerationRate={decelerationRate}
				scrollEventThrottle={scrollEventThrottle}
				pagingEnabled={!applyGestureDetector}
				horizontal
				ref={listRef}
				{...props}
			/>
		</WebListGestureDetector>
	);
};

// TODO: not sire of I like this workaround with custom panning support. Maybe we should find a different way to allow for mouse drag control? Or simply enforce that it can ONLY be done by a mouse?
// @ts-ignore
const WebListGestureDetector: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	if (!applyGestureDetector) {
		return children;
	}

	const { listRef, scrollToIndex, getCurrentIndex } = useContext(PaginatedContext);
	const startX = useSharedValue(0);
	const startPage = useSharedValue(0);
	const { width } = useWindowDimensions();

	const panGesture = Gesture.Pan()
		.onBegin(() => {
			startPage.value = getCurrentIndex();
			startX.value = width * startPage.value - width;
		})
		.onUpdate((e) => {
			if (listRef) {
				listRef.current?.scrollToOffset({ offset: startX.value - e.translationX, animated: false });
			}
		})
		.onEnd((e) => {
			if (e.translationX > 0) {
				scrollToIndex(startPage.value - 1);
			} else if (e.translationX < 0) {
				scrollToIndex(startPage.value + 1);
			}
		});

	return <GestureDetector gesture={panGesture}>{children}</GestureDetector>;
};

export const ExpandingDots: FunctionComponent<
	{
		dotSx?: SxProp;
		containerSx?: SxProp;
		expandingDotWidth?: keyof Theme["sizes"];
		activeDotColor?: keyof Theme["colors"];
		inActiveDotColor?: keyof Theme["colors"];
	} & Omit<
		ComponentProps<typeof ExpandingDot>,
		"data" | "scrollX" | "containerStyle" | "dotStyle" | "inActiveDotColor" | "activeDotColor" | "expandingDotWidth"
	>
> = ({
	dotSx = {},
	containerSx = {},
	inActiveDotOpacity = 1,
	expandingDotWidth = "$8",
	inActiveDotColor = "$gray5",
	activeDotColor = "$text",
	...props
}) => {
	const sx = useSx();
	const { scrollX, data } = useContext(PaginatedContext);

	return (
		<ExpandingDot
			data={data}
			scrollX={scrollX}
			expandingDotWidth={useThemeSize(expandingDotWidth)}
			inActiveDotOpacity={inActiveDotOpacity}
			inActiveDotColor={useThemeColor(inActiveDotColor)}
			activeDotColor={useThemeColor(activeDotColor)}
			containerStyle={sx(containerSx)}
			dotStyle={sx(dotSx)}
			{...props}
		/>
	);
};

export const ScalingDots: FunctionComponent<
	{
		dotSx?: SxProp;
		containerSx?: SxProp;
		expandingDotWidth?: keyof Theme["sizes"];
		activeDotColor?: keyof Theme["colors"];
		inActiveDotColor?: keyof Theme["colors"];
	} & Omit<
		ComponentProps<typeof ScalingDot>,
		"data" | "scrollX" | "containerStyle" | "dotStyle" | "inActiveDotColor" | "activeDotColor"
	>
> = ({
	dotSx = {},
	containerSx = {},
	inActiveDotOpacity = 1,
	inActiveDotColor = "$gray5",
	activeDotColor = "$text",
	...props
}) => {
	const sx = useSx();
	const { scrollX, data } = useContext(PaginatedContext);

	return (
		<ScalingDot
			data={data}
			scrollX={scrollX}
			inActiveDotOpacity={inActiveDotOpacity}
			inActiveDotColor={useThemeColor(inActiveDotColor)}
			activeDotColor={useThemeColor(activeDotColor)}
			containerStyle={sx(containerSx)}
			dotStyle={sx(dotSx)}
			{...props}
		/>
	);
};

export const SlidingBorderDots: FunctionComponent<
	{
		dotSx?: SxProp;
		containerSx?: SxProp;
		slidingIndicatorSx?: SxProp;
	} & Omit<
		ComponentProps<typeof SlidingBorder>,
		"data" | "scrollX" | "containerStyle" | "dotStyle" | "slidingIndicatorStyle"
	>
> = ({ dotSx = {}, containerSx = {}, slidingIndicatorSx = {}, ...props }) => {
	const sx = useSx();
	const { scrollX, data } = useContext(PaginatedContext);

	return (
		<SlidingBorder
			data={data}
			scrollX={scrollX}
			containerStyle={sx(containerSx)}
			dotStyle={sx(dotSx)}
			slidingIndicatorStyle={sx(slidingIndicatorSx)}
			{...props}
		/>
	);
};

export const SlidingDots: FunctionComponent<
	{
		dotSx?: SxProp;
		containerSx?: SxProp;
		expandingDotWidth?: keyof Theme["sizes"];
		activeDotColor?: keyof Theme["colors"];
	} & Omit<ComponentProps<typeof SlidingDot>, "data" | "scrollX" | "containerStyle" | "dotStyle">
> = ({ dotSx = {}, containerSx = {}, marginHorizontal = 3, ...props }) => {
	const sx = useSx();
	const { scrollX, data } = useContext(PaginatedContext);

	return (
		<SlidingDot
			data={data}
			scrollX={scrollX}
			containerStyle={sx(containerSx)}
			dotStyle={sx(dotSx)}
			marginHorizontal={marginHorizontal}
			{...props}
		/>
	);
};
