import type { UserDataFields } from "@pivus/firebase-entities/user_data/UserData";
import { UserDataSchema } from "@pivus/firebase-entities/user_data/UserData";
import { Mode } from "firestorter";
import { makeObservable, observable, reaction } from "mobx";

import type { TypedDocumentOptions } from "../core/TypedDocument";
import { createSchemaValidator, TypedDocument } from "../core/TypedDocument";

import { ActivePlaybackSessionCollection } from "./PlaybackSession";
import { TodoCollection } from "./TodoCollection";
import { TrackPlaybackAggregationCollection } from "./TrackPlaybackAggregation";

export class UserDataStore extends TypedDocument<UserDataFields> {
	public uid?: string;

	private _todoCollection = new TodoCollection(undefined, { mode: Mode.Off });

	private _activePlaybackSessions = new ActivePlaybackSessionCollection(undefined, {
		mode: Mode.Off,
	});

	private _trackPlaybackAggregations = new TrackPlaybackAggregationCollection(undefined, { mode: Mode.Off });

	constructor(uid?: string, options: TypedDocumentOptions = {}) {
		super(undefined, {
			...options,
			createIfNotExists: true,
			schema: createSchemaValidator(UserDataSchema),
		});

		this.uid = uid;

		makeObservable(this, {
			uid: observable,
		});

		reaction(
			() => this.uid,
			(newUid) => {
				this.source = newUid ? `user_data/${newUid}` : undefined;

				this.todoCollection.uid = newUid;
				this.activePlaybackSessions.uid = newUid;
				this.trackPlaybackAggregations.uid = newUid;
			},
			{ fireImmediately: true }
		);
	}

	get todoCollection() {
		this._todoCollection.mode = Mode.Auto;
		return this._todoCollection;
	}

	get activePlaybackSessions() {
		this._activePlaybackSessions.mode = Mode.Auto;
		return this._activePlaybackSessions;
	}

	get trackPlaybackAggregations() {
		this._trackPlaybackAggregations.mode = Mode.Auto;
		return this._trackPlaybackAggregations;
	}
}
