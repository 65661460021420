import { observer } from "mobx-react";
import type { FunctionComponent } from "react";

import { useTrackableFlatList } from "../../../hooks/tracking/useTrackableFlatList";
import { useReactionState } from "../../../hooks/useReactionState";
import { trackDocAsAudioTrack } from "../../../stores/firebase/TrackCollection";
import { useStores } from "../../../stores/StoresProvider";
import type { TrackListItemItem } from "../TrackList";
import { TrackList } from "../TrackList";

export const ManagedTrackList: FunctionComponent = observer(() => {
	const { userStore } = useStores();
	const { trackPlaybackAggregations } = userStore.userData;

	const { onViewableItemsChanged, onItemPress } = useTrackableFlatList<TrackListItemItem>("explore", (item) => ({
		id: item.id,
		name: item.title,
	}));

	const enrichedTracks = useReactionState(() => {
		return userStore.tracks
			.map((track) => {
				const session = userStore.userData.activePlaybackSessions.getSessionByTrackId(track.id);
				return {
					...trackDocAsAudioTrack(track),
					isPlaying: track.id === userStore.currentTrack?.id,
					position: session?.data.position,
					hasPlayed: session?.data.position ? false : trackPlaybackAggregations.playedTrackIds.includes(track.id!),
				};
			})
			.filter((track) => track.id !== undefined);
	}, []);

	return (
		<TrackList
			data={enrichedTracks}
			onViewableItemsChanged={onViewableItemsChanged}
			onItemPress={(item) => {
				userStore.setTrackById(item.id);
				onItemPress(item);
			}}
		/>
	);
});
