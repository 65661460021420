/* eslint global-require: 0 */
import type { ImageSourcePropType } from "react-native";
import { Platform } from "react-native";

import Tutorial from "./assets/tutorial.png";
import type { Item } from "./OnboardingScreen";

const crossPlatformSource = (source: ImageSourcePropType) => {
	return (Platform.OS === "web" ? { uri: source } : source) as ImageSourcePropType;
};

export const items: Item[] = [
	{
		id: 1,
		title: `The app
that makes
you cry`,
		color: "$awe6",
		descriptions: ["<p>Swipe left to get introduced to<br />your inward journey with Tear Up.</p>"],
	},
	{
		id: 2,
		title: "Benefits of Tears",
		color: "$joy6",
		descriptions: [
			`

<ul>
	<li>
		Hormones such as oxytocin and endorphins are released when we cry, reducing stress, anxiety and pain.
	</li>
	<li>
		Most people instantly feel better after a good cry.
	</li>
	<li>
		Long term effects of less stress and anxiety keep us healthier and happier.
	</li>
</ul>`,
		],
		// image: crossPlatformSource(Benefits),
	},
	{
		id: 3,
		title: "How it works",
		color: "$trust4",
		descriptions: [
			"<p>Listen to the audio stories in this app and tag your crying emotions so our algorithm can suggest the most tear invoking stories for you.</p>",
		],
		// image: crossPlatformSource(ThisApp),
	},
	{
		id: 4,
		title: `Tagging what
you feel`,
		color: "$calm5",
		descriptions: [
			`<p>Tag stories that make you feel angry or move you to tears, and we'll help you find more stories that will help you explore your deepest emotions, heal, and grow.</p>`,
			`<p>If a story feels overwhelming, stop listening and take care of yourself. Your need for self-care also helps us learn and provide better recommendations in the future.</p>
`,
		],
		images: [undefined, crossPlatformSource(Tutorial)],
	},
	{
		id: 5,
		title: `Lets get
started!`,
		color: "$relief3",
		descriptions: ["<p>Login or create an account so you can have a personalized experience!</p>"],
		// image: crossPlatformSource(ThisApp),
	},
];
