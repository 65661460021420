import { Button } from "@pivus/ui/Button/Button";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { useCallback, useEffect, useState } from "react";
import type { ComponentProps, FunctionComponent } from "react";

import { FieldInput } from "../FieldInput/FieldInput";

type Fields = {
	name: string;
	email: string;
	password: string;
};

// TODO: Standardize this with LoginForm
export const SignupForm: FunctionComponent<
	{
		loading?: boolean;
		onSubmit?: (data: Fields) => void;
		onChange?: (data: Fields) => void;
	} & ComponentProps<typeof View>
> = ({ loading = false, onSubmit, onChange, ...props }) => {
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	useEffect(() => {
		onChange?.({ name, email, password });
	}, [name, email, password]);

	const submitSignup = useCallback(() => {
		onSubmit?.({ name, email, password });
	}, [name, email, password]);

	// TODO: wrap this in a form on web

	return (
		<View {...props}>
			<FieldInput
				placeholder="Enter your name"
				value={name}
				onChangeText={setName}
				keyboardType="default"
				autoComplete="name"
				editable={!loading}
				onSubmitEditing={submitSignup}
			/>
			<FieldInput
				placeholder="Enter your email"
				value={email}
				onChangeText={setEmail}
				keyboardType="email-address"
				autoComplete="email"
				editable={!loading}
				onSubmitEditing={submitSignup}
			/>
			<FieldInput
				placeholder="Enter your password"
				value={password}
				onChangeText={setPassword}
				autoComplete="password"
				secureTextEntry
				editable={!loading}
				onSubmitEditing={submitSignup}
				sx={{ marginBottom: "$4" }}
			/>

			<Button label="Create account" onPress={submitSignup} color="secondary" disabled={loading} />
		</View>
	);
};
