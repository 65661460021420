import type { FontSource } from "expo-font/src/Font.types";
import { Platform } from "react-native";

type FontFace = {
	weight: "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";
	src: FontSource;
	isNormal?: boolean;
};

type FontFamily = {
	family: string;
	fallback: string;
	faces: FontFace[];
};

type FontMap = Record<
	string,
	{ family: string; variants: Record<string, string>; sources: Record<string, FontSource> }
>;

export const createFonts = (fontFamilies: FontFamily[]) =>
	fontFamilies.reduce((fonts, { family, fallback, faces }) => {
		return {
			...fonts,
			[family]: {
				family,
				// pass this to dripsy customFonts
				variants: faces.reduce((variants, { weight, isNormal }) => {
					const font = isNormal ? family : `${family}-${weight}`; // normal font is not suffixed with weight
					const fontName = Platform.select({
						web: `${font}, ${fallback}`,
						default: font,
					});
					return {
						...variants,
						[weight.toString()]: fontName,
						...(isNormal ? { normal: fontName, default: fontName } : {}),
					};
				}, {} as Record<string, string>),
				// pass this to expo-font's useFonts
				sources: faces.reduce((sources, { weight, src, isNormal }) => {
					return {
						...sources,
						[isNormal ? family : `${family}-${weight}`]: src, // normal font is not suffixed with weight
					};
				}, {} as Record<string, FontSource>),
			},
		};
	}, {} as FontMap);
