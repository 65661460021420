/* eslint global-require: 0 */
import type { SVGReactComponent } from "@pivus/svg-loader";

export const Care: SVGReactComponent = require("./svg/care.svg").default;
export const Check: SVGReactComponent = require("./svg/check.svg").default;
export const CheckCircular: SVGReactComponent = require("./svg/checkCircular.svg").default;
export const Close: SVGReactComponent = require("./svg/close.svg").default;
export const Down: SVGReactComponent = require("./svg/down.svg").default;
export const Explore: SVGReactComponent = require("./svg/explore.svg").default;
export const Forward: SVGReactComponent = require("./svg/forward.svg").default;
export const History: SVGReactComponent = require("./svg/history.svg").default;
export const Insights: SVGReactComponent = require("./svg/insights.svg").default;
export const Left: SVGReactComponent = require("./svg/left.svg").default;
export const Menu: SVGReactComponent = require("./svg/menu.svg").default;
export const Next: SVGReactComponent = require("./svg/next.svg").default;
export const Pause: SVGReactComponent = require("./svg/pause.svg").default;
export const Play: SVGReactComponent = require("./svg/play.svg").default;
export const Previous: SVGReactComponent = require("./svg/previous.svg").default;
export const Rewind: SVGReactComponent = require("./svg/rewind.svg").default;
export const Right: SVGReactComponent = require("./svg/right.svg").default;
export const Sort: SVGReactComponent = require("./svg/sort.svg").default;
export const Stop: SVGReactComponent = require("./svg/stop.svg").default;
export const Tag: SVGReactComponent = require("./svg/tag.svg").default;
export const Tear: SVGReactComponent = require("./svg/tear.svg").default;
export const Thunder: SVGReactComponent = require("./svg/thunder.svg").default;
export const Up: SVGReactComponent = require("./svg/up.svg").default;
