import type { BottomTabBarProps, BottomTabNavigationOptions } from "@react-navigation/bottom-tabs";
import { CommonActions } from "@react-navigation/native";
import { Platform } from "react-native";

/**
 * A hook to get the standard props for a tab in a bottom tab bar.
 * This is useful, so it can be reused in custom tab bars and have the native event handling.
 * @see @react-navigation/bottom-tabs/src/views/BottomTabBar.tsx
 */
export const useDefaultTabProps = <Options extends BottomTabNavigationOptions>({
	index,
	state,
	route,
	descriptors,
	navigation,
}: {
	index: number;
	state: BottomTabBarProps["state"];
	route: BottomTabBarProps["state"]["routes"][number];
	descriptors: BottomTabBarProps["descriptors"];
	navigation: BottomTabBarProps["navigation"];
}) => {
	const focused = index === state.index;
	const options = descriptors[route.key].options as Options;

	const onPress = () => {
		const event = navigation.emit({
			type: "tabPress",
			target: route.key,
			canPreventDefault: true,
		});

		if (!focused && !event.defaultPrevented) {
			navigation.dispatch({
				...CommonActions.navigate({ name: route.name, merge: true }),
				target: state.key,
			});
		}
	};

	const onLongPress = () => {
		navigation.emit({
			type: "tabLongPress",
			target: route.key,
		});
	};

	const label = options.tabBarLabel !== undefined ? (options.tabBarLabel as Options["tabBarLabel"]) : options.title;

	const accessibilityLabel =
		options.tabBarAccessibilityLabel !== undefined
			? options.tabBarAccessibilityLabel
			: typeof label === "string" && Platform.OS === "ios"
			? `${label}, tab, ${index + 1} of ${state.routes.length}`
			: undefined;

	return { focused, options, onPress, onLongPress, label, accessibilityLabel };
};
