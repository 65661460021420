import { DripsyPlay } from "@pivus/ui/example/DripsyPlay/DripsyPlay";
import { MotiPlay } from "@pivus/ui/example/MotiPlay/MotiPlay";
import { SimpleButton } from "@pivus/ui/example/SimpleButton/SimpleButton";
import { Close } from "@pivus/ui/Icons";
import { SafeAreaView, ScrollView } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useLinkTo } from "@react-navigation/native";

import { SimpleNavigation } from "../../components/example/SimpleNavigation/SimpleNavigation";

export const SimpleHomeScreen = () => {
	const linkTo = useLinkTo();
	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<ScrollView sx={{ padding: "$4" }}>
				<Text>Simple home!</Text>
				<SimpleNavigation />

				<DripsyPlay />
				<MotiPlay />

				<Close color="red" width={50} height={50} />
				<SimpleButton title="Profile" variant="primary" onPress={() => linkTo("/simple/profile")} />
			</ScrollView>
		</SafeAreaView>
	);
};
