import { useThemeSpace, useThemeToken } from "@pivus/ui/style/useThemeToken";
import type { Route } from "@react-navigation/native";
import { NavigationContainer, useNavigationContainerRef, useTheme } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as SplashScreen from "expo-splash-screen";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { initialWindowMetrics } from "react-native-safe-area-context";
import Toast from "react-native-toast-message";

import { baseToastConfig } from "./components/Toasts/Toasts";
import { logEvent } from "./firebase/analytics";
import { initializeFirebase } from "./firebase/firebase";
import { useTrackableCrossPlayer } from "./hooks/tracking/useTrackableCrossPlayer";
import { useCrossPlayerNativeControlsTagger } from "./hooks/useCrossPlayerNativeControlsTagger";
import { RootStackNavigator } from "./navigation/RootStackNavigator";
import type { RootStackParams } from "./navigation/stacks";
import { StoresProvider, useStores } from "./stores/StoresProvider";

initializeFirebase();

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const StartupOrApp = observer(() => {
	const { userStore } = useStores();

	useEffect(() => {
		reaction(
			() => userStore.initializingAuth,
			(initializing) => {
				if (!initializing) {
					SplashScreen.hideAsync();
				}
			}
		);
	}, []);

	return userStore.initializingAuth ? null : <App />;
});

const getPageTitle = (options?: Record<string, any>) => {
	if (options) {
		return `${options?.title} - TearUp`;
	}
	return `TearUp`;
};

export const AppProvider = () => {
	return (
		<StoresProvider>
			<StartupOrApp />
		</StoresProvider>
	);
};

const App = () => {
	const navigationTheme = useTheme();
	const navigationRef = useNavigationContainerRef<RootStackParams>();
	const [currentRoute, setCurrentRoute] = useState<Route<string, object | undefined>>();

	// Enables forward/backward buttons on headphones/homescreen to tag emotions
	useCrossPlayerNativeControlsTagger();

	// Adds tracking of the cross player
	useTrackableCrossPlayer();

	useEffect(() => {
		if (currentRoute) {
			logEvent("screen_view", {
				page_title: getPageTitle(navigationRef.getCurrentOptions()),
				screen_name: currentRoute.name,
				screen_class: currentRoute.name,
			});
		}
	}, [currentRoute]);

	return (
		<>
			<NavigationContainer<RootStackParams>
				ref={navigationRef}
				onReady={() => {
					setCurrentRoute(navigationRef.getCurrentRoute());
				}}
				onStateChange={() => {
					setCurrentRoute(navigationRef.getCurrentRoute());
				}}
				documentTitle={{
					formatter: getPageTitle,
				}}
				theme={{
					...navigationTheme,
					colors: {
						...navigationTheme.colors,
						// TODO improve these colors
						card: useThemeToken("colors", "$relief10"), // header/drawer background
						primary: useThemeToken("colors", "$relief1"), // header text nav (back)
						text: useThemeToken("colors", "$text"), // header text
						border: useThemeToken("colors", "$gray9"), // combines with drop shadow in Screen headings
						background: useThemeToken("colors", "$background"),
						notification: useThemeToken("colors", "$trust1"),
					},
				}}
				linking={useMemo(
					() => ({
						prefixes: [Linking.createURL("/")],
						config: {
							screens: {
								notFound: "*",
								setupStack: {
									path: "setup",
									screens: {
										onboarding: "onboarding",
										login: "login",
										signUp: "signup",
									},
								},
								drawerStack: {
									path: "",
									screens: {
										tabStack: {
											path: "",
											screens: {
												explore: "",
												care: "care",
												history: "history",
											},
										},
									},
								},
								simpleStack: {
									path: "/simple",
									screens: {
										home: "",
										audio: "audio",
										firebaseTester: "firebaseTester",
										profileStack: {
											path: "profile",
											screens: {
												overview: "",
												details: ":slug",
											},
										},
									},
								},
							},
						},
					}),
					[]
				)}
			>
				<RootStackNavigator />
			</NavigationContainer>
			<SafeAreaToast />
		</>
	);
};

const SafeAreaToast = () => {
	const space = useThemeSpace("$6");
	return <Toast config={baseToastConfig} topOffset={(initialWindowMetrics?.insets.top || 0) + space} />;
};
