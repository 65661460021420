import type { DependencyList } from "react";
import { useState } from "react";

import { useReactionEffect } from "./useReactionEffect";

/**
 * Uses the value as returned by a mobx reaction as the state, so it is essentially a reactive computed value
 */
export function useReactionState<T>(reactionFn: () => T, deps: DependencyList = []) {
	const [state, setState] = useState<T>(reactionFn);
	useReactionEffect(reactionFn, setState, { deps });

	return state;
}
