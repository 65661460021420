import { throttle } from "lodash";
import type { ComponentProps, FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { GestureResponderEvent, LayoutChangeEvent } from "react-native";

import { styled, View } from "../style/primitives/dripsy";
import { useThemeSize } from "../style/useThemeToken";

const Root = styled(View)({
	position: "relative",
	width: "100%",
});

const Bar = styled(View)(({ disabled }: { disabled: boolean }) => ({
	position: "absolute",
	top: "$1",
	height: "$1",
	width: "100%",
	backgroundColor: disabled ? "$gray11" : "$gray10",
	borderRadius: "$1",
}));

const Progress = styled(View)(({ disabled }: { disabled: boolean }) => ({
	height: "$1",
	width: "100%",
	backgroundColor: disabled ? "$gray9" : "$black",
	borderRadius: "$1",
}));

const Thumb = styled(View)(({ disabled }: { disabled: boolean }) => ({
	backgroundColor: disabled ? "$gray9" : "$black",
	size: "$3",
	borderRadius: "$3",
}));

export const Slider: FunctionComponent<
	{
		disabled?: boolean;
		position?: number;
		onChange?: (change: { position: number; isSliding: boolean }) => void;
	} & ComponentProps<typeof Root>
> = ({ disabled = false, position: positionProp = 0, onChange, ...props }) => {
	const [sliderWidth, setSliderWidth] = useState(0);
	const thumbWidth = useThemeSize("$3");
	const [isSliding, setIsSliding] = useState(false);
	const [currentPosition, setCurrentPosition] = useState(positionProp);

	const updateDragPosition = useCallback(
		throttle((locationX: number, newIsSliding: boolean) => {
			const newPosition = Math.min(1, Math.max(0, locationX / sliderWidth));
			setCurrentPosition(newPosition);

			if (newIsSliding !== isSliding) {
				setIsSliding(newIsSliding);
			}

			// Inform about the manual change
			onChange?.({ position: newPosition, isSliding: newIsSliding });
		}, 1000 / 60), // updates at 60fps
		[sliderWidth, setIsSliding, isSliding, setCurrentPosition, onChange]
	);

	useEffect(() => {
		if (!isSliding) {
			setCurrentPosition(Math.min(1, Math.max(0, positionProp)));
		}
	}, [positionProp, isSliding, setCurrentPosition]);

	// TODO cancel gestures while interacting with the slider, see https://www.notion.so/Slider-doesn-t-cancel-native-navigation-gestures-ab79fcc1ffca478f94d9ba25281a3eab?pvs=4
	return (
		<Root
			{...props}
			onStartShouldSetResponder={useCallback(() => !disabled, [disabled])}
			onResponderStart={useCallback(
				(event: GestureResponderEvent) => {
					updateDragPosition(event.nativeEvent.locationX, true);
				},
				[updateDragPosition, setIsSliding]
			)}
			onResponderMove={useCallback(
				(event: GestureResponderEvent) => {
					updateDragPosition(event.nativeEvent.locationX, true);
				},
				[updateDragPosition]
			)}
			onResponderRelease={useCallback(
				(event: GestureResponderEvent) => {
					updateDragPosition(event.nativeEvent.locationX, false);
				},
				[updateDragPosition]
			)}
			onLayout={useCallback(
				(event: LayoutChangeEvent) => {
					setSliderWidth(event.nativeEvent.layout.width);
				},
				[setSliderWidth]
			)}
		>
			<Bar pointerEvents="none" disabled={disabled}>
				<Progress
					disabled={disabled}
					sx={useMemo(
						() => ({ width: thumbWidth + (sliderWidth - thumbWidth) * currentPosition }),
						[thumbWidth, sliderWidth, currentPosition]
					)}
				/>
			</Bar>
			<Thumb
				disabled={disabled}
				pointerEvents="none"
				sx={useMemo(
					() => ({ transform: [{ translateX: (sliderWidth - thumbWidth) * currentPosition }] }),
					[thumbWidth, sliderWidth, currentPosition]
				)}
			/>
		</Root>
	);
};
