import { useCrossPlayerControls, useCrossPlayerPlaybackStatus, useCrossPlayerTrack } from "@pivus/cross-player/hooks";
import { BottomOverlay, sizeTokens } from "@pivus/ui/BottomOverlay/BottomOverlay";
import { MotiView } from "@pivus/ui/style/primitives/moti";
import type { ComponentProps, FunctionComponent, ReactElement } from "react";
import { useCallback, useEffect, useMemo, useState, Children, useReducer } from "react";
import type { LayoutChangeEvent } from "react-native";
import { Easing } from "react-native-reanimated";

import { logUiEvent } from "../../firebase/analytics-utils";
import { PlayPauseButton } from "../AudioPlayer/AudioPlayer";
import { BottomPlayer } from "../AudioPlayer/BottomPlayer/BottomPlayer";

import { Completed } from "./Screen/Completed/Completed";
import { Emergency } from "./Screen/Emergency/Emergency";

const AnimatedPlayPauseButton: FunctionComponent<{ visible: boolean } & ComponentProps<typeof PlayPauseButton>> = ({
	visible,
	...props
}) => {
	return (
		<MotiView
			pointerEvents={visible ? undefined : "none"}
			transition={useMemo(
				() => ({
					type: "timing",
					delay: visible ? 50 : 0, // slight delay so play-pause-button can transition to the updated (non-locked) state
					duration: visible ? 350 : 200,
					easing: visible ? Easing.in(Easing.elastic(1.3)) : Easing.in(Easing.back(1.3)),
				}),
				[visible]
			)}
			animate={{
				opacity: visible ? 1 : 0,
				transform: [{ scale: visible ? 1 : 0 }],
			}}
			sx={{
				position: "absolute",
				zIndex: 2, // Place on top of the TabBar
				left: "50%",
				bottom: "$8",
				marginLeft: "-$8", // half the PlayPauseButton size
			}}
		>
			<PlayPauseButton size="$16" lockState={!visible} {...props} />
		</MotiView>
	);
};

export type BottomOverlayPlayerScreen = "player" | "emergency" | "completed";
export const BottomOverlayPlayer: FunctionComponent<{
	onPlayPausePress?: () => void;
	onTag?: Required<ComponentProps<typeof BottomPlayer>>["onTag"];
	onCloseRequest?: ({
		source,
		dontPlayChecked,
		screen,
	}: {
		source: "overlay" | "close-button" | "care-button";
		dontPlayChecked: boolean;
		screen: BottomOverlayPlayerScreen;
	}) => void;
	onCloseTransitionCompleted?: () => void;
}> = ({ onPlayPausePress, onTag, onCloseRequest, onCloseTransitionCompleted }) => {
	const { crossPlayerTrack } = useCrossPlayerTrack();
	const playerControls = useCrossPlayerControls();
	const playbackStatus = useCrossPlayerPlaybackStatus();
	const [screen, setScreen] = useState<BottomOverlayPlayerScreen>("player");

	// We're caching the title so that it doesn't disappear we're destroying the track. Instead, we simply transition out
	const [cachedTitle, setCachedTitle] = useState(crossPlayerTrack?.title || " ");
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenTransitioning, setIsOpenTransitioning] = useState(false);

	useEffect(() => {
		if (crossPlayerTrack?.title) {
			setCachedTitle(crossPlayerTrack.title);
		}

		if (!!crossPlayerTrack !== isOpen) {
			setIsOpen(!!crossPlayerTrack);
		}
	}, [crossPlayerTrack]);

	useEffect(() => {
		if (playbackStatus === "stopped" && !isOpenTransitioning && isOpen) {
			setScreen("completed");
			logUiEvent({
				ui_component: "bottom_overlay_player",
				ui_trigger: "playback_status",
				ui_action: "played_track_till_end",
				ui_ref: crossPlayerTrack?.id,
			});
		}
	}, [playbackStatus, isOpen, isOpenTransitioning]);

	useEffect(() => {
		if (!isOpen && !isOpenTransitioning) {
			// At the end of a close
			setScreen("player");
			onCloseTransitionCompleted?.();
		}
	}, [isOpen, isOpenTransitioning]);

	const transitionOpen = useCallback(
		(open: boolean) => {
			setIsOpen(open);
			setIsOpenTransitioning(true);
		},
		[setIsOpen, setIsOpenTransitioning]
	);

	return (
		<>
			<AnimatedPlayPauseButton
				// TODO: when playbackStatus changes to stopped, the button already animates to the stopped state before we have the chance to change the visibility here
				visible={!isOpen || screen === "player"}
				onPress={useCallback(() => {
					if (!isOpen) {
						transitionOpen(true);
					}
					onPlayPausePress?.();
				}, [transitionOpen, onPlayPausePress, isOpen])}
			/>
			<BottomOverlay
				open={isOpen}
				onOpenChange={transitionOpen}
				onOpenComplete={useCallback(() => {
					setIsOpenTransitioning(false);
				}, [setIsOpenTransitioning])}
				onClosePress={useCallback(() => {
					// TODO: screen is somehow always the player screen here...
					onCloseRequest?.({ source: "overlay", dontPlayChecked: false, screen });
				}, [onCloseRequest, screen])}
			>
				<ViewStack
					duration={isOpen ? 300 : 0}
					viewIndex={useMemo(() => {
						if (screen === "completed") return 2;
						if (screen === "emergency") return 1;
						return 0;
					}, [screen])}
				>
					<BottomPlayer
						title={cachedTitle}
						id={crossPlayerTrack?.id}
						sx={{ marginTop: `-${sizeTokens.contentPaddingYToken}` }}
						onTag={(type) => {
							if (type === "emergency") {
								playerControls.pause();
								setScreen("emergency");
							}
							onTag?.(type);
						}}
					/>

					{/* TODO: as the ViewStack is always present the checkbox state never gets reset in these components while ideally it should */}

					<Emergency
						onButtonPress={useMemo(
							() => (button, dontPlayChecked) => {
								if (button === "close" || button === "care") {
									onCloseRequest?.({ source: `${button}-button`, dontPlayChecked, screen: "emergency" });
								}

								if (button === "close") {
									transitionOpen(false);
								}
								if (button === "play") {
									setScreen("player");
									playerControls.play();

									logUiEvent({
										ui_component: "bottom_overlay_player",
										ui_trigger: "emergency",
										ui_action: "continue",
										ui_ref: crossPlayerTrack?.id,
									});
								}
								if (button === "care") {
									transitionOpen(false);
								}
							},
							[transitionOpen, setScreen, playerControls, onCloseRequest]
						)}
					/>

					<Completed
						onButtonPress={useMemo(
							() => (button, dontPlayChecked) => {
								onCloseRequest?.({ source: `${button}-button`, dontPlayChecked, screen: "completed" });
								transitionOpen(false);
							},
							[transitionOpen, onCloseRequest]
						)}
					/>
				</ViewStack>
			</BottomOverlay>
		</>
	);
};

// TODO: This ViewStack could be its own component
const stackHeightReducer = (
	state: { [screenIndex: number]: number },
	reducerAction: { type: "SET_HEIGHT"; payload: { screenIndex: number; height: number } }
) => {
	const { type, payload } = reducerAction;
	switch (type) {
		case "SET_HEIGHT":
			return {
				...state,
				[payload.screenIndex]: payload.height,
			};
		default:
			return state;
	}
};
export const ViewStack: FunctionComponent<
	{
		children: ReactElement[];
		viewIndex: number;
		duration?: number;
		onTransition?: (isTransitioning: boolean) => void;
	} & Omit<ComponentProps<typeof MotiView>, "transition" | "animate">
> = ({ children, viewIndex, duration = 300, sx, onTransition, onDidAnimate, ...props }) => {
	const [stackHeights, dispatch] = useReducer(stackHeightReducer, {});
	const [isTransitioning, setIsTransitioning] = useState(false);
	const height = useMemo(() => stackHeights[viewIndex], [viewIndex, stackHeights]);

	useEffect(() => setIsTransitioning(true), [height]);
	useEffect(() => onTransition?.(isTransitioning), [isTransitioning, onTransition]);

	return (
		<MotiView
			transition={useMemo(
				() => ({
					type: "timing",
					duration,
					easing: Easing.inOut(Easing.cubic),
				}),
				[duration]
			)}
			animate={useMemo(
				() => ({
					height,
				}),
				[height]
			)}
			sx={{
				position: "relative",
				...sx,
			}}
			onDidAnimate={useCallback(
				// TODO: resolve these ts-ignore errors
				// @ts-ignore
				(...args) => {
					setIsTransitioning(false);
					// @ts-ignore
					onDidAnimate?.(...args);
				},
				[onTransition, onDidAnimate]
			)}
			{...props}
		>
			{Children.map(children, (child, index) => {
				const childVisible = viewIndex === index;
				return (
					<MotiView
						pointerEvents={childVisible ? undefined : "none"}
						transition={useMemo(
							() => ({
								type: "timing",
								duration: duration / 2,
								delay: childVisible ? duration / 2 : 0,
								easing: childVisible ? Easing.out(Easing.circle) : Easing.in(Easing.circle),
							}),
							[childVisible, duration]
						)}
						animate={useMemo(
							() => ({
								opacity: childVisible ? 1 : 0,
							}),
							[childVisible]
						)}
						onLayout={(event: LayoutChangeEvent) =>
							dispatch({ type: "SET_HEIGHT", payload: { screenIndex: index, height: event.nativeEvent.layout.height } })
						}
						sx={{
							position: "absolute",
							width: "100%",
						}}
					>
						{child}
					</MotiView>
				);
			})}
		</MotiView>
	);
};
