import type { ComponentProps, FunctionComponent } from "react";
import RenderHTML, { defaultSystemFonts, HTMLContentModel, HTMLElementModel } from "react-native-render-html";

import { fonts } from "../style/fonts/theme.fonts";
import type { SxProp } from "../style/primitives/dripsy";
import { useSx } from "../style/primitives/dripsy";
import type { Theme } from "../style/theme.dripsy";
import { useTextVariantStyle } from "../style/useTextVariantStyle";

export const htmlFonts = [
	...Object.keys(fonts.main.sources),
	...Object.keys(fonts.heading.sources),
	...Object.keys(fonts.ui.sources),
	...defaultSystemFonts,
];

export const basicCustomHtmlElementsModels = {
	strong: HTMLElementModel.fromCustomModel({
		tagName: "strong",
		mixedUAStyles: {
			fontFamily: "main-700",
			fontWeight: "700",
		},
		contentModel: HTMLContentModel.textual,
	}),
};

export const BasicHTML: FunctionComponent<
	{ baseStyleSx?: SxProp; variant?: keyof Theme["text"]["text"]["variants"]["variant"] } & Omit<
		ComponentProps<typeof RenderHTML>,
		"baseStyle"
	>
> = ({
	baseStyleSx,
	variant = "body",
	systemFonts,
	customHTMLElementModels = basicCustomHtmlElementsModels,
	...props
}) => {
	const sx = useSx();

	return (
		<RenderHTML
			baseStyle={sx({
				...useTextVariantStyle("text", "variant", variant).style,
				...baseStyleSx,
			})}
			systemFonts={htmlFonts}
			customHTMLElementModels={customHTMLElementModels}
			{...props}
		/>
	);
};
