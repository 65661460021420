import type { Track } from "react-native-track-player";

export type CrossTrack = undefined | (Track & { id: string; url: string });
export type PlaybackStatus = undefined | "playing" | "paused" | "loading" | "stopped";
export type SetTrackOptions = { autoPlay?: boolean };

type CrossPlayerEvents = {
	initialized: (payload: boolean) => void;
	playbackStatusChanged: (payload: PlaybackStatus) => void;
	trackChanged: (payload: CrossTrack) => void;
	positionChanged: (payload?: number) => void;
	durationChanged: (payload?: number) => void;
	nativeRemotePrevious: () => void;
	nativeRemoteNext: () => void;
	nativeRemoteSeek: (position: number) => void;
	nativeRemotePlay: () => void;
	nativeRemotePause: () => void;
};

export class BaseCrossPlayer {
	private _initialized = false;

	private _track?: CrossTrack;

	private _playbackStatus?: PlaybackStatus;

	private _position?: number;

	private _duration?: number;

	protected _events: {
		type: keyof CrossPlayerEvents;
		listener: Partial<CrossPlayerEvents[keyof CrossPlayerEvents]>;
	}[] = [];

	protected initialize(): void {
		this.initialized = true;
	}

	public get initialized() {
		return this._initialized;
	}

	protected set initialized(initialized) {
		if (initialized !== this._initialized) {
			this._initialized = initialized;
			this.dispatchEvent("initialized", initialized);
		}
	}

	public get playbackStatus() {
		return this._playbackStatus;
	}

	protected set playbackStatus(status) {
		if (status !== this._playbackStatus) {
			this._playbackStatus = status;
			this.dispatchEvent("playbackStatusChanged", status);
		}
	}

	public get track() {
		return this._track;
	}

	protected set track(track) {
		if (track !== this._track) {
			this._track = track;
			this.dispatchEvent("trackChanged", track);
		}
	}

	// eslint-disable-next-line no-unused-vars
	public setTrack = async (newTrack: CrossTrack, options: SetTrackOptions = {}) => {
		await Promise.reject(new Error("Not implemented"));
	};

	// TODO: stubs like these should be defined by the interface
	public play = async () => {
		await Promise.reject(new Error("Not implemented"));
	};

	public pause = async () => {
		await Promise.reject(new Error("Not implemented"));
	};

	public stop = async () => {
		await Promise.reject(new Error("Not implemented"));
	};

	// eslint-disable-next-line no-unused-vars
	public seekTo = async (position: number) => {
		await Promise.reject(new Error("Not implemented"));
	};

	public get position() {
		return this._position;
	}

	protected set position(position) {
		if (position !== this._position) {
			this._position = position;
			this.dispatchEvent("positionChanged", position);
		}
	}

	public get duration() {
		return this._duration;
	}

	protected set duration(duration) {
		if (duration !== this._duration) {
			this._duration = duration;
			this.dispatchEvent("durationChanged", duration);
		}
	}

	public addEventListener = <EventName extends keyof CrossPlayerEvents, Listener extends CrossPlayerEvents[EventName]>(
		eventName: EventName,
		listener: Listener
	) => {
		this._events.push({ type: eventName, listener });
	};

	public removeEventListener = <
		EventName extends keyof CrossPlayerEvents,
		Listener extends CrossPlayerEvents[EventName]
	>(
		eventName: EventName,
		listener: Listener
	) => {
		this._events = this._events.filter((event) => !(event.type === eventName && event.listener === listener));
	};

	protected dispatchEvent = <
		EventName extends keyof CrossPlayerEvents,
		Payload extends Parameters<CrossPlayerEvents[EventName]>[0]
	>(
		eventName: EventName,
		payload: Payload
	) => {
		// setImmediate is used to ensure that events are batched, so if the trigger of this event would cause another event to fire,
		// it would only do so after this event has been dispatched completely and prevents inaccurate state updates
		setImmediate(() => {
			this._events.forEach((event) => {
				if (event.type === eventName) {
					const listener = event.listener as (val: Payload) => void;
					listener(payload);
				}
			});
		});
	};

	public destroy() {
		// TODO: the cast to any shouldn't be needed
		this._events.forEach((event) => this.removeEventListener(event.type, event.listener as any));
	}
}
