import { AppProvider } from "@pivus/app/AppProvider";
import { CrossPlayerProvider } from "@pivus/cross-player";
import { StyledDripsyProvider } from "@pivus/ui/style/theme.dripsy";
// @ts-ignore
if (process.browser) {
	// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
	// @ts-ignore
	// eslint-disable-next-line no-undef,no-underscore-dangle
	window._frameTimestamp = null;
}

const App = () => {
	return (
		<CrossPlayerProvider>
			<StyledDripsyProvider>
				<AppProvider />
			</StyledDripsyProvider>
		</CrossPlayerProvider>
	);
};

export default App;
