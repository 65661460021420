import { Sort } from "@pivus/ui/Icons";
import { Pressable, View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import { observer } from "mobx-react";
import type { ComponentProps, FunctionComponent } from "react";
import { useMemo, useState } from "react";

import { useTrackableFlatList } from "../../hooks/tracking/useTrackableFlatList";
import { useReactionState } from "../../hooks/useReactionState";
import { useStores } from "../../stores/StoresProvider";

import type { HistoryListItemItem } from "./HistoryList";
import { HistoryList, ItemSeparator } from "./HistoryList";

const OrderButton: FunctionComponent<{ isActive: boolean } & ComponentProps<typeof Pressable>> = ({
	isActive,
	children,
	sx,
	...props
}) => {
	return (
		<Pressable
			sx={{
				padding: "$2",
				...sx,
			}}
			{...props}
		>
			<Text sx={{ fontWeight: isActive ? "700" : undefined }}>{children}</Text>
		</Pressable>
	);
};

const Header: FunctionComponent<{
	orderBy: "date" | "interactions";
	setOrderBy: (order: "date" | "interactions") => void;
}> = ({ orderBy, setOrderBy }) => {
	return (
		<>
			<View sx={{ marginX: "$4" }}>
				<Text sx={{ marginY: "$4" }}>
					This is an overview of all the audio&apos;s that have started playing or have tagged emotions, sorted by the
					tracks you have interacted with the most.
				</Text>

				<View sx={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginX: "-$2" }}>
					<View sx={{ size: "$4" }}>
						<Sort color={useThemeColor("$text")} />
					</View>

					<OrderButton isActive={orderBy === "interactions"} onPress={() => setOrderBy("interactions")}>
						{orderBy === "interactions" ? "Ordered" : "Order"} by interactions
					</OrderButton>

					<OrderButton isActive={orderBy === "date"} onPress={() => setOrderBy("date")}>
						{orderBy === "date" ? "Ordered" : "Order"} by date
					</OrderButton>
				</View>
			</View>
			<ItemSeparator />
		</>
	);
};

const Footer = () => {
	return (
		<>
			<ItemSeparator />
			<Text variant="bodySmall" sx={{ margin: "$4", marginBottom: "$6", color: "$gray8" }}>
				Please note that tracks that you don&apos;t want to hear anymore got blocked and won&apos;t appear in the list
				above.
			</Text>
		</>
	);
};

const Empty = () => {
	return <Text sx={{ margin: "$4" }}>You haven&apos;t played any track or tagged any emotions yet</Text>;
};

type ItemData = HistoryListItemItem & { interactions: number };

export const ManagedHistoryList: FunctionComponent = observer(() => {
	const { userStore } = useStores();
	const { trackPlaybackAggregations } = userStore.userData;
	const [orderBy, setOrderBy] = useState<"date" | "interactions">("interactions");

	const { onViewableItemsChanged, onItemPress } = useTrackableFlatList<HistoryListItemItem>("history", (item) => ({
		id: item.id,
		name: item.title,
	}));

	const trackHistory = useReactionState(() => {
		return trackPlaybackAggregations.docs
			.map((trackAggregation) => {
				const track = userStore.tracks.find((t) => t.id === trackAggregation.id);
				if (track === undefined || track.id === undefined) return undefined;

				const session = userStore.userData.activePlaybackSessions.getSessionByTrackId(trackAggregation.id);

				return {
					id: track.id,
					title: track.data.title,
					duration: track.data.duration,
					position: session?.data.position,
					plays: trackAggregation.data.plays,
					tags: {
						cry: trackAggregation.data.tags.cry,
						anger: trackAggregation.data.tags.anger,
					},
					isPlaying: track.id === userStore.currentTrack?.id,
					interactions: trackAggregation.data.plays + trackAggregation.data.tags.cry + trackAggregation.data.tags.anger,
				};
			})
			.filter((item) => item !== undefined) as ItemData[];
	}, []);

	const sortedItems = useMemo(() => {
		if (orderBy === "date") return trackHistory;
		return [...trackHistory].sort((a, b) => b.interactions - a.interactions);
	}, [trackHistory, orderBy]);

	return (
		<HistoryList
			data={sortedItems}
			onViewableItemsChanged={onViewableItemsChanged}
			onItemPress={(item) => {
				userStore.setTrackById(item.id);
				onItemPress(item);
			}}
			ListHeaderComponent={sortedItems.length ? <Header orderBy={orderBy} setOrderBy={setOrderBy} /> : undefined}
			ListFooterComponent={sortedItems.length ? Footer : undefined}
			ListEmptyComponent={Empty}
		/>
	);
});
