import type { FlatList } from "@pivus/ui/style/primitives/dripsy";
import type { ComponentProps } from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { logEvent } from "../../firebase/analytics";
import useThrottle from "../useThrottle";

/**
 * Tracks the items that are visible and selected (pressed) in a FlatList
 * @param listName
 * @param itemExtractor
 */
export const useTrackableFlatList = <Item, Entry extends { id: string; name: string } = { id: string; name: string }>(
	listName: string,
	itemExtractor: (item: Item) => Entry
) => {
	const [visibleEntries, setVisibleEntries] = useState<Entry[]>([]);
	const throttledEntries = useThrottle(visibleEntries, 250); // Throttle the entries to avoid executing the effect too often
	const trackedEntries = useRef<Entry[]>([]);

	const onViewableItemsChanged: ComponentProps<typeof FlatList>["onViewableItemsChanged"] = useMemo(
		() =>
			({ viewableItems }) => {
				setVisibleEntries(viewableItems.filter((item) => item.isViewable).map(({ item }) => itemExtractor(item)));
			},
		[]
	);

	const onItemPress = useMemo(
		() => (item: Item) => {
			const entry = itemExtractor(item);
			logEvent("select_item", {
				item_list_name: listName,
				items: [
					{
						item_list_name: listName, // odd, but GA wants this also on an item level, not sure if it makes any difference though
						item_id: entry.id,
						item_name: entry.name,
					},
				],
			});
		},
		[]
	);

	useEffect(() => {
		const trackedIds = trackedEntries.current.map((entry) => entry.id);
		const newEntries = visibleEntries.filter((entry) => !trackedIds.includes(entry.id));

		if (newEntries.length > 0) {
			logEvent("view_item", {
				item_list_name: listName,
				items: newEntries.map((entry) => ({
					item_list_name: listName, // odd, but GA wants this also on an item level, not sure if it makes any difference though
					item_id: entry.id,
					item_name: entry.name,
				})),
			});
		}

		trackedEntries.current = visibleEntries;
	}, [throttledEntries]);

	return {
		onViewableItemsChanged,
		onItemPress,
	};
};
