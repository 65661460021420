import { SafeAreaView } from "@pivus/ui/style/primitives/dripsy";

import { ManagedTrackList } from "../../components/TrackList/ManagedTrackList/ManagedTrackList";

export const ExploreScreen = () => {
	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<ManagedTrackList />
		</SafeAreaView>
	);
};
