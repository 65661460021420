import { SafeAreaView, ScrollView } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { FunctionComponent } from "react";

import { SimpleNavigation } from "../../../components/example/SimpleNavigation/SimpleNavigation";

export const SimpleProfileDetailsScreen: FunctionComponent<{ slug: string }> = ({ slug }) => {
	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<ScrollView sx={{ padding: "$4" }}>
				<Text>Simple profile details</Text>
				<Heading variant="h1">{slug}</Heading>
				<SimpleNavigation />
			</ScrollView>
		</SafeAreaView>
	);
};
