import type { EventParams, EventNameString } from "@firebase/analytics";
import { initializeAnalytics, logEvent as logEventFn, setUserId as setUserIdFn } from "firebase/analytics";

import { firebaseApp } from "./firebase";

// Keep these utils in sync with apps/storybook/mocks/analytics.ts

const firebaseAnalytics = initializeAnalytics(firebaseApp, { config: { send_page_view: false } });
export const logEvent = (event: EventNameString, params: EventParams) => {
	// console.log("analytics > logEvent", { event, params });
	return logEventFn(firebaseAnalytics, event as string, params);
};

export const setUserId = (userId: string) => {
	// console.log("analytics > setUserId", { userId });
	return setUserIdFn(firebaseAnalytics, userId);
};
