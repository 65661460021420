import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import { NotFoundScreen } from "../screens/NotFoundScreen/NotFoundScreen";
import { useStores } from "../stores/StoresProvider";

import { DrawerStackNavigator } from "./DrawerStackNavigator";
import { SetupStackNavigator } from "./SetupStackNavigator";
import { SimpleStackNavigator } from "./SimpleStackNavigator";
import type { RootStackParams } from "./stacks";

const RootStack = createNativeStackNavigator<RootStackParams>();

export const RootStackNavigator = observer(() => {
	const { userStore } = useStores();

	const [hadUser, setHadUser] = useState(false);
	useEffect(() => {
		if (userStore.user) {
			setHadUser(true);
		}
	}, [userStore.user]);

	return (
		<RootStack.Navigator screenOptions={{ headerShown: false }}>
			{userStore.user ? (
				<>
					<RootStack.Screen name="drawerStack" component={DrawerStackNavigator} />
					<RootStack.Screen name="simpleStack" component={SimpleStackNavigator} />
				</>
			) : (
				<RootStack.Screen
					name="setupStack"
					component={SetupStackNavigator}
					initialParams={{ screen: hadUser ? "login" : "onboarding" }}
				/>
			)}

			<RootStack.Screen name="notFound" component={NotFoundScreen} options={{ title: "404 not found" }} />
		</RootStack.Navigator>
	);
});
