import { FlatList, SafeAreaView, View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useLinkTo } from "@react-navigation/native";
import { Button } from "react-native";
import type { ListRenderItemInfo } from "react-native";

import { SimpleNavigation } from "../../../components/example/SimpleNavigation/SimpleNavigation";

type Profile = { slug: string };
const profiles: Profile[] = [
	{ slug: "Paul" },
	{ slug: "Shane" },
	{ slug: "Linda" },
	{ slug: "Mia" },
	{ slug: "Liam" },
	{ slug: "Emma" },
	{ slug: "Noah" },
	{ slug: "Olivia" },
	{ slug: "William" },
	{ slug: "Ava" },
	{ slug: "James" },
	{ slug: "Isabella" },
	{ slug: "Logan" },
	{ slug: "Sophia" },
	{ slug: "Benjamin" },
	{ slug: "Charlotte" },
	{ slug: "Mason" },
	{ slug: "Mia" },
];

const ListHeader = () => (
	<>
		<Text>Simple profile overview</Text>
		<View sx={{ marginVertical: "$6" }}>
			<SimpleNavigation />
		</View>
	</>
);

const ListFooter = () => <Text sx={{ marginVertical: "$6" }}>Some notes after the FlatList</Text>;

export const SimpleProfileOverviewScreen = () => {
	const linkTo = useLinkTo();
	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<FlatList
				sx={{ padding: "$4" }}
				data={profiles}
				ListHeaderComponent={ListHeader}
				ListFooterComponent={ListFooter}
				// @ts-ignore -- because of https://github.com/nandorojo/dripsy/issues/58
				renderItem={({ item }: ListRenderItemInfo<Profile>) => {
					return (
						<View sx={{ width: "50%", paddingHorizontal: "$6", paddingVertical: "$3", backgroundColor: "$awe11" }}>
							<Button
								title={item.slug}
								onPress={() => {
									linkTo(`/simple/profile/${item.slug}`);
								}}
							/>
						</View>
					);
				}}
			/>
		</SafeAreaView>
	);
};
