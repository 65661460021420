import type { SVGReactComponent } from "@pivus/svg-loader";
import type { ComponentProps, FunctionComponent } from "react";

import { View, useSx } from "../style/primitives/dripsy";
import { MotiPressable } from "../style/primitives/moti";
import { Text } from "../style/primitives/Text/Text";
import type { Theme } from "../style/theme.dripsy";
import { useThemeColor, useThemeFontSize } from "../style/useThemeToken";
import { usePressableAnimation, usePressableState, usePressableTransition } from "../utils/transitionUtils";

type ButtonColors = "awe" | "joy" | "calm" | "pride" | "relief" | "trust" | "primary" | "secondary";
type ButtonColorsConfig = {
	[color in ButtonColors]: {
		color: keyof Theme["colors"];
		normal: { backgroundColor: keyof Theme["colors"] };
		hover: { backgroundColor: keyof Theme["colors"]; color?: keyof Theme["colors"] };
	};
};
const colors: ButtonColorsConfig = {
	awe: {
		color: "$gray12",
		normal: { backgroundColor: "$awe6" },
		hover: { backgroundColor: "$awe8" },
	},
	joy: {
		color: "$gray12",
		normal: { backgroundColor: "$joy5" },
		hover: { backgroundColor: "$joy3" },
	},
	calm: {
		color: "$black",
		normal: { backgroundColor: "$calm6" },
		hover: { backgroundColor: "$calm7" },
	},
	pride: {
		color: "$black",
		normal: { backgroundColor: "$pride6" },
		hover: { backgroundColor: "$pride7" },
	},
	relief: {
		color: "$gray12",
		normal: { backgroundColor: "$relief5" },
		hover: { backgroundColor: "$relief3" },
	},
	trust: {
		color: "$gray12",
		normal: { backgroundColor: "$trust6" },
		hover: { backgroundColor: "$trust4" },
	},
	primary: {
		color: "$white",
		normal: { backgroundColor: "$black" },
		hover: { backgroundColor: "$background", color: "$black" },
	},
	secondary: {
		color: "$black",
		normal: { backgroundColor: "$background" },
		hover: { backgroundColor: "$black", color: "$white" },
	},
};

export const Button: FunctionComponent<
	{
		label: string;
		Icon?: SVGReactComponent;
		iconSx?: ComponentProps<typeof View>["sx"];
		labelSx?: ComponentProps<typeof Text>["sx"];
		color?: ButtonColors;
	} & Omit<ComponentProps<typeof MotiPressable>, "children">
> = ({ label, Icon, iconSx, labelSx, color = "awe", containerStyle, sx, disabled, ...props }) => {
	const animateSx = useSx();

	const colorVariants = colors[color];
	const disabledBackgroundColor = "$gray10";

	const hasBorder = color === "secondary" || color === "primary";
	const border = hasBorder ? { borderWidth: 1, borderColor: "$black" } : {};

	return (
		<MotiPressable
			containerStyle={{ flexGrow: 1, ...containerStyle }}
			sx={{
				flexGrow: 1,
				flexDirection: "row",
				borderRadius: 100,
				justifyContent: "center",
				alignItems: "center",
				paddingX: "$4",
				paddingY: "$2",
				...border,
				...sx,
			}}
			transition={usePressableTransition(
				{
					normal: { type: "timing", duration: 400 },
					hovered: { type: "timing", duration: 200 },
					pressed: { type: "timing", duration: 0 },
				},
				[]
			)}
			animate={usePressableAnimation(
				{
					normal: animateSx({
						backgroundColor: disabled ? disabledBackgroundColor : colorVariants.normal.backgroundColor,
					}),
					hovered: animateSx({
						backgroundColor: disabled ? disabledBackgroundColor : colorVariants.hover.backgroundColor,
					}),
					pressed: animateSx({
						backgroundColor: disabled ? disabledBackgroundColor : colorVariants.hover.backgroundColor,
					}),
				},
				[animateSx, colorVariants, disabled]
			)}
			disabled={disabled}
			{...props}
		>
			<Child
				color={colorVariants.color}
				hoverColor={colorVariants.hover.color}
				label={label}
				Icon={Icon}
				iconSx={iconSx}
				labelSx={labelSx}
				disabled={disabled}
			/>
		</MotiPressable>
	);
};

const Child: FunctionComponent<{
	color: keyof Theme["colors"];
	hoverColor?: keyof Theme["colors"];
	label: string;
	Icon?: SVGReactComponent;
	iconSx?: ComponentProps<typeof View>["sx"];
	labelSx?: ComponentProps<typeof Text>["sx"];
	disabled?: boolean;
}> = ({ color, hoverColor, label, Icon, iconSx, labelSx, disabled }) => {
	const pressableState = usePressableState();
	const hoverdOrPressed = pressableState === "hovered" || pressableState === "pressed";
	const textColor = disabled ? "$gray7" : hoverdOrPressed && hoverColor ? hoverColor : color;

	return (
		<>
			{Icon && (
				<View sx={{ marginX: "$2", size: "$4", ...iconSx }}>
					<Icon color={useThemeColor(textColor)} />
				</View>
			)}
			<Text
				sx={{
					color: textColor,
					textAlign: "center",
					fontSize: "$16",
					lineHeight: useThemeFontSize("$16") * 1.5,
					marginRight: "$3",
					...labelSx,
				}}
			>
				{label}
			</Text>
		</>
	);
};
