import { View } from "@pivus/ui/style/primitives/dripsy";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import type { FunctionComponent } from "react";
import { useWindowDimensions } from "react-native";

import YellowBL from "./assets/yellowBottomLeft.svg";
import YellowTR from "./assets/yellowTopRight.svg";

export const ShapedBackground: FunctionComponent<{}> = ({}) => {
	const { width, height } = useWindowDimensions();
	const bp1 = useThemeSize("$bp1");
	const offset = {
		x: width < bp1 ? Math.max(-100, (width - bp1) * 0.5) : 0,
		y: width < bp1 ? Math.max(-75, (width - bp1) * 0.25) : 0,
	};
	return (
		<View sx={{ position: "absolute", width, height, overflow: "hidden" }}>
			<View sx={{ position: "absolute", top: offset.y, right: offset.x, width: 206, height: 376 }}>
				<YellowTR />
			</View>
			<View sx={{ position: "absolute", bottom: offset.y, left: offset.x, width: 184, height: 341 }}>
				<YellowBL />
			</View>
		</View>
	);
};
