import {
	useCrossPlayerControls,
	useCrossPlayerDuration,
	useCrossPlayerInitialized,
	useCrossPlayerPlaybackStatus,
	useCrossPlayerPosition,
	useCrossPlayerTrack,
} from "@pivus/cross-player/hooks";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { FunctionComponent } from "react";
import { Button } from "react-native";

const Prop: FunctionComponent<{ label: string; value: boolean | number | string | object | undefined }> = ({
	label,
	value,
}) => {
	return (
		<View sx={{ width: "50%", flexDirection: "row", flexWrap: "nowrap", paddingRight: 10 }}>
			<Text variant="note">{label}:</Text>
			{/* @ts-ignore */}
			{typeof value === "boolean" ? <Text>{value ? "true" : "false"}</Text> : null}
			{/* @ts-ignore */}
			{typeof value === "number" ? <Text>{value.toFixed(2)}</Text> : null}
			{/* @ts-ignore */}
			{typeof value === "string" ? <Text>{value}</Text> : null}
			{/* @ts-ignore */}
			{typeof value === "object" ? <Text>{JSON.stringify(value)}</Text> : null}
		</View>
	);
};

/**
 * This component implements the `cross-player`, which only works within an `expo` context, **not** within on web directly from `storybook`, `webpack`, `nextjs`, etc.
 */
export const CrossPlayerStateController: FunctionComponent = () => {
	const playerControls = useCrossPlayerControls();
	const duration = useCrossPlayerDuration();
	const position = useCrossPlayerPosition();
	const playbackStatus = useCrossPlayerPlaybackStatus();
	const { crossPlayerTrack, setCrossPlayerTrack } = useCrossPlayerTrack();
	const crossPlayerInitialized = useCrossPlayerInitialized();
	return (
		<View sx={{ padding: "$4" }}>
			{crossPlayerInitialized ? (
				<>
					<View sx={{ flexDirection: "row", flexWrap: "wrap" }}>
						<Button
							title="Play"
							onPress={() => {
								playerControls.play();
							}}
						/>
						<Button
							title="Pause"
							onPress={() => {
								playerControls.pause();
							}}
						/>
						<Button
							title="Stop"
							onPress={() => {
								playerControls.stop();
							}}
						/>
						<Button
							title="Unset"
							onPress={() => {
								setCrossPlayerTrack(undefined);
							}}
						/>
					</View>
					<View sx={{ flexDirection: "row", flexWrap: "wrap" }}>
						<Button
							title="Seek till start"
							onPress={() => {
								playerControls.seekTo(5);
							}}
						/>
						<Button
							title="Restart"
							onPress={() => {
								playerControls.seekTo(0).then(playerControls.play);
							}}
						/>
						<Button
							title="Seek till end"
							onPress={() => {
								playerControls.seekTo((duration || 5) - 5);
							}}
						/>
					</View>
					<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
						<Button
							title="Track 1"
							onPress={() => {
								setCrossPlayerTrack({
									id: "testId1",
									title: "Sorry",
									artwork: "https://picsum.photos/id/1/200/300",
									url: "https://s3.amazonaws.com/exp-us-standard/audio/playlist-example/Comfort_Fit_-_03_-_Sorry.mp3",
								});
							}}
						/>
						<Button
							title="Track 2"
							onPress={() => {
								setCrossPlayerTrack({
									id: "testId2",
									title: "Rubber robot",
									artwork: "https://picsum.photos/id/2/200/300",
									url: "https://s3.amazonaws.com/exp-us-standard/audio/playlist-example/Podington_Bear_-_Rubber_Robot.mp3",
								});
							}}
						/>
						<Button
							title="Track 3"
							onPress={() => {
								setCrossPlayerTrack(
									{
										id: "testId3",
										title: "SoundHelix Song 3",
										artwork: "https://picsum.photos/id/3/200/300",
										url: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3",
									},
									{ autoPlay: true }
								);
							}}
						/>
					</View>
				</>
			) : (
				/* Possibly running in storybook for web */
				<Text>Player is initializing</Text>
			)}

			<View sx={{ flexDirection: "row", flexWrap: "wrap" }}>
				{[
					{ name: "initialized", value: crossPlayerInitialized },
					{ name: "position", value: position },
					{ name: "duration", value: duration },
					{ name: "playbackStatus", value: playbackStatus },
					{ name: "crossPlayerTrack", value: crossPlayerTrack },
				].map((prop) => (
					<Prop label={prop.name} value={prop.value} key={prop.name} />
				))}
			</View>
		</View>
	);
};
