import type { DependencyList } from "react";
import { useMemo, useState } from "react";
import type { PressableStateCallbackType, ViewStyle } from "react-native";
import { runOnJS } from "react-native-reanimated";

import type { MotiAnimationProp, MotiTransition } from "../style/primitives/moti";
import { useMotiPressable } from "../style/primitives/moti";

/**
 * A hook to create a new transition for a pressable component without creating a callback with the specific "worklet" annotation.
 * and simply defining the transition for each state.
 */
export const usePressableTransition = (
	transition: { normal: MotiTransition; focused?: MotiTransition; hovered?: MotiTransition; pressed?: MotiTransition },
	deps: DependencyList
) => {
	return useMemo<(state: PressableStateCallbackType) => MotiTransition>(
		() => (state) => {
			"worklet";

			if (state.hovered && transition.hovered) return transition.hovered;
			if (state.pressed && transition.pressed) return transition.pressed;
			if (state.focused && transition.focused) return transition.focused;

			return transition.normal;
		},
		deps
	);
};

/**
 * A hook to create a new animation for a pressable component without creating a callback with the specific "worklet" annotation.
 * and simply defining the animation(style) props for each state.
 */
type AnimationProps = MotiAnimationProp<ViewStyle>;
export const usePressableAnimation = (
	css: {
		normal: AnimationProps;
		focused?: AnimationProps;
		hovered?: AnimationProps;
		pressed?: AnimationProps;
	},
	deps: DependencyList
) => {
	return useMemo<(state: PressableStateCallbackType) => AnimationProps>(
		() => (state) => {
			"worklet";

			if (state.hovered && css.hovered) return css.hovered;
			if (state.pressed && css.pressed) return css.pressed;
			if (state.focused && css.focused) return css.focused;

			return css.normal;
		},
		deps
	) as AnimationProps; // Needing to cast this somehow to keep TS happy.
};

/**
 * This can be used to animate a child component of a Pressable. It will return the correct animation props based on the pressable state.
 * @see usePressableState
 */
export const usePressableChildAnimation = (css: {
	normal: AnimationProps;
	hovered?: AnimationProps;
	pressed?: AnimationProps;
}) => {
	const pressableState = usePressableState();

	if (pressableState === "hovered" && css.hovered) return css.hovered;
	if (pressableState === "pressed" && css.pressed) return css.pressed;
	return css.normal;
};

/**
 * Returns the pressable state in a nested component. (note: this can't be used directly inline with a Pressable, it needs a separate component that's nested)
 */
export const usePressableState = () => {
	const [state, setState] = useState("normal");
	const updateStateOnJs = (newState: string) => {
		if (newState !== state) {
			setState(newState);
		}
	};

	useMotiPressable(
		({ pressed, hovered }) => {
			"worklet";

			const newState = pressed ? "pressed" : hovered ? "hovered" : "normal";
			// We need to break out of the UI thread to update the state on devices...
			runOnJS(updateStateOnJs)(newState);

			// Workaround to satisfy useMotiPressable
			return {};
		},
		[updateStateOnJs]
	);

	return state;
};
