import { SafeAreaView, ScrollView } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";

import { CrossPlayerStateController } from "../../components/example/CrossPlayerStateController/CrossPlayerStateController";
import { SimpleNavigation } from "../../components/example/SimpleNavigation/SimpleNavigation";

export const SimpleAudioScreen = () => (
	<SafeAreaView sx={{ flex: 1 }}>
		<ScrollView sx={{ padding: "$4" }}>
			<Text>Simple audio!</Text>
			<SimpleNavigation />
			<CrossPlayerStateController />
		</ScrollView>
	</SafeAreaView>
);
