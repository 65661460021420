import { Play, Tear, Thunder } from "@pivus/ui/Icons";
import { FlatList, useSx, View } from "@pivus/ui/style/primitives/dripsy";
import { MotiPressable } from "@pivus/ui/style/primitives/moti";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { Theme } from "@pivus/ui/style/theme.dripsy";
import { useThemeSize } from "@pivus/ui/style/useThemeToken";
import { formatDuration } from "@pivus/ui/utils/formatDuration";
import { usePressableAnimation, usePressableTransition } from "@pivus/ui/utils/transitionUtils";
import type { ComponentProps, FunctionComponent } from "react";
import type { ListRenderItemInfo } from "react-native";

import { HistoryTag } from "./HistoryTag";

export const HistoryList: FunctionComponent<
	{
		data: HistoryListItemItem[];
		onItemPress: (item: HistoryListItemItem) => void;
		paddingX?: keyof Theme["space"];
		paddingY?: keyof Theme["space"];
	} & Omit<ComponentProps<typeof FlatList>, "data" | "renderItem">
> = ({ data, onItemPress, paddingX = "$4", paddingY = "$4", ...props }) => {
	return (
		<FlatList
			data={data}
			{...props}
			ItemSeparatorComponent={ItemSeparator}
			// @ts-ignore -- because of https://github.com/nandorojo/dripsy/issues/58
			renderItem={({ item }: ListRenderItemInfo<HistoryListItemItem>) => (
				<HistoryListItem sx={{ paddingX, paddingY }} item={item} onPress={() => onItemPress(item)} />
			)}
			contentContainerStyle={{ paddingTop: useThemeSize("$headerHeight") }}
		/>
	);
};

export const ItemSeparator: FunctionComponent = () => {
	return <View sx={{ borderBottomWidth: 1, borderBottomColor: "$gray10" }} />;
};

export type HistoryListItemItem = {
	id: string;
	title: string;
	duration: number;
	position?: number;
	plays: number;
	tags: { cry: number; anger: number };
	isPlaying: boolean;
};
export const HistoryListItem: FunctionComponent<
	{ item: HistoryListItemItem } & Omit<ComponentProps<typeof MotiPressable>, "children">
> = ({ item, ...props }) => {
	const sx = useSx();

	const hasHistoryTags = item.plays || item.tags.cry || item.tags.anger;

	return (
		<MotiPressable
			{...props}
			transition={usePressableTransition(
				{
					normal: { type: "timing", duration: 400 },
					hovered: { type: "timing", duration: 200 },
					pressed: { type: "timing", duration: 0 },
				},
				[]
			)}
			animate={usePressableAnimation(
				{
					normal: sx({ backgroundColor: item.isPlaying ? "$gray10" : "$background" }),
					hovered: sx({ backgroundColor: "$gray10" }),
					pressed: sx({ backgroundColor: item.isPlaying ? "$gray10" : "$background" }),
				},
				[item.isPlaying, sx]
			)}
		>
			<Text variant="bodyLarge" sx={{ marginBottom: hasHistoryTags ? "$6" : "$1", color: "$text" }}>
				{item.title}
			</Text>
			<View
				sx={{
					flexDirection: "row",
					flexWrap: "wrap",
					marginX: "-$1",
					marginY: "-$2",
					paddingBottom: hasHistoryTags ? "$1" : undefined,
					alignItems: "center",
				}}
			>
				{item.plays ? <HistoryTag label="Played" count={item.plays} Icon={Play} /> : null}
				{item.tags.cry ? <HistoryTag label="Cried" count={item.tags.cry} Icon={Tear} iconColor="$relief3" /> : null}
				{item.tags.anger ? (
					<HistoryTag label="Anger" count={item.tags.anger} Icon={Thunder} iconColor="$pride5" />
				) : null}

				<Text variant="bodySmall" sx={{ textAlign: "right", flexGrow: 1, padding: "$1" }}>
					{item.isPlaying || item.position ? `${formatDuration(item.position || 0)}/` : ""}
					{formatDuration(item.duration)}
				</Text>
			</View>
		</MotiPressable>
	);
};
