import { docSchema } from "@pivus/firebase-entities/Document";
import type { CollectionReference } from "firebase/firestore";
import { orderBy, query } from "firebase/firestore";
import type { DocumentSource, ICollectionOptions } from "firestorter";
import { makeObservable, observable, reaction } from "mobx";
import type { Infer } from "superstruct";
import { boolean, string } from "superstruct";

import { TypedCollection } from "../core/TypedCollection";
import type { TypedDocumentOptions } from "../core/TypedDocument";
import { createSchemaValidator, TypedDocument } from "../core/TypedDocument";

const TodoSchema = docSchema({
	title: string(),
	completed: boolean(),
});
type TodoFields = Infer<typeof TodoSchema>;

export class TodoDocument extends TypedDocument<TodoFields> {
	constructor(source: DocumentSource, options: TypedDocumentOptions = {}) {
		super(source, {
			...options,
			schema: createSchemaValidator(TodoSchema),
		});
	}
}

export class TodoCollection extends TypedCollection<TodoFields, TodoDocument> {
	uid?: string;

	constructor(uid?: string, options: Omit<ICollectionOptions<TodoFields>, "createDocument"> = {}) {
		super(undefined, {
			...options,
			query: (ref: CollectionReference) => ref && query(ref, orderBy("updatedAt", "desc")),
			createDocument: (docSrc, docOptions) => new TodoDocument(docSrc, docOptions),
		});

		this.uid = uid;

		makeObservable(this, {
			uid: observable,
		});

		reaction(
			() => this.uid,
			(newUid) => {
				this.source = newUid ? `user_data/${newUid}/todos` : undefined;
			},
			{ fireImmediately: true }
		);
	}
}
