import { View } from "../../style/primitives/dripsy";
import { Heading } from "../../style/primitives/Heading/Heading";
import { Text } from "../../style/primitives/Text/Text";

export const DripsyPlay = () => {
	return (
		<View>
			<Heading variant="h1">Heading 1</Heading>
			<Heading variant="h2">Heading 2</Heading>
			<Heading variant="h3">Heading 3</Heading>
			<Heading variant="h4">Heading 4</Heading>
			<Heading variant="h5">Heading 5</Heading>
			<Heading variant="h6">Heading 6</Heading>
			<Text variant="body" as="p">
				Text
			</Text>
			<View
				sx={{
					padding: "$4",
					width: ["$32", "$64"],
					backgroundColor: "$calm10",
				}}
			>
				<Text variant="bodyLarge">Hello world</Text>
			</View>
		</View>
	);
};
