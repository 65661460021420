import type { SVGReactComponent } from "@pivus/svg-loader";
import type { ComponentProps, FunctionComponent } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { LayoutChangeEvent } from "react-native";
import { useWindowDimensions } from "react-native";
import { Easing } from "react-native-reanimated";

import { Close } from "../Icons";
import { Pressable, styled, View } from "../style/primitives/dripsy";
import type { MotiTransitionProp } from "../style/primitives/moti";
import { MotiView } from "../style/primitives/moti";
import type { ThemedSize } from "../style/theme.dripsy";
import { useThemeColor } from "../style/useThemeToken";

// eslint-disable-next-line global-require
export const Corner: SVGReactComponent = require("./corner.svg").default;

const baseTransition: MotiTransitionProp = { type: "timing", duration: 200 };
const defaultTransition: { [key: string]: MotiTransitionProp } = {
	default: { ...baseTransition },
	in: { ...baseTransition, easing: Easing.out(Easing.circle) },
	out: { ...baseTransition, easing: Easing.in(Easing.ease) },
};

export const sizeTokens: { contentPaddingXToken: ThemedSize; contentPaddingYToken: ThemedSize } = {
	contentPaddingXToken: "$4",
	contentPaddingYToken: "$8",
};

const Root = styled(View)(({ width, height }: { width: number; height: number }) => {
	return {
		zIndex: 1,
		position: "absolute",
		left: 0,
		bottom: 0,
		width,
		height,
		overflow: "hidden",
	};
});

const Panel = styled(MotiView)({
	position: "absolute",
	zIndex: 2,
	bottom: 0,
	width: "100%",
});

const Content: FunctionComponent<ComponentProps<typeof View>> = ({ sx, ...props }) => {
	return (
		<View
			sx={{
				boxShadow: "$navigation",
				backgroundColor: "$background",
				paddingX: sizeTokens.contentPaddingXToken,
				paddingY: sizeTokens.contentPaddingYToken,
				alignItems: "center",
			}}
		>
			<View sx={{ width: "100%", maxWidth: "$bp1" }} {...props} />
		</View>
	);
};

const Overlay: FunctionComponent<
	{ width: number; height: number } & ComponentProps<typeof MotiView> & ComponentProps<typeof Pressable>
> = ({ animate, transition, width, height, sx, ...props }) => {
	return (
		<MotiView animate={animate} transition={transition}>
			<Pressable
				sx={{
					position: "absolute",
					backgroundColor: "black",
					width,
					height,
					cursor: "auto",
					...sx,
				}}
				{...props}
			/>
		</MotiView>
	);
};

const CloseButton: FunctionComponent<ComponentProps<typeof Pressable>> = ({ sx, ...props }) => {
	return (
		<View
			sx={{
				// This view crops off the shadow at the bottom of the button and places it on top of the underlying Content
				size: "$20",
				zIndex: 3,
				marginBottom: -1, // improves transition, otherwise flickering gray line underneath
				justifyContent: "flex-end",
				overflow: "hidden",
				position: "relative",
			}}
		>
			<Pressable
				sx={{
					padding: "$3",
					size: "$10",
					backgroundColor: "$background",
					borderTopRightRadius: "$2",
					boxShadow: "$navigation",
					...sx,
				}}
				{...props}
			>
				<Close color={useThemeColor("$text")} />
			</Pressable>
			<View
				sx={{
					position: "absolute",
					zIndex: 1,
					left: "$10",
					bottom: 0,
					size: 6,
					// This border resolves the flickering gray line underneath the button
					transform: [{ translateX: -1 }], // we move it 1px to the left solve this gray line vertically on Android
					borderBottomWidth: 1,
					borderLeftWidth: 1,
					borderColor: "$background",
				}}
			>
				<Corner color={useThemeColor("$background")} />
			</View>
		</View>
	);
};

export const BottomOverlay: FunctionComponent<
	{
		open?: boolean;
		onOpenChange?: (open: boolean) => void;
		onOpenComplete?: (open: boolean) => void;
		onClosePress?: () => void;
	} & ComponentProps<typeof Content>
> = ({ open: openProp = false, onOpenChange, onOpenComplete, onClosePress, pointerEvents, ...props }) => {
	const windowDimensions = useWindowDimensions();
	const overlayDimensions = { width: windowDimensions.width, height: windowDimensions.height };
	const [isOpen, setIsOpen] = useState(openProp);
	const [isOpenTransitioning, setIsOpenTransitioning] = useState(false);
	const [panelHeight, setPanelHeight] = useState<number>(windowDimensions.height); // Very high number to make sure the panel is not visible at mount, until we get the height
	const shouldAnimate = useMemo(() => isOpen || isOpenTransitioning, [isOpen, isOpenTransitioning]);
	const animatedPanelHeight = useMemo(
		() => (shouldAnimate ? panelHeight : windowDimensions.height),
		[panelHeight, shouldAnimate]
	);

	useEffect(() => {
		onOpenChange?.(isOpen);
		setIsOpenTransitioning(true);
	}, [isOpen, onOpenChange]);

	useEffect(() => {
		setIsOpen(openProp);
	}, [openProp]);

	const close = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
			setIsOpenTransitioning(true);
			onClosePress?.();
		}
	}, [isOpen, setIsOpen, setIsOpenTransitioning]);

	return (
		<Root pointerEvents={isOpen ? pointerEvents : "none"} {...overlayDimensions}>
			{/* TODO: yuk, remove these hacky updates when the height is changing while the panel is closed, this is not even truly stable on all platforms */}
			<Panel
				transition={useMemo(() => {
					if (!shouldAnimate) {
						return { ...defaultTransition.default, duration: 0 };
					}

					return isOpen ? defaultTransition.in : defaultTransition.out;
				}, [isOpen, isOpenTransitioning])}
				animate={useMemo(() => ({ translateY: isOpen ? 0 : animatedPanelHeight }), [isOpen, animatedPanelHeight])}
				onLayout={useCallback(
					(event: LayoutChangeEvent) => {
						setPanelHeight(event.nativeEvent.layout.height);
					},
					[setPanelHeight]
				)}
				onDidAnimate={useCallback(() => {
					setIsOpenTransitioning(false);
					onOpenComplete?.(isOpen);
				}, [isOpen, onOpenComplete])}
			>
				<CloseButton onPress={close} />
				<Content {...props} />
			</Panel>
			<Overlay
				transition={defaultTransition.default}
				animate={{ opacity: isOpen ? 0.65 : 0 }}
				onPress={close}
				{...overlayDimensions}
			/>
		</Root>
	);
};
