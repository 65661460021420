import type { TrackFields } from "@pivus/firebase-entities/Track";
import { TrackSchema } from "@pivus/firebase-entities/Track";
import type { CollectionReference } from "firebase/firestore";
import { orderBy, query } from "firebase/firestore";
import type { DocumentSource, ICollectionOptions, CollectionSource, IDocumentOptions } from "firestorter";

import { TypedCollection } from "./core/TypedCollection";
import { createSchemaValidator, TypedDocument } from "./core/TypedDocument";

export class TrackDocument extends TypedDocument<TrackFields> {
	constructor(source: DocumentSource, options: IDocumentOptions = {}) {
		super(source, {
			...options,
			schema: createSchemaValidator(TrackSchema),
		});
	}
}

export class TrackCollection extends TypedCollection<TrackFields, TrackDocument> {
	constructor(
		source: CollectionSource = "tracks",
		options: Omit<ICollectionOptions<TrackFields>, "createDocument"> = {}
	) {
		super(source, {
			...options,
			query: (ref: CollectionReference) => ref && query(ref, trackQuery.orderByUpdatedAt()),
			createDocument: (docSrc, docOptions) => new TrackDocument(docSrc, docOptions),
		});
	}
}

export const trackQuery = {
	orderByUpdatedAt: (direction: "asc" | "desc" = "asc") => orderBy("updatedAt", direction),
};

export const trackDocAsAudioTrack = (trackDoc: TrackDocument) => ({ id: trackDoc.id as string, ...trackDoc.data });
