import type { SVGReactComponent } from "@pivus/svg-loader";
import { Button } from "@pivus/ui/Button/Button";
import { Care, Explore, History, Menu } from "@pivus/ui/Icons";
import { Pressable, View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import { useThemeColor, useThemeSize, useThemeSpace } from "@pivus/ui/style/useThemeToken";
import type { DrawerContentComponentProps } from "@react-navigation/drawer";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { DrawerActions, useLinkTo } from "@react-navigation/native";
import type { ComponentProps, FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { logUiEvent } from "../../../firebase/analytics-utils";
import { firebaseAuth } from "../../../firebase/firebase";
import { useStores } from "../../../stores/StoresProvider";
import { heightToken } from "../BottomTabBar/BottomTabBar";

/**
 * TODO: Make this component also work in a storybook context
 */
export const DrawerContent: FunctionComponent<DrawerContentComponentProps> = ({ navigation }) => {
	const logDrawerPressEvent = useCallback((value: string) => {
		logUiEvent({
			ui_component: "drawer",
			ui_trigger: "button",
			ui_action: "press",
			ui_value: value,
		});
	}, []);

	const linkTo = useLinkTo();
	const linkToOnPress = useCallback(
		(name: string, path: Parameters<typeof linkTo>[0]) => {
			return () => {
				logDrawerPressEvent(name);
				linkTo(path);

				// Yuk, this is needed for the browser in order to update the url properly as it seems to be behind the actual state.
				// TODO: This is a hack, I am not sure how to resolve this properly.
				if (Platform.OS === "web") {
					setTimeout(() => {
						navigation.dispatch(DrawerActions.openDrawer());
						navigation.dispatch(DrawerActions.closeDrawer());
					}, 100);
				}
			};
		},
		[linkTo]
	);

	const { userStore } = useStores();

	const insets = useSafeAreaInsets();
	// TODO: Remove this debug menu someday
	const [debugMenuVisible, setDebugMenuVisible] = useState(false);

	return (
		<>
			<DrawerContentScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "flex-end" }}>
				<View sx={{ flexGrow: 1, marginX: "$6", marginY: "$2" }}>
					<Button
						label={`Logout ${userStore.user?.displayName || userStore.user?.email}`}
						onPress={() => {
							logDrawerPressEvent("logout");
							firebaseAuth.signOut();
						}}
						containerStyle={{ flexGrow: 0 }}
					/>
				</View>

				{/* DEBUG */}
				<Pressable
					sx={{
						position: "absolute",
						top: insets.top,
						right: 0,
						size: "$20",
						zIndex: 1,
						cursor: "default",
						opacity: 0,
					}}
					onLongPress={() => {
						logDrawerPressEvent(debugMenuVisible ? "hide debug menu" : "show debug menu");
						setDebugMenuVisible(!debugMenuVisible);
					}}
				>
					<Text>Long press me to {debugMenuVisible ? "hide" : "show"} debug menu items</Text>
				</Pressable>
				{debugMenuVisible && (
					<>
						<Heading
							variant="h6"
							sx={{
								paddingX: "$6",
								paddingBottom: "$2",
							}}
						>
							Debug menu:
						</Heading>
						<DrawerItem label="Login" onPress={linkToOnPress("Login", "/login")} />
						<DrawerItem label="Simple home" onPress={linkToOnPress("Simple home", "/simple")} />
						<DrawerItem label="Simple audio" onPress={linkToOnPress("Simple audio", "/simple/audio")} />
						<DrawerItem
							label="FirebaseTesterScreen"
							onPress={linkToOnPress("FirebaseTesterScreen", "/simple/firebaseTester")}
						/>
						<DrawerItem label="Simple profile" onPress={linkToOnPress("Simple profile", "/simple/profile")} />
						<DrawerItem label="Paul's profile" onPress={linkToOnPress("Paul's profile", "/simple/profile/Paul")} />
						<DrawerItem label="Shane's profile" onPress={linkToOnPress("Shane's profile", "/simple/profile/Shane")} />
						<DrawerItem
							label="404"
							onPress={linkToOnPress("404", "/page/that/does/not/exist")}
							sx={{ paddingBottom: "$6", borderBottomWidth: 1, borderBottomColor: "$gray10", marginBottom: "$8" }}
						/>
					</>
				)}
				{/* END DEBUG */}

				<DrawerItem label="Explore" Icon={Explore} onPress={linkToOnPress("Explore", "/")} />
				<DrawerItem label="Care" Icon={Care} onPress={linkToOnPress("Care", "/care")} />
				<DrawerItem label="History" Icon={History} onPress={linkToOnPress("History", "/history")} />
			</DrawerContentScrollView>

			<View
				sx={{
					// TODO: move dropshadow to an absolute before positioned element as now it crops of the items at the top instead of overlaying them
					paddingTop: "$10",
					height: useThemeSize(heightToken) + useThemeSpace("$10"),
					overflow: "hidden",
				}}
			>
				<View
					sx={{
						flex: 1,
						paddingBottom: insets.bottom,
						borderTopWidth: 1,
						borderTopColor: "$gray10",
						alignItems: "flex-end",
						justifyContent: "center",
						boxShadow: "$navigation",
						backgroundColor: "$relief10",
					}}
				>
					<Pressable
						onPress={() => {
							logDrawerPressEvent("drawer");
							navigation.dispatch(DrawerActions.toggleDrawer());
						}}
						sx={{ padding: "$6" }}
					>
						<View sx={{ size: "$6" }}>
							<Menu color={useThemeColor("$text")} />
						</View>
					</Pressable>
				</View>
			</View>
		</>
	);
};

const DrawerItem: FunctionComponent<{ Icon?: SVGReactComponent; label: string } & ComponentProps<typeof Pressable>> = ({
	Icon,
	label,
	sx,
	...props
}) => {
	return (
		<Pressable
			sx={{
				flexDirection: "row",
				alignItems: "center",
				paddingX: "$6",
				paddingY: "$3",
				...sx,
			}}
			{...props}
		>
			{Icon && (
				<View sx={{ size: "$5", marginRight: "$2" }}>
					<Icon color={useThemeColor("$text")} />
				</View>
			)}
			<Text variant="bodyLarge">{label}</Text>
		</Pressable>
	);
};
