import { Button } from "@pivus/ui/Button/Button";
import { Explore } from "@pivus/ui/Icons";
import { Image, SafeAreaView, View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { useLinkTo } from "@react-navigation/native";

import { useStores } from "../../stores/StoresProvider";
import { routes } from "../../utils/routes";

import NotFoundImage from "./assets/notFound.png";

export const NotFoundScreen = () => {
	const { userStore } = useStores();
	const linkTo = useLinkTo();
	return (
		<SafeAreaView sx={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "$fixed.$awe1" }}>
			<Image source={NotFoundImage} sx={{ width: "$64", height: "$64" }} />
			<Heading variant="h6" as="h1" sx={{ color: "$fixed.$calm2", textTransform: "uppercase" }}>
				404: Not found
			</Heading>
			<View sx={{ marginTop: "$8" }}>
				<Button
					Icon={Explore}
					label="Home"
					color="relief"
					onPress={() => linkTo(userStore.user !== undefined ? routes.explore : routes.onboarding)}
				/>
			</View>
		</SafeAreaView>
	);
};
