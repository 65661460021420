import type { SVGReactComponent } from "@pivus/svg-loader";
import { styled, View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { Text as PrimitiveText } from "@pivus/ui/style/primitives/Text/Text";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import type { ComponentProps, FunctionComponent } from "react";

export const Root = styled(View)({});

// TODO: First and last variants instead of overriding the margin in the implementation
export const Section = styled(View)({
	marginY: "$4",
});

export const Title: FunctionComponent<
	{
		Icon: SVGReactComponent;
		iconSx?: ComponentProps<typeof View>["sx"];
		label: string;
		labelSx?: ComponentProps<typeof Heading>["sx"];
	} & Omit<ComponentProps<typeof View>, "children">
> = ({ Icon, iconSx, label, labelSx, sx, ...props }) => {
	return (
		<View
			sx={{
				flexDirection: "row",
				alignItems: "center",
				...sx,
			}}
			{...props}
		>
			<View sx={{ size: "$6", marginY: "$1", marginRight: "$2", ...iconSx }}>
				<Icon color={useThemeColor("$text")} />
			</View>
			<Heading variant="h6" sx={labelSx}>
				{label}
			</Heading>
		</View>
	);
};

export const Text: FunctionComponent<
	{
		children: string;
	} & Omit<ComponentProps<typeof PrimitiveText>, "children">
> = ({ children, sx, variant = "bodyLarge", ...props }) => {
	return (
		<PrimitiveText
			sx={{
				color: "$gray4",
				...sx,
			}}
			variant={variant}
			{...props}
		>
			{children}
		</PrimitiveText>
	);
};
