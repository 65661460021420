import { reaction } from "mobx";
import type { DependencyList } from "react";
import { useEffect } from "react";

/**
 * Use a reaction as an effect and dispose it when deps change or at unmount
 */
export const useReactionEffect = <T, ReactionArgs extends Parameters<typeof reaction<T, true>>>(
	expression: ReactionArgs[0],
	effect: ReactionArgs[1],
	{ deps = [], ...options }: { deps?: DependencyList } & ReactionArgs[2] = {}
) => {
	useEffect(() => {
		return reaction(expression, effect, options);
	}, deps);
};
