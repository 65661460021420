import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { LoginScreen } from "../screens/LoginScreen/LoginScreen";
import { OnboardingScreen } from "../screens/OnboardingScreen/OnboardingScreen";
import { SignupScreen } from "../screens/SignupScreen/SignupScreen";

import type { SetupStackParams } from "./stacks";
import { useNavigationStyles } from "./styles/useNavigationStyles";

const SetupStack = createNativeStackNavigator<SetupStackParams>();

export const SetupStackNavigator = () => {
	const { headerStyle, headerTitleStyle } = useNavigationStyles();

	return (
		<SetupStack.Navigator screenOptions={{ headerStyle, headerTitleStyle }}>
			<SetupStack.Screen
				name="onboarding"
				component={OnboardingScreen}
				options={{ title: "Onboarding", headerShown: false }}
			/>
			<SetupStack.Screen name="login" component={LoginScreen} options={{ title: "Login", headerShown: false }} />
			<SetupStack.Screen
				name="signUp"
				component={SignupScreen}
				options={{ title: "Create account", headerShown: false }}
			/>
		</SetupStack.Navigator>
	);
};
