import type { PrivateDocumentFields } from "@pivus/firebase-entities/Document";
import type { PlaybackSessionFields } from "@pivus/firebase-entities/user_data/PlaybackSession";
import { PlaybackSessionSchema } from "@pivus/firebase-entities/user_data/PlaybackSession";
import type { CollectionSource, DocumentSource, ICollectionOptions } from "firestorter";
import { makeObservable, observable, reaction } from "mobx";

import { TypedCollection } from "../core/TypedCollection";
import type { TypedDocumentOptions } from "../core/TypedDocument";
import { createSchemaValidator, TypedDocument } from "../core/TypedDocument";

export class PlaybackSessionDocument extends TypedDocument<PlaybackSessionFields> {
	constructor(source: DocumentSource, options: TypedDocumentOptions = {}) {
		super(source, {
			...options,
			schema: createSchemaValidator(PlaybackSessionSchema),
		});
	}
}

// Keep this private and inherit instead
class AbstractPlaybackSessionCollection extends TypedCollection<PlaybackSessionFields, PlaybackSessionDocument> {
	public uid?: string;

	constructor(uid?: string, options: Omit<ICollectionOptions<PlaybackSessionFields>, "createDocument"> = {}) {
		super(undefined, {
			...options,
			createDocument: (docSrc, docOptions) => new PlaybackSessionDocument(docSrc, docOptions),
		});

		this.uid = uid;

		makeObservable(this, {
			uid: observable,
		});

		reaction(
			() => this.collectionSource,
			(src) => (this.source = src),
			{ fireImmediately: true }
		);
	}

	// Override this in the implementation
	protected get collectionSource(): CollectionSource | undefined {
		throw new Error("collectionSource must be implemented by the class extending this");
	}

	// Do we have a UID and is the collection not loading?, then we can be considered ready
	public get isReady() {
		return this.uid !== undefined && !this.isLoading;
	}
}

export class ActivePlaybackSessionCollection extends AbstractPlaybackSessionCollection {
	protected get collectionSource() {
		return this.uid ? `user_data/${this.uid}/active_playback_sessions` : undefined;
	}

	getSessionByTrackId(trackId?: string) {
		if (!trackId) {
			return undefined;
		}
		return this.docs.find((doc) => doc.data.trackId === trackId);
	}

	createNewSessionForTrack(
		trackId: string,
		fields: Omit<Omit<PlaybackSessionFields, PrivateDocumentFields>, "trackId"> = { state: "active", position: 0 }
	) {
		if (this.getSessionByTrackId(trackId)) {
			throw new Error(`Session for track ${trackId} already exists`);
		} else {
			return this.add({ trackId, ...fields });
		}
	}
}
