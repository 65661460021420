import { useDripsyTheme } from "./primitives/dripsy";
import type { Theme } from "./theme.dripsy";

export const useTextVariantStyle = <
	Type extends keyof Theme["text"],
	// @ts-ignore TODO: this type error shouldn't be here, for some reason its not sure if it has a variants key
	Space extends keyof Theme["text"][Type]["variants"],
	// @ts-ignore TODO: this type error shouldn't be here, for some reason its not sure if it has a variants key
	Value extends keyof Theme["text"][Type]["variants"][Space]
>(
	type: Type,
	variantSpace: Space,
	variantValue: Value
) => {
	const {
		theme: { text },
	} = useDripsyTheme();

	// @ts-ignore TODO: not so happy with this cast, should be possible to do differently. It does (partially) fix the typing though
	const { variants, ...baseStyle } = text[type] as any; // as Required<Theme["text"][Type] & { variants: Theme["text"][Type]["variants"] }>;
	const variantStyle = variants[variantSpace][variantValue];

	return {
		baseStyle,
		variantStyle,
		style: {
			...baseStyle,
			...variantStyle,
		},
	};
};
