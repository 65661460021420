// eslint-disable-next-line no-restricted-imports -- this component overrides the headings from dripsy, but we still need to import them
import { H1, H2, H3, H4, H5, H6, P, Text } from "dripsy";
import type { ComponentProps, FunctionComponent } from "react";

import type { Theme } from "../../theme.dripsy";
import { useTextVariantStyle } from "../../useTextVariantStyle";

const tags = { h1: H1, h2: H2, h3: H3, h4: H4, h5: H5, h6: H6, text: Text, p: P } as const;

/**
 * Styled heading component where the display is separated from the actual tag element.
 * This also works around a heading issue with dripsy/expo where headings don't get a font-weight applied.
 * See https://github.com/nandorojo/dripsy/issues/130
 * @param variant - The style variant to use from the theme.
 * @param as - The HTML tag to use.
 */
export const Heading: FunctionComponent<
	{
		variant: keyof Theme["text"]["heading"]["variants"]["variant"];
		as?: keyof typeof tags;
	} & Partial<
		Omit<
			ComponentProps<typeof H1 | typeof H2 | typeof H3 | typeof H4 | typeof H5 | typeof H6 | typeof Text | typeof P>,
			// removed props because they don't make sense for headings
			"as" | "variant" | "accessibilityLevel" | "href" | "target" | "hrefAttrs" | "lang"
		>
	>
> = ({ variant, sx, ...props }) => {
	const as = props.as || variant;
	const { style } = useTextVariantStyle("heading", "variant", variant);
	const HeadingTag = tags[as];

	return (
		<HeadingTag
			// @ts-ignore, fontWeight will be passed explicitly as the style, so on web AND android it will render properly, otherwise the weight doesn't get set
			style={{ fontWeight: style.fontWeight || sx?.fontWeight }}
			sx={{
				...style,
				...sx,
			}}
			{...props}
			as={undefined} // No need to set the as prop, it's already set in the Heading variable
			accessibilityLevel={as.replace("h", "")}
		/>
	);
};
