import { SafeAreaView, View } from "@pivus/ui/style/primitives/dripsy";
import { Text } from "@pivus/ui/style/primitives/Text/Text";

import type { CareListItemItem } from "../../components/CareList/CareList";
import { CareList, ItemSeparator } from "../../components/CareList/CareList";
import { dummyCareListData } from "../../components/CareList/dummyCareListData";
import { useTrackableFlatList } from "../../hooks/tracking/useTrackableFlatList";

export const CareScreen = () => {
	const { onViewableItemsChanged, onItemPress } = useTrackableFlatList<CareListItemItem>("care", (item) => ({
		id: item.id,
		name: item.title,
	}));
	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<CareList
				data={dummyCareListData}
				ListHeaderComponent={CareListHeader}
				onViewableItemsChanged={onViewableItemsChanged}
				onItemPress={onItemPress}
			/>
		</SafeAreaView>
	);
};

const CareListHeader = () => {
	return (
		<>
			<View sx={{ padding: "$4", alignItems: "center" }}>
				<Text variant="body" sx={{ color: "$gray6", maxWidth: "$bp1" }}>
					{`Exploring raw emotions can be intense. Once you've connected with feelings that might be distressing to your mind and body, it's beneficial to nurture them. This isn't about bottling the emotions up again; it's about finding a path to process and ease the impact on your nervous system.\r\n\r\nCheck out these resources; they're here to support you on your journey:`}
				</Text>
			</View>
			<ItemSeparator />
		</>
	);
};
