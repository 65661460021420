import type { FunctionComponent, ReactNode } from "react";
import React, { createContext, useEffect, useMemo } from "react";

import type { BaseCrossPlayer } from "./BaseCrossPlayer";
import { CrossPlayer } from "./CrossPlayer";

export const CrossPlayerContext = createContext<{ crossPlayer: BaseCrossPlayer }>(
	{} as { crossPlayer: BaseCrossPlayer }
);

export const CrossPlayerProvider: FunctionComponent<{ children: ReactNode; crossPlayer?: BaseCrossPlayer }> = ({
	children,
	crossPlayer, // Allow to set a mocked player for testing
}) => {
	const value = useMemo(() => ({ crossPlayer: crossPlayer || new CrossPlayer() }), [crossPlayer]);

	useEffect(() => {
		return () => value.crossPlayer.destroy();
	}, [value]);
	return <CrossPlayerContext.Provider value={value}>{children}</CrossPlayerContext.Provider>;
};
