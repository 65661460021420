import type { SVGReactComponent } from "@pivus/svg-loader";
import type { ComponentProps, FunctionComponent } from "react";

import { Pause, Play } from "../../Icons";
import { MotiView } from "../../style/primitives/moti";
import { useThemeSize } from "../../style/useThemeToken";
import * as ControlButton from "../ControlButton/ControlButton";

export const PlayPauseButton: FunctionComponent<
	{
		state?: "play" | "pause";
	} & Omit<ComponentProps<typeof ControlButton.Root>, "children" | "state">
> = ({ state = "play", size = "$16", sx, ...props }) => {
	const borderWidth = 1;
	const iconSize = useThemeSize(size) - borderWidth * 2;
	return (
		<ControlButton.Root size={size} sx={{ overflow: "hidden", ...sx }} {...props}>
			<MotiView
				transition={ControlButton.defaultTransition}
				animate={{ translateX: state === "play" ? 0 : iconSize * -1 }}
				sx={{ width: iconSize * 2, flexDirection: "row" }}
			>
				<Icon SVG={Play} visible={state === "play"} />
				<Icon SVG={Pause} visible={state === "pause"} />
			</MotiView>
		</ControlButton.Root>
	);
};

const Icon: FunctionComponent<{ SVG: SVGReactComponent; visible: boolean }> = ({ SVG, visible }) => {
	return <ControlButton.Icon SVG={SVG} animate={{ opacity: visible ? 1 : 0 }} />;
};
