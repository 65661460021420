import type { SVGReactComponent } from "@pivus/svg-loader";
import type { ComponentProps, FunctionComponent } from "react";
import { useMemo } from "react";

import { useSx, View } from "../../style/primitives/dripsy";
import type { MotiTransitionProp } from "../../style/primitives/moti";
import { MotiPressable, MotiView } from "../../style/primitives/moti";
import type { ThemedColor, ThemedSize } from "../../style/theme.dripsy";
import { useThemeColor, useThemeSize } from "../../style/useThemeToken";
import { usePressableAnimation, usePressableState, usePressableTransition } from "../../utils/transitionUtils";

export const defaultTransition: MotiTransitionProp = { type: "timing", duration: 200 };

export const Root: FunctionComponent<
	{
		size?: ThemedSize;
		disabled?: boolean;
	} & ComponentProps<typeof MotiPressable>
> = ({ size = "$16", disabled = false, sx, children, ...props }) => {
	const animateSx = useSx();
	return (
		<MotiPressable
			transition={usePressableTransition(
				{
					normal: defaultTransition,
					hovered: defaultTransition,
					pressed: { ...defaultTransition, duration: 0 },
				},
				[]
			)}
			animate={usePressableAnimation(
				{
					normal: animateSx({
						backgroundColor: disabled ? "$gray11" : "$background",
						borderColor: disabled ? "$gray11" : "$gray10",
					}),
					hovered: animateSx({
						backgroundColor: "$gray11",
						borderColor: "$gray11",
					}),
					pressed: animateSx({
						backgroundColor: "$gray11",
						borderColor: "$gray11",
					}),
				},
				[animateSx, disabled]
			)}
			sx={{
				size,
				borderWidth: 1,
				boxShadow: "$navigation",
				borderRadius: useThemeSize(size),
				...sx,
			}}
			{...props}
		>
			<ChildWrapper transition={defaultTransition} disabled={disabled}>
				{children}
			</ChildWrapper>
		</MotiPressable>
	);
};

const ChildWrapper: FunctionComponent<{ disabled: boolean } & Omit<ComponentProps<typeof MotiView>, "animate">> = ({
	disabled,
	children,
	transition,
	sx,
	...props
}) => {
	const pressableState = usePressableState();
	const isPressed = pressableState === "pressed";

	return (
		<MotiView
			// TODO: resolve these ts-ignore's
			// @ts-ignore
			transition={useMemo(
				// @ts-ignore
				() => ({ ...transition, duration: isPressed ? 0 : transition.duration }),
				[transition, isPressed]
			)}
			animate={{
				opacity: disabled ? 0.15 : 1,
				// Minimal pressed feedback by scaling the icon down a bit.
				transform: [{ scale: isPressed ? 0.95 : 1 }],
			}}
			sx={{ flex: 1, ...sx }}
			{...props}
		>
			{children}
		</MotiView>
	);
};

export const Icon: FunctionComponent<
	{
		SVG: SVGReactComponent;
		color?: ThemedColor; // We can set the custom color of the SVG but note that this can not be animated at the moment.
		animate?: { opacity?: number };
	} & ComponentProps<typeof View>
> = ({ SVG, color = "$black", sx, ...props }) => {
	return (
		<View
			sx={{
				flex: 1,
				padding: "$2",
				...sx,
			}}
			{...props}
		>
			<SVG color={useThemeColor(color)} />
		</View>
	);
};
