import type { ComponentProps, FunctionComponent, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Easing } from "react-native-reanimated";

import { Check } from "../Icons";
import { Pressable, useSx } from "../style/primitives/dripsy";
import type { MotiTransitionProp } from "../style/primitives/moti";
import { MotiView } from "../style/primitives/moti";
import { Text } from "../style/primitives/Text/Text";
import { useThemeColor, useThemeSpace } from "../style/useThemeToken";

const defaultTransition: MotiTransitionProp = {
	type: "timing",
	duration: 200,
	easing: Easing.inOut(Easing.cubic),
};

export const CheckBox: FunctionComponent<
	{
		checked?: boolean;
		onChange?: (checked: boolean) => void;
		children: ReactNode;
	} & ComponentProps<typeof Pressable>
> = ({ checked = false, disabled, onChange, onPress, children, sx, ...props }) => {
	const isText = typeof children === "string" || typeof children === "number";
	const animateSx = useSx();
	const [isChecked, setIsChecked] = useState(checked);
	const textColor = disabled ? "$disabledText" : "$text";

	useEffect(() => setIsChecked(checked), [checked, setIsChecked]);
	useEffect(() => onChange?.(isChecked), [isChecked, onChange]);

	return (
		<Pressable
			sx={{
				flexDirection: "row",
				...sx,
			}}
			onPress={useCallback(
				// TODO: solve this ts-ignore
				// @ts-ignore
				(e) => {
					setIsChecked(!isChecked);
					onPress?.(e);
				},
				[onPress, isChecked, setIsChecked]
			)}
			disabled={disabled}
			{...props}
		>
			<MotiView
				sx={{ size: "$6", borderWidth: 1, borderRadius: "$1", padding: "$1", marginRight: "$2" }}
				animate={useMemo(
					() => animateSx({ borderColor: textColor, backgroundColor: isChecked ? textColor : "$background" }),
					[isChecked, animateSx, textColor]
				)}
				transition={defaultTransition}
			>
				<MotiView transition={defaultTransition} animate={useMemo(() => ({ opacity: isChecked ? 1 : 0 }), [isChecked])}>
					<Check color={useThemeColor("$background")} />
				</MotiView>
			</MotiView>

			{isText ? (
				<Text sx={{ color: textColor, userSelect: "none", lineHeight: useThemeSpace("$6") }}>{children}</Text>
			) : (
				children
			)}
		</Pressable>
	);
};
