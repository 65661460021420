import type { Infer } from "superstruct";
import { string, number } from "superstruct";

import { docSchema } from "./Document";

export const TrackSchema = docSchema({
	url: string(),
	artist: string(),
	// artwork: optional(string()),
	title: string(),
	description: string(),
	duration: number(),
});
export type TrackFields = Infer<typeof TrackSchema>;
