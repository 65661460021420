/* eslint no-restricted-imports: 0 */
import * as Dripsy from "dripsy";
import type { ComponentProps, FunctionComponent } from "react";

/**
 * Dripsy's ScrollView with undefined props as their defaults, bypassing some type errors
 */
export const ScrollView: FunctionComponent<Partial<ComponentProps<typeof Dripsy.ScrollView>>> = ({
	children,
	stickyHeaderHiddenOnScroll = undefined,
	StickyHeaderComponent = undefined,
	onPointerEnter = undefined,
	onPointerEnterCapture = undefined,
	onPointerLeave = undefined,
	onPointerLeaveCapture = undefined,
	onPointerMove = undefined,
	onPointerMoveCapture = undefined,
	onPointerCancel = undefined,
	onPointerCancelCapture = undefined,
	onPointerDown = undefined,
	onPointerDownCapture = undefined,
	onPointerUp = undefined,
	onPointerUpCapture = undefined,
	accessibilityLabelledBy = undefined,
	accessibilityLanguage = undefined,
	automaticallyAdjustKeyboardInsets = undefined,
	automaticallyAdjustsScrollIndicatorInsets = undefined,
	...props
}) => {
	return (
		<Dripsy.ScrollView
			stickyHeaderHiddenOnScroll={stickyHeaderHiddenOnScroll}
			StickyHeaderComponent={StickyHeaderComponent}
			onPointerEnter={onPointerEnter}
			onPointerEnterCapture={onPointerEnterCapture}
			onPointerLeave={onPointerLeave}
			onPointerLeaveCapture={onPointerLeaveCapture}
			onPointerMove={onPointerMove}
			onPointerMoveCapture={onPointerMoveCapture}
			onPointerCancel={onPointerCancel}
			onPointerCancelCapture={onPointerCancelCapture}
			onPointerDown={onPointerDown}
			onPointerDownCapture={onPointerDownCapture}
			onPointerUp={onPointerUp}
			onPointerUpCapture={onPointerUpCapture}
			accessibilityLabelledBy={accessibilityLabelledBy}
			accessibilityLanguage={accessibilityLanguage}
			automaticallyAdjustKeyboardInsets={automaticallyAdjustKeyboardInsets}
			automaticallyAdjustsScrollIndicatorInsets={automaticallyAdjustsScrollIndicatorInsets}
			{...props}
		>
			{children}
		</Dripsy.ScrollView>
	);
};
