import { SafeAreaView } from "@pivus/ui/style/primitives/dripsy";

import { ManagedHistoryList } from "../../components/HistoryList/ManagedHistoryList";

export const HistoryScreen = () => {
	return (
		<SafeAreaView sx={{ flex: 1 }}>
			<ManagedHistoryList />
		</SafeAreaView>
	);
};
