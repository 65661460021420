import {
	useCrossPlayerControls,
	useCrossPlayerDuration,
	useCrossPlayerInitialized,
	useCrossPlayerPlaybackStatus,
	useCrossPlayerPosition,
	useCrossPlayerTrack,
} from "@pivus/cross-player/hooks";
import { Forward, Rewind } from "@pivus/ui/Icons";
import * as ControlButton from "@pivus/ui/player/ControlButton/ControlButton";
import { PlayPauseButton as UnmanagedPlayPauseButton } from "@pivus/ui/player/PlayPauseButton/PlayPauseButton";
import { TimeScrubber } from "@pivus/ui/player/TimeScrubber/TimeScrubber";
import { styled, View } from "@pivus/ui/style/primitives/dripsy";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { ComponentProps, FunctionComponent } from "react";

import { logUiEvent } from "../../firebase/analytics-utils";

export const Context = createContext<{
	disabled: boolean;
}>({ disabled: false });

export const StyledRoot = styled(View)({
	flexDirection: "column",
});
export const Section = styled(View)({
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
});

export const Root: FunctionComponent<{ disabled?: boolean } & ComponentProps<typeof StyledRoot>> = ({
	disabled,
	...props
}) => {
	const initialized = useCrossPlayerInitialized();
	const { crossPlayerTrack } = useCrossPlayerTrack();

	return (
		<Context.Provider
			value={useMemo(
				() => ({ disabled: disabled === true || !initialized || !crossPlayerTrack }),
				[disabled, initialized, crossPlayerTrack]
			)}
		>
			<StyledRoot {...props} />
		</Context.Provider>
	);
};

export const Scrubber: FunctionComponent<ComponentProps<typeof TimeScrubber>> = ({ onSlidingComplete, ...props }) => {
	const position = useCrossPlayerPosition();
	const duration = useCrossPlayerDuration();
	const { seekTo } = useCrossPlayerControls();
	const { disabled } = useContext(Context);

	return (
		<TimeScrubber
			duration={duration}
			currentTime={position}
			onSlidingComplete={(newTime) => {
				onSlidingComplete?.(newTime);
				seekTo(newTime);
			}}
			disabled={disabled}
			{...props}
		/>
	);
};

/**
 * This is a wrapper around the PlayPauseButton that manages the state of the button based on the player state and the users intent.
 */
export const PlayPauseButton: FunctionComponent<
	{
		lockState?: boolean; // Should the button lock displaying the current state (so it can't change upon playbackStatusChanges)
	} & ComponentProps<typeof UnmanagedPlayPauseButton>
> = ({ lockState, state: initialState, onPress, ...props }) => {
	const playerControls = useCrossPlayerControls();
	const { crossPlayerTrack } = useCrossPlayerTrack();
	const playbackStatus = useCrossPlayerPlaybackStatus();
	const { disabled } = useContext(Context);

	const [state, setState] = useState(initialState);

	const toggleState = useCallback(() => {
		setState(state === "pause" ? "play" : "pause");
	}, [state, setState]);

	useEffect(() => {
		if (lockState) return; // Whoops, can't update now
		if (playbackStatus === "playing" || playbackStatus === "loading") {
			setState("pause");
		} else {
			setState("play");
		}
	}, [playbackStatus, setState, lockState]);

	return (
		<UnmanagedPlayPauseButton
			state={state}
			onPress={() => {
				if (!disabled) {
					toggleState();
					if (crossPlayerTrack) {
						if (state === "pause") {
							playerControls.pause();
						} else {
							playerControls.play();
						}
					} else {
						console.error("Tried playing while no track was loaded.");
					}

					logUiEvent({
						ui_component: "audio_player",
						ui_trigger: "play_pause_button",
						ui_action: state || "play",
						ui_value: crossPlayerTrack ? "with_track" : "without_track",
						ui_ref: crossPlayerTrack ? crossPlayerTrack.id : "",
					});
				}

				onPress?.();
			}}
			disabled={disabled}
			{...props}
		/>
	);
};

export const RewindButton: FunctionComponent<ComponentProps<typeof ControlButton.Root>> = ({ onPress, ...props }) => {
	const position = useCrossPlayerPosition() || 0;
	const { crossPlayerTrack } = useCrossPlayerTrack();
	const { seekTo } = useCrossPlayerControls();
	const { disabled } = useContext(Context);
	const buttonDisabled = disabled || position === 0;

	return (
		<ControlButton.Root
			onPress={() => {
				if (!buttonDisabled && crossPlayerTrack) {
					seekTo(Math.max(0, position - 15));
				}
				onPress?.();
			}}
			disabled={buttonDisabled}
			{...props}
		>
			<ControlButton.Icon SVG={Rewind} />
		</ControlButton.Root>
	);
};

export const ForwardButton: FunctionComponent<ComponentProps<typeof ControlButton.Root>> = ({ onPress, ...props }) => {
	const position = useCrossPlayerPosition() || 0;
	const duration = useCrossPlayerDuration() || Infinity;
	const { crossPlayerTrack } = useCrossPlayerTrack();
	const { seekTo } = useCrossPlayerControls();
	const { disabled } = useContext(Context);
	const buttonDisabled = disabled || position >= duration - 15;

	return (
		<ControlButton.Root
			onPress={() => {
				if (!buttonDisabled && crossPlayerTrack) {
					seekTo(Math.min(duration, position + 15));
				}
				onPress?.();
			}}
			disabled={buttonDisabled}
			{...props}
		>
			<ControlButton.Icon SVG={Forward} />
		</ControlButton.Root>
	);
};
