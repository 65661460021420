import { useSx } from "@pivus/ui/style/primitives/dripsy";
import { useColorModeValue } from "@pivus/ui/style/useColorModeValue";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { SimpleProfileDetailsScreen } from "../screens/simple/profile/SimpleProfileDetailsScreen";
import { SimpleProfileOverviewScreen } from "../screens/simple/profile/SimpleProfileOverviewScreen";

import type { SimpleProfileStackParams } from "./stacks";
import { useNavigationStyles } from "./styles/useNavigationStyles";
import { asStackComponent } from "./utils/asStackComponent";
import { withColoredStatusBarComponent } from "./utils/withColoredStatusBarComponent";

const ProfileStack = createNativeStackNavigator<SimpleProfileStackParams>();

export const ProfileStackNavigator = () => {
	const sx = useSx();
	const { headerStyle, headerTitleStyle } = useNavigationStyles();
	return (
		<ProfileStack.Navigator screenOptions={{ headerStyle, headerTitleStyle }}>
			<ProfileStack.Screen
				name="overview"
				component={withColoredStatusBarComponent(SimpleProfileOverviewScreen, {
					barStyle: useColorModeValue("light-content", "dark-content"),
					backgroundColor: useThemeColor("$relief8"),
				})}
				options={{
					title: "Profile overview",
					headerStyle: sx({ ...headerStyle, backgroundColor: "$relief8" }),
					headerBackVisible: false,
				}}
			/>
			<ProfileStack.Screen
				name="details"
				component={withColoredStatusBarComponent(
					asStackComponent<SimpleProfileStackParams["details"]>(SimpleProfileDetailsScreen),
					{
						barStyle: useColorModeValue("light-content", "dark-content"),
						backgroundColor: useThemeColor("$joy3"),
					}
				)}
				options={{
					title: "Profile details",
					headerStyle: sx({ ...headerStyle, backgroundColor: "$joy3" }),
				}}
			/>
		</ProfileStack.Navigator>
	);
};
