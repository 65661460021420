import { addDocumentToCollection } from "@pivus/firebase-entities/Document";
import type { PlaybackTagFields } from "@pivus/firebase-entities/user_data/PlaybackTag";
import { useMemo } from "react";

import { useStores } from "../../StoresProvider";

import type { PlaybackSessionDocument } from "./PlaybackSession";

const createPlaybackTag = (uid: string, session: PlaybackSessionDocument, tag: PlaybackTagFields["tag"]) => {
	return addDocumentToCollection<PlaybackTagFields>(`user_data/${uid}/playback_tags`, {
		sessionId: session.id!,
		trackId: session.data.trackId,
		tag,
		position: session.data.position,
	});
};

export const useCreatePlaybackTag = () => {
	const { userStore } = useStores();

	return useMemo(
		() => (tag: PlaybackTagFields["tag"]) => {
			if (!userStore.user || !userStore.activePlaybackSession) {
				throw new Error("Can't create playback tag without user or active playback session");
			}
			return createPlaybackTag(userStore.user?.uid!, userStore.activePlaybackSession!, tag);
		},
		[]
	);
};
