import type { SxProp } from "@pivus/ui/style/primitives/dripsy";
import { Image, useSx, View } from "@pivus/ui/style/primitives/dripsy";
import { useTextVariantStyle } from "@pivus/ui/style/useTextVariantStyle";
import { useThemeFontSize, useThemeSize } from "@pivus/ui/style/useThemeToken";
import type { ComponentProps, FunctionComponent } from "react";
import { useWindowDimensions } from "react-native";
import { BaseToast } from "react-native-toast-message";
import type { ToastConfig } from "react-native-toast-message/lib/src/types";

import LoveShake from "../../assets/loveShake.png";

export const SuccessToast: FunctionComponent<
	{
		sx?: SxProp;
		text1Sx?: SxProp;
		text2Sx?: SxProp;
		contentContainerSx?: SxProp;
	} & Omit<ComponentProps<typeof BaseToast>, "style" | "text1Style" | "text2Style" | "contentContainerStyle">
> = ({ sx: sxProp, text1Sx, text1Props, text2Sx, text2Props, contentContainerSx, ...props }) => {
	const sx = useSx();
	const { width } = useWindowDimensions();
	const maxWidth = useThemeSize("$bp1");

	return (
		<BaseToast
			style={sx({
				borderLeftColor: "$success",
				width: Math.min(maxWidth, width * 0.9),
				height: "auto",
				overflow: "hidden",
				...sxProp,
			})}
			contentContainerStyle={sx({
				paddingX: "$5",
				paddingY: "$4",
				...contentContainerSx,
			})}
			text1Props={{ numberOfLines: undefined, ...text1Props }}
			text1Style={sx({
				...useTextVariantStyle("heading", "variant", "h6").style,
				fontSize: "$20",
				lineHeight: useThemeFontSize("$20") * 1.5,
				marginBottom: "$2",
				fontWeight: "700",
				whiteSpace: "normal",
				...text1Sx,
			})}
			text2Props={{ numberOfLines: undefined, ...text2Props }}
			text2Style={sx({
				...useTextVariantStyle("text", "variant", "body").style,
				whiteSpace: "normal",
				color: "$gray6",
				...text2Sx,
			})}
			{...props}
		/>
	);
};

export const ErrorToast: FunctionComponent<ComponentProps<typeof SuccessToast>> = (props) => {
	return <SuccessToast sx={{ borderLeftColor: "$error" }} {...props} />;
};
export const InfoToast: FunctionComponent<ComponentProps<typeof SuccessToast>> = (props) => {
	return <SuccessToast sx={{ borderLeftColor: "$info" }} {...props} />;
};

export const ToastIcon: FunctionComponent<{ placement: "leading" | "trailing" } & ComponentProps<typeof View>> = ({
	placement,
	sx,
	...props
}) => {
	return (
		<View
			sx={{
				marginY: "$2",
				...(placement === "leading"
					? {
							marginLeft: "$5",
							marginRight: "-$1",
					  }
					: {
							marginLeft: "-$1",
							marginRight: "$5",
					  }),

				justifyContent: "center",
				alignItems: "center",
				...sx,
			}}
			{...props}
		/>
	);
};
export const baseToastConfig: ToastConfig = {
	success: (props) => <SuccessToast {...props} />,
	accountSuccess: (props) => (
		<SuccessToast
			{...props}
			renderTrailingIcon={() => (
				<ToastIcon placement="trailing">
					<Image source={LoveShake} sx={{ size: "$14" }} />
				</ToastIcon>
			)}
		/>
	),
	error: (props) => <ErrorToast {...props} />,
	info: (props) => <InfoToast {...props} />,
};
