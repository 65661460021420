import { useCrossPlayer } from "@pivus/cross-player/hooks";
import type { PlaybackTagFields } from "@pivus/firebase-entities/user_data/PlaybackTag";
import { useEffect, useMemo } from "react";
import { Platform } from "react-native";

import { logUiEvent } from "../firebase/analytics-utils";
import { useCreatePlaybackTag } from "../stores/firebase/user_data/useCreatePlaybackTag";

/**
 * Listens to native remote controls and creates playback tags when the user presses previous or next while the track is currently playing.
 */
export const useCrossPlayerNativeControlsTagger = () => {
	if (Platform.OS === "web") return false;

	const crossPlayer = useCrossPlayer();
	const createPlaybackTag = useCreatePlaybackTag();
	const onTag = useMemo(
		() => (tag: PlaybackTagFields["tag"]) => {
			if (crossPlayer.playbackStatus === "playing") {
				logUiEvent({
					ui_component: "audio_player",
					ui_trigger: "native_remote_controls",
					ui_action: "tag",
					ui_value: tag,
					ui_ref: crossPlayer.track?.id,
				});
				return createPlaybackTag(tag);
			}
		},
		[crossPlayer, createPlaybackTag]
	);

	useEffect(() => {
		const tagCry = () => onTag("cry");
		const tagAnger = () => onTag("anger");
		const onSeek = () => {
			logUiEvent({
				ui_component: "audio_player",
				ui_trigger: "native_remote_controls",
				ui_action: "seek",
			});
		};
		const onPlay = () => {
			logUiEvent({
				ui_component: "audio_player",
				ui_trigger: "native_remote_controls",
				ui_action: "play",
			});
		};
		const onPause = () => {
			logUiEvent({
				ui_component: "audio_player",
				ui_trigger: "native_remote_controls",
				ui_action: "pause",
			});
		};

		crossPlayer.addEventListener("nativeRemotePrevious", tagAnger);
		crossPlayer.addEventListener("nativeRemoteNext", tagCry);
		crossPlayer.addEventListener("nativeRemoteSeek", onSeek);
		crossPlayer.addEventListener("nativeRemotePlay", onPlay);
		crossPlayer.addEventListener("nativeRemotePause", onPause);
		return () => {
			crossPlayer.removeEventListener("nativeRemotePrevious", tagAnger);
			crossPlayer.removeEventListener("nativeRemoteNext", tagCry);
			crossPlayer.removeEventListener("nativeRemoteSeek", onSeek);
			crossPlayer.removeEventListener("nativeRemotePlay", onPlay);
			crossPlayer.removeEventListener("nativeRemotePause", onPause);
		};
	}, [onTag, crossPlayer]);

	return true;
};
