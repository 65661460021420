/**
 * Format a duration in seconds to a string
 */
export const formatDuration = (duration: number) => {
	const absoluteDuration = Math.abs(duration);
	const totalMinutes = Math.floor(absoluteDuration / 60);
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes - hours * 60;
	const seconds = Math.floor(absoluteDuration % 60);

	return `${Math.ceil(duration) < 0 ? "-" : ""}${
		hours > 0 ? `${hours}:${minutes.toString().padStart(2, "0")}` : minutes
	}:${seconds.toString().padStart(2, "0")}`;
};
