import type { Infer } from "superstruct";
import { enums, number, string } from "superstruct";

import { docSchema } from "../Document";

export const PlaybackSessionSchema = docSchema({
	trackId: string(),
	state: enums(["active", "completed", "stopped", "blocked"]),
	position: number(),
});

export type PlaybackSessionFields = Infer<typeof PlaybackSessionSchema> & {
	state: "active" | "completed" | "stopped" | "blocked";
};
