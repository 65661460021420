import { number, object, boolean } from "superstruct";
import type { Infer } from "superstruct";

import { docSchema } from "../Document";

export const TrackPlaybackAggregationSchema = docSchema({
	blocked: boolean(),
	plays: number(),
	tags: object({
		cry: number(),
		anger: number(),
		emergency: number(),
	}),
});

export type TrackPlaybackAggregationFields = Infer<typeof TrackPlaybackAggregationSchema>;
