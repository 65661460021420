import { createDrawerNavigator } from "@react-navigation/drawer";

import { DrawerContent } from "./components/Drawer/DrawerContent";
import type { DrawerStackParams } from "./stacks";
import { TabStackNavigator } from "./TabStackNavigator";

const DrawerStack = createDrawerNavigator<DrawerStackParams>();

export const DrawerStackNavigator = () => {
	return (
		<DrawerStack.Navigator screenOptions={{ drawerPosition: "right" }} drawerContent={DrawerContent}>
			<DrawerStack.Screen name="tabStack" options={{ headerShown: false }} component={TabStackNavigator} />
		</DrawerStack.Navigator>
	);
};
