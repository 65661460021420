import { useContext, useEffect, useMemo, useState } from "react";

import { CrossPlayerContext } from "./CrossPlayerProvider";

export const useCrossPlayer = () => {
	return useContext(CrossPlayerContext).crossPlayer;
};

export const useCrossPlayerInitialized = () => {
	const crossPlayer = useCrossPlayer();
	const [initialized, setInitialized] = useState(crossPlayer.initialized);
	useEffect(() => {
		crossPlayer.addEventListener("initialized", setInitialized);
		return () => crossPlayer.removeEventListener("initialized", setInitialized);
	}, [crossPlayer, setInitialized]);

	return initialized;
};

export const useCrossPlayerPosition = () => {
	const crossPlayer = useCrossPlayer();
	const [position, setPosition] = useState(crossPlayer.position);

	useEffect(() => {
		crossPlayer.addEventListener("positionChanged", setPosition);
		return () => crossPlayer.removeEventListener("positionChanged", setPosition);
	}, [crossPlayer, setPosition]);

	return position;
};

export const useCrossPlayerDuration = () => {
	const crossPlayer = useCrossPlayer();
	const [duration, setDuration] = useState(crossPlayer.duration);
	useEffect(() => {
		crossPlayer.addEventListener("durationChanged", setDuration);
		return () => crossPlayer.removeEventListener("durationChanged", setDuration);
	}, [crossPlayer, setDuration]);

	return duration;
};

export const useCrossPlayerTrack = () => {
	const crossPlayer = useCrossPlayer();
	const [crossPlayerTrack, setCrossPlayerTrack] = useState(crossPlayer.track);
	useEffect(() => {
		crossPlayer.addEventListener("trackChanged", setCrossPlayerTrack);
		return () => crossPlayer.removeEventListener("trackChanged", setCrossPlayerTrack);
	}, [crossPlayer, setCrossPlayerTrack]);

	return useMemo(
		() => ({ crossPlayerTrack, setCrossPlayerTrack: crossPlayer.setTrack }),
		[crossPlayerTrack, crossPlayer]
	);
};

export const useCrossPlayerPlaybackStatus = () => {
	const crossPlayer = useCrossPlayer();
	const [playbackStatus, setPlaybackStatus] = useState(crossPlayer.playbackStatus);
	useEffect(() => {
		crossPlayer.addEventListener("playbackStatusChanged", setPlaybackStatus);
		return () => crossPlayer.removeEventListener("playbackStatusChanged", setPlaybackStatus);
	}, [crossPlayer, setPlaybackStatus]);

	return playbackStatus;
};

export const useCrossPlayerControls = () => {
	const crossPlayer = useCrossPlayer();
	return useMemo(
		() => ({
			play: crossPlayer.play,
			pause: crossPlayer.pause,
			stop: crossPlayer.stop,
			// Round seek to nearest 0.01 seconds, to avoid large floating points
			seekTo: (position: number) => crossPlayer.seekTo(Math.round(position * 100) / 100),
		}),
		[]
	);
};
