import { useCrossPlayerTrack } from "@pivus/cross-player/hooks";
import { useLinkTo } from "@react-navigation/native";
import type { FunctionComponent } from "react";

import { logUiEvent } from "../../firebase/analytics-utils";
import { blockTrackById } from "../../firebase/functions";
import { useCreatePlaybackTag } from "../../stores/firebase/user_data/useCreatePlaybackTag";
import { useStores } from "../../stores/StoresProvider";

import { BottomOverlayPlayer } from "./BottomOverlayPlayer";

export const ManagedBottomOverlayPlayer: FunctionComponent<{}> = () => {
	const { userStore } = useStores();
	const linkTo = useLinkTo();
	const { crossPlayerTrack } = useCrossPlayerTrack();

	return (
		<BottomOverlayPlayer
			onPlayPausePress={() => {
				if (userStore.currentTrack === undefined) {
					userStore.setTrackById(userStore.tracks[0].id);
				}
			}}
			onTag={useCreatePlaybackTag()}
			onCloseRequest={({ source, dontPlayChecked, screen }) => {
				logUiEvent({
					ui_component: "bottom_overlay_player",
					ui_trigger: `${screen}.${source}`,
					ui_action: "close",
					ui_ref: crossPlayerTrack?.id,
				});
				if (dontPlayChecked) {
					blockTrackById({ trackId: crossPlayerTrack?.id! });

					logUiEvent({
						ui_component: "bottom_overlay_player",
						ui_trigger: `${source}.${source}`,
						ui_action: "block_track",
						ui_ref: crossPlayerTrack?.id,
					});
				}

				if (source === "care-button") {
					linkTo("/care");
				}
			}}
			onCloseTransitionCompleted={() => {
				userStore.setTrackById(undefined, { autoPlay: false });
			}}
		/>
	);
};
