import { useRoute } from "@react-navigation/native";
import type { FunctionComponent } from "react";

/**
 * This is a helper function to apply the route params as props to a component. So we can detach the screen from the stack and its exact params.
 */
export function asStackComponent<
	Params extends Record<string, string | number | boolean>, // TODO: params should be based on what the parent stack component provides
	Comp = FunctionComponent<Params>
>(Component: Comp) {
	return function Screen() {
		const { params } = useRoute();
		// @ts-ignore
		return <Component {...(params as Params)} />;
	};
}
