import type { FunctionComponent } from "react";

import type { SimpleButtonProps } from "./SimpleButton";

export const SimpleButton: FunctionComponent<SimpleButtonProps> = ({ title, variant, onPress }) => {
	return (
		<button
			style={{
				backgroundColor: variant === "primary" ? "lightgreen" : variant === "secondary" ? "lightblue" : "lightgrey",
			}}
			onClick={onPress}
			type="button"
		>
			Web button: {title}
		</button>
	);
};
