import Color from "color";

// See RadixUI's documentation on theme colors. That scale definition would be ideal to use.
// https://www.radix-ui.com/docs/colors/palette-composition/the-scales
/**
 * See https://www.radix-ui.com/docs/colors/palette-composition/understanding-the-scale
 * Guidelines (not strict, just a starting point):
 * 1-2 - app background
 * 3-4 - element background
 * 6-8 - borders
 * 9-10 - solid backgrounds (overlays, accent borders, shadows, header background)
 * 11-12 - text
 */

const scalesConfigs = [
	{ name: "$awe", baseColor: "#5552E2", baseColorStep: 6, stepIncrement: 0.095 },
	{ name: "$joy", baseColor: "#D1512D", baseColorStep: 6, stepIncrement: 0.15 },
	{ name: "$calm", baseColor: "#BBBB41", baseColorStep: 6, stepIncrement: 0.15 },
	{ name: "$pride", baseColor: "#FFCC33", baseColorStep: 7, stepIncrement: 0.125 },
	{ name: "$relief", baseColor: "#618ECF", baseColorStep: 6, stepIncrement: 0.1 },
	{ name: "$trust", baseColor: "#C23450", baseColorStep: 6, stepIncrement: 0.16 },
	{ name: "$gray", baseColor: "#f4f4f4", baseColorStep: 12, stepIncrement: 0.075 },
] as const;
const steps = 12;

// TODO Simplify following utils and types
type Keys = `${(typeof scalesConfigs)[number]["name"]}${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}`;

type ScaleInput = {
	name: string;
	baseColor: string;
	baseColorStep?: number;
	stepIncrement?: number;
};
/**
 * Generate a color scale based on a base color.
 */
const generateScale = ({ name, baseColor, baseColorStep = 1, stepIncrement = 0.1 }: ScaleInput) => {
	const color = Color(baseColor);
	const lightColors: Record<string, string> = {};
	const darkColors: Record<string, string> = {};

	for (let step = 1; step <= steps; step++) {
		const offset = (step - baseColorStep) * stepIncrement;
		lightColors[`${name}${step}`] = color.lighten(offset).hex();
	}

	Object.values(lightColors)
		.reverse()
		.forEach((reverseColor, reverseStep) => {
			darkColors[`${name}${reverseStep + 1}`] = reverseColor;
		});

	return { light: lightColors, dark: darkColors };
};

// reduce scalesConfigs into light and dark color scales with correct types
const { light: lightScales, dark: darkScales } = scalesConfigs.reduce(
	(scales, scale) => {
		const { light, dark } = generateScale(scale);

		return {
			light: {
				...scales.light,
				...light,
			},
			dark: {
				...scales.dark,
				...dark,
			},
		};
	},
	{ light: {}, dark: {} } as { light: Record<Keys, string>; dark: Record<Keys, string> }
);

export const lightColors = {
	...lightScales,
	$fixed: lightScales,

	$background: lightScales.$gray12,
	$text: "#000000",
	$invertedText: "#ffffff",
	$disabledText: lightScales.$gray10,
	$black: "#000000",
	$white: "#ffffff",

	$success: "#167716",
	$info: lightScales.$relief4,
	$error: "#aa0404",
};

export const darkColors: typeof lightColors = {
	...darkScales,
	$fixed: lightScales,

	$background: darkScales.$gray12,
	$text: "#ffffff",
	$invertedText: "#000000",
	$disabledText: darkScales.$gray10,
	$black: "#ffffff",
	$white: "#000000",

	$success: "#167716",
	$info: lightScales.$relief4,
	$error: "#aa0404",
};
