import { ScrollView, View } from "@pivus/ui/style/primitives/dripsy";
import { useLinkTo } from "@react-navigation/native";
import { Button } from "react-native";

export const SimpleNavigation = () => {
	const linkTo = useLinkTo();
	return (
		<ScrollView horizontal>
			<View sx={{ flexDirection: "row", justifyContent: "space-between" }}>
				<Button
					title="Explore"
					onPress={() => {
						linkTo("/");
					}}
				/>
				<Button
					title="Care"
					onPress={() => {
						linkTo("/care");
					}}
				/>
				<Button
					title="History"
					onPress={() => {
						linkTo("/history");
					}}
				/>
				<Button
					title="Simple home"
					onPress={() => {
						linkTo("/simple");
					}}
				/>
				<Button
					title="Profile overview"
					onPress={() => {
						linkTo("/simple/profile");
					}}
				/>
				<Button
					title="404"
					onPress={() => {
						linkTo("/not-found/page");
					}}
				/>
				<Button
					title="Audio"
					onPress={() => {
						linkTo("/simple/audio");
					}}
				/>
			</View>
		</ScrollView>
	);
};
