import type { TrackPlaybackAggregationFields } from "@pivus/firebase-entities/user_data/TrackPlaybackAggregation";
import { TrackPlaybackAggregationSchema } from "@pivus/firebase-entities/user_data/TrackPlaybackAggregation";
import type { CollectionReference } from "firebase/firestore";
import { orderBy, query } from "firebase/firestore";
import type { ICollectionOptions, DocumentSource } from "firestorter";
import { computed, makeObservable, observable, reaction } from "mobx";

import { TypedCollection } from "../core/TypedCollection";
import type { TypedDocumentOptions } from "../core/TypedDocument";
import { createSchemaValidator, TypedDocument } from "../core/TypedDocument";

export class TrackPlaybackAggregationDocument extends TypedDocument<TrackPlaybackAggregationFields> {
	constructor(source: DocumentSource, options: TypedDocumentOptions = {}) {
		super(source, {
			...options,
			schema: createSchemaValidator(TrackPlaybackAggregationSchema),
		});
	}
}

export class TrackPlaybackAggregationCollection extends TypedCollection<
	TrackPlaybackAggregationFields,
	TrackPlaybackAggregationDocument
> {
	public uid?: string;

	constructor(uid?: string, options: Omit<ICollectionOptions<TrackPlaybackAggregationFields>, "createDocument"> = {}) {
		super(undefined, {
			...options,
			query: (ref: CollectionReference) => ref && query(ref, orderBy("updatedAt", "desc")),
			createDocument: (docSrc, docOptions) => new TrackPlaybackAggregationDocument(docSrc, docOptions),
		});

		this.uid = uid;

		makeObservable(this, {
			uid: observable,
			playedTrackIds: computed,
			blockedTrackIds: computed,
		});

		reaction(
			() => this.uid,
			(newUid) => {
				this.source = newUid ? `user_data/${newUid}/track_playback_aggregations` : undefined;
			},
			{ fireImmediately: true }
		);
	}

	get playedTrackIds() {
		return this.docs.filter((doc) => doc.data.plays > 0).map((doc) => doc.id);
	}

	get blockedTrackIds() {
		return this.docs.filter((doc) => doc.data.blocked).map((doc) => doc.id);
	}
}
