import type { SVGReactComponent } from "@pivus/svg-loader";
import { View } from "@pivus/ui/style/primitives/dripsy";
import { Heading } from "@pivus/ui/style/primitives/Heading/Heading";
import { MotiView } from "@pivus/ui/style/primitives/moti";
import { Text } from "@pivus/ui/style/primitives/Text/Text";
import type { Theme } from "@pivus/ui/style/theme.dripsy";
import { useThemeColor } from "@pivus/ui/style/useThemeToken";
import { useState, useMemo } from "react";
import type { ComponentProps, FunctionComponent } from "react";
import { Easing } from "react-native-reanimated";

import CD from "./assets/CD.svg";
import Cover from "./assets/Cover.svg";
import One from "./assets/shapes/1.svg";
import Two from "./assets/shapes/2.svg";
import Three from "./assets/shapes/3.svg";
import Four from "./assets/shapes/4.svg";
import Five from "./assets/shapes/5.svg";
import Six from "./assets/shapes/6.svg";
import Seven from "./assets/shapes/7.svg";
import Eight from "./assets/shapes/8.svg";

export const albumShapes = {
	1: One,
	2: Two,
	3: Three,
	4: Four,
	5: Five,
	6: Six,
	7: Seven,
	8: Eight,
};

export const Album: FunctionComponent<{
	title: string;
	subtitle: string;
	open?: boolean;
	Shape: SVGReactComponent;
	color: keyof Theme["colors"];
	sx?: ComponentProps<typeof View>["sx"];
}> = ({ title, subtitle, Shape, open = false, color, sx }) => {
	const [size, setSize] = useState(0);
	const cdFactor = 0.9;
	const shapeFactor = 0.7;
	const headingFactor = 0.12;
	const subheadingFactor = headingFactor * 0.5;
	const spaceFactor = 0.05;
	const space = size * spaceFactor;

	const textShadow = {
		textShadowColor: "$gray11",
		textShadowRadius: size * 0.08,
	};

	return (
		<View
			sx={{
				width: "100%",
				height: size,
				position: "relative",
				shadowColor: "#000",
				shadowOffset: {
					width: 5,
					height: 5,
				},
				shadowOpacity: 0.15,
				shadowRadius: 5,
				...sx,
			}}
			onLayout={({
				nativeEvent: {
					layout: { width },
				},
			}) => setSize(width)}
		>
			<MotiView
				transition={useMemo(
					() => ({
						type: "timing",
						duration: open ? 250 : 175,
						easing: open ? Easing.out(Easing.cubic) : Easing.in(Easing.cubic),
					}),
					[open]
				)}
				animate={{
					transform: [{ translateX: open ? size * cdFactor * 0.5 : 0 }],
				}}
				sx={{
					position: "absolute",
					top: (1 - cdFactor) * 0.5 * size,
					left: (1 - cdFactor) * 0.5 * size,
				}}
			>
				<CD width={size * cdFactor} height={size * cdFactor} />
			</MotiView>
			<View>
				<View sx={{ position: "absolute", top: 0, left: 0 }}>
					<Cover width={size} height={size} />
				</View>
				<View sx={{ position: "absolute", top: (1 - shapeFactor) * 0.5 * size, right: space }}>
					<Shape width={size * shapeFactor} height={size * shapeFactor} color={useThemeColor(color)} />
				</View>

				{title || subtitle ? (
					<View
						sx={{
							justifyContent: "flex-end",
							width: size,
							height: size,
							paddingY: space * 2,
							paddingX: space,
						}}
					>
						{title && (
							<Heading
								variant="h3"
								sx={{
									textTransform: "capitalize",
									fontSize: size * headingFactor,
									lineHeight: size * headingFactor * 1.1,
									marginBottom: subtitle ? space * 0.33 : undefined,
									...textShadow,
								}}
							>
								{title}
							</Heading>
						)}
						{subtitle && (
							<Text
								sx={{
									textTransform: "capitalize",
									fontSize: Math.max(8, size * subheadingFactor),
									lineHeight: Math.max(8, size * subheadingFactor) * 1.3,
									opacity: 0.5,
									...textShadow,
								}}
							>
								{subtitle}
							</Text>
						)}
					</View>
				) : null}
			</View>
		</View>
	);
};
