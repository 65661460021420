import AsyncStorage from "@react-native-async-storage/async-storage";
import type { FirebaseOptions } from "firebase/app";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectAuthEmulator, initializeAuth } from "firebase/auth";
import { getReactNativePersistence } from "firebase/auth/react-native";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
// eslint-disable-next-line no-restricted-imports
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { initFirestorter } from "firestorter";
import makeWebContext from "firestorter/web";
import { Platform } from "react-native";

import env from "../utils/env";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig: FirebaseOptions = {
	apiKey: env.FIREBASE_API_KEY,
	databaseURL: env.FIREBASE_DATABASE_URL,
	authDomain: env.FIREBASE_AUTH_DOMAIN,
	projectId: env.FIREBASE_PROJECT_ID,
	storageBucket: env.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
	appId: env.FIREBASE_APP_ID,
	measurementId: env.FIREBASE_MEASUREMENT_ID,
};

/**
 * TODO: Use react-native-firebase instead for native apps
 * This comes with some issues though, as detailed in
 * - The PR - https://github.com/PaulTondeur/pivus/pull/19
 * - The issue - https://www.notion.so/add-firebase-app-check-2b7f387f230b4dcb945acbc73c620dd5?pvs=4
 * Before going live, this should be resolved in order not to run security risks where atackers can write any data format as they please to the database.
 */

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = initializeAuth(firebaseApp, {
	// Resolves the AsyncStorage warning, @see https://amanhimself.dev/blog/remove-asyncstorage-has-been-extracted-warning-using-firebase/
	persistence: getReactNativePersistence(AsyncStorage),
});
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp, "europe-west1");
export const firebaseStorage = getStorage(firebaseApp);

// eslint-disable-next-line no-undef
if (__DEV__) {
	// setLogLevel("debug");
	const host = "192.168.2.7"; // home, also change in firebase.json
	connectFirestoreEmulator(firebaseFirestore, host, 8080);
	connectAuthEmulator(firebaseAuth, `http://${host}:9099`, { disableWarnings: true });
	connectFunctionsEmulator(firebaseFunctions, host, 5001);
	connectStorageEmulator(firebaseStorage, host, 9199);
}

// Call this at the toplevel of your app
export const initializeFirebase = () => {
	if (Platform.OS === "web") {
		if (env.FIREBASE_WEB_APP_CHECK_DEBUG_TOKEN !== "" && env.FIREBASE_WEB_APP_CHECK_DEBUG_TOKEN !== undefined) {
			// @ts-ignore
			// eslint-disable-next-line no-restricted-globals,no-undef
			self.FIREBASE_APPCHECK_DEBUG_TOKEN = env.FIREBASE_WEB_APP_CHECK_DEBUG_TOKEN;
		}

		initializeAppCheck(firebaseApp, {
			// See https://www.google.com/recaptcha/admin/site/645820996 or https://console.firebase.google.com/project/tearjerker-16d25/appcheck/apps for debug keys
			provider: new ReCaptchaV3Provider(env.FIREBASE_WEB_APP_CHECK_SITE_KEY),
			isTokenAutoRefreshEnabled: true,
		});
	}

	initFirestorter(makeWebContext({ firestore: firebaseFirestore }));
};
