import Constants from "expo-constants";
import { Platform } from "react-native";

export default function isMobileWebClient() {
	if (Platform.OS !== "web") {
		return false;
	}

	return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(
		Constants.platform?.web?.ua
	);
}
