import type { SVGReactComponent } from "@pivus/svg-loader";
import type { ComponentProps, FunctionComponent } from "react";

import { Pressable, View } from "../style/primitives/dripsy";
import { Text } from "../style/primitives/Text/Text";
import type { ThemedColor } from "../style/theme.dripsy";
import { useThemeColor } from "../style/useThemeToken";

const Root: FunctionComponent<ComponentProps<typeof View> & { containerSx?: ComponentProps<typeof View>["sx"] }> = ({
	containerSx,
	sx,
	...props
}) => {
	return (
		<View
			sx={{
				borderTopWidth: 1,
				backgroundColor: "$background",
				borderTopColor: "$gray10",
				boxShadow: "$navigation",
				alignItems: "center",
				...containerSx,
			}}
		>
			<View
				sx={{
					flexDirection: "row",
					justifyContent: "space-between",
					maxWidth: "$bp1",
					width: "100%",
					...sx,
				}}
				{...props}
			/>
		</View>
	);
};

const Item: FunctionComponent<
	{
		Icon: SVGReactComponent;
		iconColor?: ThemedColor;
		active?: boolean;
		label?: string;
		labelColor?: ThemedColor;
	} & ComponentProps<typeof Pressable>
> = ({ Icon, iconColor = "$gray2", label, labelColor = "$gray2", active, sx, ...props }) => {
	return (
		<Pressable
			sx={{
				alignItems: "center",
				justifyContent: "center",
				minWidth: "$16",
				minHeight: "$16",
				borderTopColor: active ? iconColor : "transparent",
				borderTopWidth: 2,
				marginTop: -1,
				paddingTop: 1,
				...sx,
			}}
			{...props}
		>
			<View sx={{ width: "$6", height: "$6" }}>
				<Icon color={useThemeColor(iconColor)} />
			</View>
			<Text variant="bodySmall" sx={{ marginTop: "$1", color: labelColor }}>
				{label}
			</Text>
		</Pressable>
	);
};

const TabBar = { Root, Item };
export { TabBar, Root, Item };
