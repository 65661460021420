// eslint-disable-next-line no-restricted-imports
import { P, Text as DripsyText } from "dripsy";
import type { ComponentProps, FunctionComponent } from "react";

import type { Theme } from "../../theme.dripsy";
import { useTextVariantStyle } from "../../useTextVariantStyle";

const tags = { text: DripsyText, p: P } as const;

/**
 * Styled text component where the display is separated from the actual tag element.
 * In theory this should render a `div` or `p` tag in the browser when `as` is set to `p`. This is however not working properly yet.
 */
export const Text: FunctionComponent<
	{
		as?: keyof typeof tags;
		variant?: keyof Theme["text"]["text"]["variants"]["variant"];
	} & Partial<
		Omit<
			ComponentProps<typeof DripsyText | typeof P>,
			// removed props because they don't make sense for plain text
			"as" | "variant"
		>
	>
> = ({ as = "text", variant = "body", sx, ...props }) => {
	const { style } = useTextVariantStyle("text", "variant", variant);
	const TextTag = tags[as];

	return (
		<TextTag
			// @ts-ignore - not sure why, but Text really wants the style prop
			style={undefined}
			sx={{
				...style,
				...sx,
			}}
			{...props}
			as={undefined} // No need to set the as prop, it's already set in the Text variable
		/>
	);
};
