import type { BaseCrossPlayer, PlaybackStatus } from "@pivus/cross-player/BaseCrossPlayer";
import { action, makeObservable, observable } from "mobx";

export class CrossPlayerState {
	protected _crossPlayer: BaseCrossPlayer;

	protected _initialized = false;

	protected _trackId?: string;

	protected _position?: number;

	protected _duration?: number;

	protected _playbackStatus?: PlaybackStatus;

	constructor(crossPlayer: BaseCrossPlayer) {
		this._crossPlayer = crossPlayer;

		this.update = this.update.bind(this);
		this.update();

		makeObservable(this, {
			_initialized: observable,
			_trackId: observable,
			_position: observable,
			_duration: observable,
			_playbackStatus: observable,
		} as any);

		this.addListeners();
	}

	protected addListeners() {
		this._crossPlayer.addEventListener("initialized", this.update);
		this._crossPlayer.addEventListener("trackChanged", this.update);
		this._crossPlayer.addEventListener("positionChanged", this.update);
		this._crossPlayer.addEventListener("durationChanged", this.update);
		this._crossPlayer.addEventListener("playbackStatusChanged", this.update);
	}

	public destroy() {
		this._crossPlayer.removeEventListener("initialized", this.update);
		this._crossPlayer.removeEventListener("trackChanged", this.update);
		this._crossPlayer.removeEventListener("positionChanged", this.update);
		this._crossPlayer.removeEventListener("durationChanged", this.update);
		this._crossPlayer.removeEventListener("playbackStatusChanged", this.update);
	}

	protected update() {
		action(() => {
			this._initialized = this._crossPlayer.initialized;
			this._trackId = this._crossPlayer.track?.id;
			this._position = this._crossPlayer.position;
			this._duration = this._crossPlayer.duration;
			this._playbackStatus = this._crossPlayer.playbackStatus;
		})();
	}

	public get crossPlayer() {
		return this._crossPlayer;
	}

	public get initialized() {
		return this._initialized;
	}

	public get trackId() {
		return this._trackId;
	}

	public get position() {
		return this._position;
	}

	public get duration() {
		return this._duration;
	}

	public get playbackStatus() {
		return this._playbackStatus;
	}
}
